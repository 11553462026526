<section class="content">
    <div class="row my-1">
        <div class="col-lg-2 pr-md-0" >
            <app-filtercontainer [title]="'Filtra per fasi e date'">
                <span class="card p-1 mb-2">
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-white"><small>Fasi</small></span>
                        </div>
                        <select class="form-control form-control-sm" [(ngModel)]="id_phase" (change)="getItems()">
                            <option value="" >Tutte le fasi</option>
                            <option value="1">In corso</option>
                            <option value="2">Concluse</option>
                            <option value="3">Confermate</option>
                        </select>
                    </div>
                </span>
                <app-filterdate [date_type]="3" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
            </app-filtercontainer>
        </div>
        <div class="col-lg-10">
            <div class="card elevation-2">
                <div class="card-body">
                    <canvas baseChart
                                [legend]="false"
                                [datasets]="chartData"
                                [labels]="chartLabels"
                                [options]="chartOptions"
                        >
                    </canvas>

                    <table class="table table-sm mt-5">
                        <thead class="bg-light">
                            <th><small>Giorno</small></th>
                            <th *ngFor="let u of users" class="text-center"><small>{{u.name}}</small></th>
                            <th class="text-right"><small>Totale</small></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of list">
                                <td>{{r.date | date:"dd/MM/yyyy"}}</td>
                                <td *ngFor="let u of users" class="text-center">
                                    <ng-container *ngFor="let au of r.users">
                                        <ng-container *ngIf="u.id==au.id_user">
                                            <span  title="incassato">{{au.paid | currency:" &euro;"}}</span><br/>
                                            <small><i><span  title="totale attività">{{au.amount | currency:" &euro;"}}</span></i></small>
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td class="text-right">
                                    <b>{{r.total_paid | currency:" &euro;"}}</b><br/>
                                    <small><i><span  title="totale attività">{{r.total_amount | currency:" &euro;"}}</span></i></small>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><b>Totale</b></td>
                                <td *ngFor="let t of total_user" class="text-center">
                                    <b>{{t.paid | currency:" &euro;"}}</b><br/>
                                    <small><i>{{t.amount | currency:" &euro;"}}</i></small>
                                </td>
                                <td class="text-right">
                                    <b>{{total_paid | currency:" &euro;"}}</b><br/>
                                    <small><i>{{total_amount | currency:" &euro;"}}</i></small>
                                 </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>