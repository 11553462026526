import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Globals } from '../globals';
import { WSService } from '../include/service';
import {Template} from '../include/structures';
@Injectable({
  providedIn: 'root'
})
export class TemplatesService extends WSService<Template>{


  static templates=[];

  constructor() { super("templates")}

  getTemplates(table:string,isdetail:boolean,inrow:boolean=false):Observable<Template[]>{
    return  Globals.ws.requestUrl<Template[]>("templates","getItems",["t.table='"+table+"' AND t.inrow="+(inrow?"1":"0")+" AND t.detail="+(isdetail?"1":"0")]);
  }


  getTemplatesByModel(table:string):Observable<Template[]>{

    let templates;
    for(let t of TemplatesService.templates ){
      if(t.table==table){
        templates=t.templates;
      }
    }
    if(templates==undefined){
      Globals.ws.requestUrl<Template[]>("templates","getItems",["t.table='"+table+"' AND t.inrow=1 AND t.detail=1"]).subscribe((items)=>{
        let found=false;
        for(let i=0;i<TemplatesService.templates.length;i++ ){
          if(TemplatesService.templates[i].table==table){
            TemplatesService.templates[i].templates=items;
            found=true;
          }
        }

        if(!found){
          let t:any={};
          t.table=table;
          t.templates=items;
          TemplatesService.templates.push(t);

        }

      })
      return Globals.ws.requestUrl<Template[]>("templates","getItems",["t.table='"+table+"' AND t.inrow=1 AND t.detail=1"]);

    }else{
      function sequenceSubscriber(observer: Observer<any[]>) {

        for(let i=0;i<TemplatesService.templates.length;i++ ){
          if(TemplatesService.templates[i].table==table){
            observer.next(TemplatesService.templates[i].types);
          }
        }

       
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }

    return  ;
  }



  getAllTemplates(table:string="",section:string=""):Observable<Template[]>{
    return  Globals.ws.requestUrl<Template[]>("templates","getItems",[(table!=""?"t.table='"+table+"' AND ":"")+(section!=""?"t.section='"+section+"' AND ":"")+"t.status=1"]);
    
  }

  openTemplate(id_template:number,args:string,is_uri="true",requestparams:string="",version=1):Observable<string>{
    
    var task="createTemplate";
    if(version==2)
      task="createTemplateV2";



    return  Globals.ws.requestUrl<string>("templates",task,[id_template.toString(),args,is_uri,requestparams]);
   
  }


}
