<section class="content">
    <!-- <div class="d-flex justify-content-between">
        <ul class="nav nav-tabs" id="myTab" role="tablist" style="width: calc(100% - 4rem)">        
            <li class="nav-item" *ngFor="let t of types">
                <a class="nav-link" [class.active]="tab==t.id" (click)="tab=t.id">
                    <i class="fas fa-archive mr-2"></i>{{t.name}}
                </a>
            </li>
        </ul>
        <i *ngIf="currentUser.isAdmin()" class="fas fa-user-cog pointer mr-2" title="Configura" (click)="openSettings()"></i>
    </div> -->
    <div class="tab-content" id="myTabContent" >
        <div class="form-group form-group-sm">
            <div class="input-group input-group-sm">
                <select class="form-control form-control-sm font-weight-bold" #tabSelect (change)="tab=tabSelect.value;">
                    <option value="">Tutte le pratiche</option>
                    <ng-container class="nav-item" *ngFor="let t of types">
                        <option value="{{t.id}}">{{t.name}}</option>
                    </ng-container>
                </select>
                <div class="input-group-append input-group-append-sm" *ngIf="currentUser.isAdmin()">
                    <button type="button" class="btn btn-sm btn-default ml-2" (click)="openSettings()"><i class="fas fa-cog"></i><span class="d-none d-lg-inline ml-2">Configura</span></button>
                </div>
            </div>
        </div>
        <ng-container *ngFor="let t of types">
            <div class="tab-pane show active" *ngIf="tab==t.id">
                <app-dossierlist [mode]="mode" [id_type]="t.id" (selectedRecordOut)="recordSelected=$event" [modalWindow]="modalWindow" [mode]="mode"></app-dossierlist>
            </div>
        </ng-container>        
    </div>
</section>