import { Injectable } from '@angular/core';

import { ActivatedRoute, Router } from "@angular/router";
import { Globals } from "./globals";
import {Filter,FilterAdvanced,StateFilter} from "./include/structures";
import {Md5} from 'ts-md5/dist/md5';
import { Helper } from './helper';


export interface Navigation{
    url:string;
    insert:boolean;
    label:string;
  }

@Injectable()
export class NavigationHelper{

    history:Navigation[]=[]; //elenco di tutte le schermate aperte
    stateFilters:StateFilter[]=[];
    session:string;
    menu=[]; //menu nella sidebar
    menu_settings=[]; //menu nella sidebar
    skipLocationChange:boolean=false;

    title=""; //titolo del modulo corrente

    constructor(
        private router: Router,
        private activeroute: ActivatedRoute
    ){

    }
    

    getQueryString(){
      let search:string=window.document.location.search;
      
      search=Helper.getElementAttribute("param","query",search);
      
      if(search.indexOf("?") !== -1)
        search=search.split("?")[1];

      let params:any[]=[];
      if(search.length>0){
        //search=search.substr(1,search.length);
        //dividi i parametri
        let p=search.split("&");
        //per ogni parametro definisci key e value
        p.forEach((v,i)=>{
          let q:any={} as Object;
          let t=v.split("=");
          q['key']=t[0];
          q['value']=t[1];
          params.push(q);
        });


      }
      return params;
      
    }

    getPathName(){
      let pathname:string=window.document.location.pathname;
      return pathname;

    }

    getParam(querystringparams:any[],paramname:any,default_value:any){
      let p=querystringparams.filter(x=>x.key==paramname);
      if(p.length>0){
        if(typeof(default_value)=="boolean"){
          return p[0]['value']=='true'?true:false;
        }
        if(typeof(default_value)=="number")
          return parseInt(p[0]['value']);
          
        return decodeURIComponent(p[0]['value']);
      }else{
        return default_value;
      }
    }
    
    
    setTitle(title:string){
      this.title=title;
    }
    

    addHistory(label:string,insert:boolean=false,verifyInsert=true,overwrite=false){
        let nav=this.router.url;
  
        //verifica se il link è stato già inserito
        if(verifyInsert){
          for(let i=0;i<this.history.length;i++){
            if(this.history[i].url==nav)
              return;
          }
        }
  
        if(overwrite)
          this.history=[];
  
        let h:Navigation={} as Navigation;
        h.url=nav;
        h.insert=insert;
        h.label=label;
  
        this.history.push(h);
    }
  
    deleteLastHistory(){
      this.history.splice(this.history.length-1,1);
    }

    onNavigate(link:any):void{
     
      this.router.navigate(link,{ skipLocationChange: this.skipLocationChange });
    }
  

    addStateFilter(filters:Filter[],component,advanced:FilterAdvanced[]){
        let sf:StateFilter={} as StateFilter;
        sf.filters=filters;
        sf.component=component;
        sf.url=this.router.url;
        sf.advanced=advanced;
  
        for(let i=0;i<this.stateFilters.length;i++){
          if(this.stateFilters[i].url==this.router.url && this.stateFilters[i].component==component){
             this.stateFilters[i]=sf;
             return;
          }
        }
  
        this.stateFilters.push(sf);
    }

    getStateFilters(component,filters:Filter[]):FilterAdvanced[]{
      for(let i=0;i<this.stateFilters.length;i++){
        if(this.stateFilters[i].url==this.router.url && this.stateFilters[i].component==component){
          for(let j=0;j<filters.length;j++){
            if(JSON.stringify(filters[j].fields)==JSON.stringify(this.stateFilters[i].filters[j].fields)){
              filters[j].value=this.stateFilters[i].filters[j].value;
              filters[j].value2=this.stateFilters[i].filters[j].value2;
            }
              
          }

          
          return this.stateFilters[i].advanced;
        }
          
      }

     

      return [];
    }

    onBackLink(route:ActivatedRoute):void{
        if(route==null)
          route=this.activeroute;
        this.router.navigate(['.'], { relativeTo: route.parent });
      /*
        let nav;
        //prendi dalla storia l'ultima schermata aperta
        if(this.history.length>1){
          nav=this.history[this.history.length-2].url;
          //elimina l'elemento
          this.deleteLastHistory();
        }else{
          const UrlPath=this.router.url.split("/");
          nav="/"+UrlPath[UrlPath.length-3];
        }
        */
  
        
  
        //this.router.navigate([nav]);
  
        Globals.currentModel=null; //azzera il model corrente
  
    }

    createMenu(){
      this.menu=[];
      this.menu_settings=[];
      
      for(let m of Globals.parameters.modules){
        if(m.permissions.indexOf(Globals.user.role)>-1){
          if(m.type==1)
            this.menu.push(m);
          else
            this.menu_settings.push(m);
        }
          
      }
      this.newSession();
  
    }

    newSession(){
      const md5 = new Md5();
      Globals.navigation.session=md5.appendStr(new Date().toUTCString()+"_"+Globals.user.username+"_"+Math.random().toString()).end().toString();
                    
    }
}