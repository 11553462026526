
<div *ngIf="softwarebackground" 
        style="position:fixed; z-index:0;
            top:0; left:0;
            width:100%; height:100%;           
            background-image:url('{{softwarebackground}}');
            background-size: cover;
            background-color:#a8b6c4;"
            [style.background-color]="softwarecolor" >
    </div>

    <div class="login-box-body login-card-body elevation-1">
        <div class="login-logo">
            <img src="{{softwareicon}}" alt="LOGO" class="brand-image" style="height: 80px;">
            <p>{{softwarename}}</p>
        </div>

        <form *ngIf="tab==1" [formGroup]="loginForm" (ngSubmit)="login()" >
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="La tua Username" name="uname" formControlName="username" required=""  (keyup.enter)="onLogin()">
                <div class="input-group-append">
                    <div class="input-group-text border">
                    <span class="fas fa-user text-light"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input type="password" autocomplete class="form-control"  placeholder="La tua Password" name="passwd" formControlName="password" >
                <div class="input-group-append">
                    <div class="input-group-text border">
                    <span class="fas fa-key text-light"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                <button type="submit" class="btn btn-primary btn-block">Accedi</button>
                </div>
                <div class="col-2"></div>
            </div>
            <div class="row mt-3">
                <div class="col-12 text-center">
                    <input class="mb-0" type="checkbox" name="remember_connected" formControlName="remember_connected" ><span>&ensp;Ricordati di me</span>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 text-right">
                    <small class="pointer" (click)="tab=2">Ripristina password &xrarr;</small>
                </div>
            </div>
        </form>

        <form *ngIf="tab==2" [formGroup]="loginForm"  (ngSubmit)="requestResetPassword()" >
            <b>STEP 1</b><br>
            <div class="py-2" *ngIf="onerror">
                <i class="fa fa-exclamation-triangle text-warning mr-2"></i>{{errormessage}}
            </div>
            <!-- Email input -->
            <label>Inserisci il tuo nome utente</label>        
            <div class="input-group mb-3">
                <input placeholder="nome utente" type="email" id="username"  class="form-control" formControlName="username" required="" minlength="3" />
            </div>
            <!-- Submit button -->
            <button type="submit" class="btn btn-primary mt-4 btn-block">Continua</button>
            <button type="button" class="btn btn-outline-light btn-block" (click)="tab=1">Annulla</button>
        </form>

        <form *ngIf="tab==3" [formGroup]="resetForm" (ngSubmit)="resetPassword()" >
            <b>STEP 2</b><br>
            <div class="py-2" *ngIf="onerror">
                <i class="fa fa-exclamation-triangle text-warning mr-2"></i>{{errormessage}}
            </div>
            <div class="py-2" *ngIf="onsuccess">
                <i class="fa fa-check text-success mr-2"></i>{{successmessage}}
            </div>
            <!-- Pin input --> 
            <label>Inserisci il PIN che ti è stato inviato al tuo indirizzo email</label>        
            <div class="input-group mb-3">
                <input placeholder="pin" type="number" id="pin"  class="form-control" formControlName="pin" required="" minlength="3"  />           
            </div>        
            <!-- New Password input -->
            <label>Inserisci la nuova password</label>
            <div class="input-group mb-3">
                <input placeholder="nuova password" type="password" id="newpassword"  class="form-control" formControlName="newpassword" required="" minlength="3" />
            </div>        
            <!-- Submit button -->
            <button type="submit" class="btn btn-primary btn-block mt-4">Ripristina</button>
            <button type="button" class="btn btn-outline-light btn-block" (click)="tab=1">Annulla</button>        
            <div class="row mt-4">
                <div class="col-12 text-center">
                    <span>Email non arrivata?&ensp;<b class="pointer" (click)="requestResetPassword()">Rinvia il PIN</b></span>
                </div>
            </div>
        </form>

    </div>

    <div *ngIf="copyright" class="animation__shake fixed-bottom text-light text-xs text-center m-3">
        <b>C1</b> is a <a class="text-light" href="http://codingict.it" target="_blank"><b>Coding</b></a> software
    </div>