
<div class="modal-header py-1 bg-secondary" *ngIf="mode=='modal'">
    <img class="d-none d-lg-inline" *ngIf="config.softwareicon" src="{{config.softwareicon}}" style="max-height: 33px;width: auto;">
    <h5 class="mt-1">Prenotazione guidata</h5>
    <div class="card-tools mt-2">
        <button type="button" class="btn btn-tool text-white" (click)="closeWindow();" >
          <i class="fas fa-times"></i>
        </button>
    </div>
</div>

<!-- Noleggio / Tour -->
<div class="modal-body pt-1">
    <ng-container *ngIf="step==0">
        <div class="row py-2">
            <div class="col-12 text-center"><h3>Cosa vuoi prenotare?</h3></div>            
            <div class="col-12 col-lg-6 text-center p-3">
                <button class="btn btn-lg btn-outline-primary btn-block p-3" style="height:30vh" (click)="step=1;this.type_booking=1;">
                    <i class="fa-3x {{icon_rent}}"></i><br/>
                    Noleggio
                </button>
            </div>
            <div class="col-12 col-lg-6 text-center p-3">
                <button class="btn btn-lg btn-outline-warning btn-block p-3" style="height:30vh" (click)="step=99;this.type_booking=2;">
                    <i class="fa-3x {{icon_tour}}"></i><br/>
                    Tour
                </button>
            </div>
            <!--
            <div class="col-12 col-lg-4 text-center p-3">
                <button class="btn btn-lg btn-outline-success btn-block p-3" style="height:30vh" (click)="this.type_booking=3;">
                    <i class="fa-3x {{icon_tour}}"></i><br/>
                    Tour<br>TailorMade
                </button>
            </div>
            -->
        </div>
    </ng-container>
    
    <!-- Dati noleggio -->
    <ng-container *ngIf="step==1">
        <div class="row">
            <div class="col-12">
                <h5>Noleggio</h5>
            </div>
            <div class="col-lg-6 col-12"> <!-- Date start-->
                <label for="date_arrival">Data prelievo</label>	
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-gray"><i class="far fa-calendar-alt"></i></span>
                    </div>
                    <input name="date_start" placeholder="dd/mm/yyyy" [(ngModel)]="record.date_start" type="date" class="form-control"  aria-label="date_arrival" aria-describedby="date_arrival" (change)="onChangeDateStart();checkPriceList()" >
                </div>
            </div>
            <div class="col-lg-6 col-12"> <!-- Date end-->
                <label for="date_departure">Data rilascio</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-gray"><i class="far fa-calendar-alt"></i></span>
                    </div>
                    <input name="date_end" [(ngModel)]="record.date_end" type="date" class="form-control"  value="01/01/2021" aria-label="date_departure" aria-describedby="date_departure" (change)="checkPriceList();">
                </div>
            </div>
            <div class="col-lg-6 col-12"> <!-- Time start-->
                <label for="hour_arrival">Ora prelievo</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-gray"><i class="far fa-clock"></i></span>
                    </div>
                    
                    <select name="time_start" [(ngModel)]="record.time_start" class="custom-select text-center" id="hour_arrival" (change)="checkPriceList();">
                        <!-- <option class="text-secondary font-weight-bold" disabled>ORA PRELIEVO</option>	 -->
                        <ng-container *ngFor="let e of [].constructor(20); let i = index">
                            <option *ngIf="i>7" value="{{i | number:'2.0' }}:30">{{i | number:'2.0' }}:30</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-12"> <!-- Time end-->
                <label for="hour_departure">Ora rilascio</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-gray"><i class="far fa-clock"></i></span>
                    </div>
                
                    <!-- <input type="text" class="form-control text-center" value="20:00" aria-label="hour_departure" aria-describedby="hour_departure"> -->
                    <select name="time_end" [(ngModel)]="record.time_end" class="custom-select text-center" id="hour_departure" (change)="checkPriceList();">
                        <!-- <option class="text-secondary font-weight-bold" disabled>ORA RILASCIO</option> -->
                        <ng-container *ngFor="let e of [].constructor(20); let i = index">
                            <option *ngIf="i>7" value="{{i | number:'2.0' }}:30">{{i | number:'2.0' }}:30</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-12"> <!-- Time end-->
                <label for="hour_departure">Luogo prelievo</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-gray"><i class="fas fa-map-marker-alt"></i></span>
                    </div>
                    <!-- <input type="text" class="form-control text-center" value="20:00" aria-label="hour_departure" aria-describedby="hour_departure"> -->
                    <select [class.alert-danger]="!record.shop_start.city" name="shop_start" [(ngModel)]="record.shop_start" class="custom-select text-center" id="shop_start" (change)="checkPriceList();getTours();record.shop_end=record.shop_start">
                        <!-- <option class="text-secondary font-weight-bold" disabled>LUOGO PRELIEVO</option> -->
                        <ng-container *ngFor="let s of shops">
                            <option *ngIf="s.city" [ngValue]="s">{{s.name}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 col-12"> <!-- Time end-->
                <label for="hour_departure">Luogo rilascio</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-gray"><i class="fas fa-map-marker-alt"></i></span>
                    </div>
                    <!-- <input type="text" class="form-control text-center" value="20:00" aria-label="hour_departure" aria-describedby="hour_departure"> -->
                    <select [class.alert-danger]="!record.shop_end.city" name="shop_end" [(ngModel)]="record.shop_end" class="custom-select text-center" id="shop_end">
                        <!-- <option class="text-secondary font-weight-bold" disabled>LUOGO RILASCIO</option> -->
                        <ng-container *ngFor="let s of shops">
                            <option *ngIf="s.city" [ngValue]="s">{{s.name}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="col-lg-3 col-12"> <!-- Adults-->
                <label>Adulti</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-gray"><i class="fas fa-male"></i></span>
                    </div>
                    <input type="number" min="1" class="form-control" name="adults" value="1" [(ngModel)]="record.adults">
                </div>
            </div>
            <div class="col-lg-3 col-12"> <!-- Children-->
                <label>Bambini <small>(fino a 12 anni)</small></label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-gray"><small><i class="fas fa-child"></i></small></span>
                    </div>
                    <input type="number"  min="0" class="form-control" name="children" value="1" [(ngModel)]="record.children">
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <button class="btn btn-success float-right mt-4" [disabled]="(!record.shop_start.city || !record.shop_end.city) || (record.date_start==record.date_end && record.time_start==record.time_end)" (click)="goToStep2()">Avanti<i class="fas fa-chevron-right ml-2"></i></button>
            </div>
        </div>
         <!-- elenco tour-->
        <div class="row"  *ngIf="tours.length>0">
            <div class="col-12">
            <hr>
                <label>Tour suggeriti</label>
            </div>
            <div class="col-6 col-lg-3" *ngFor="let t of tours">
                <div class="card">
                    <div class="card-header p-2 bg-warning">
                        <small>
                            <div class="w-100 text-truncate"><b>{{t.title}}</b></div>
                            <div class="w-100 text-truncate"><i>{{t.subtitle}}</i></div>
                        </small>
                    </div>
                    <div class="card-body p-2 alert-warning">
                        <small>
                            Ora di partenza: <b>{{t.dates[0].time_start.substr(0,5)}}</b><br/>
                            Costo per adulto: <b>{{t.dates[0].price_adult | currency:"&euro;"}}</b><br/>
                            Costo per bambino: <b>{{t.dates[0].price_children | currency:"&euro;"}}</b>
                            <br/>
                            Posti liberi: <b>{{t.dates[0].quantity}}</b>
                        </small><br/>
                        <button class="btn btn-success btn-xs float-right" (click)="addTour(t);">Seleziona<i class="fas fa-chevron-right ml-2"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- SELEZIONE PRODOTTI-->
    <ng-container *ngIf="step==2">
        <div class="row">
            <div class="col-md-6 px-1">
                <div class="card mb-0 bg-light">
                    <div class="card-header p-1">
                        <b>Prodotti in catalogo</b>
                    </div>
                    <ng-container *ngIf="currentUser.role!='guest' && currentUser.role!='agent' && currentUser.role!='external'">
                        <div class="col-12 p-1">
                            <div class="input-group input-group-sm">
                                <input type="text" autofocus="true"  #searchCode class="form-control form-control-sm" placeholder="cerca per codice articolo, EAN o numero seriale..." name="search" [(ngModel)]="search" (keyup.enter)="searchProduct()" >
                                <div class="input-group-append input-group-append-sm">
                                    <button class="btn btn-primary btn-sm " (click)="searchProduct()"><i class="fa fa-search"></i></button>
                                </div>
                                <br>
                                
                            </div>
                            <small *ngIf="currentUser.role!='guest'"><input checked type="checkbox" (change)="only_available==true?only_available=false:only_available=true"><i><span class="ml-2"></span>visualizza solo prodotti disponibili</i></small>
                        </div>
                    </ng-container>
                    <div class="px-3" style="overflow-y: scroll; max-height: 50vh;">
                        <!-- <ng-container *ngIf="tours.length>0 ">
                            <div class="col-12 p-1">
                                <h5 class="border-top text-gray mb-0">Tour</h5>
                            </div>
                            <ng-container *ngFor="let t of tours">
                                <ng-container *ngIf="!only_available || (t.dates[0].quantity>0 && only_available)">
                                    <div class="col-lg-6 p-1" >
                                        <div class="card bg-light mb-1">
                                            <img (click)="addProduct(p)" src="{{t.coverImage().imagePath}}" alt="{{t.id}}"  title="{{t.title}}" style="text-align:center;color:#ccc;height:7vh;">
                                            <div class="p-1">
                                                <div style="text-align:center;white-space:nowrap; overflow:hidden; text-overflow: ellipsis;"  title="{{t.title}}">
                                                <small>{{t.title}}</small>
                                                </div>
                                                <button (click)="addTour(t)" class="btn-xs btn-block btn btn-warning">
                                                    <small *ngIf="t.dates[0].quantity" class="badge badge-success">{{t.dates[0].quantity}} posti</small>
                                                    Aggiungi</button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container> -->
                        <ng-container *ngFor="let c of categories">
                            <div class="row">
                                <ng-container *ngIf="c.products.length>0">
                                    <div class="category_title col-12 p-1 mt-3 border-top text-center">
                                        <h5 class="text-gray mb-0">{{c.name}} </h5>
                                    </div>
                                    <ng-container *ngFor="let p of c.products">
                                        <ng-container *ngIf="(!only_available || (p.stock>0 && only_available) || !p.stockable) && checkProductByTag(p)">
                                            <div class="col-6 col-lg-4 col-xl-3 p-1">
                                                <div class="card bg-light mb-1">
                                                    <div style="background-image: url({{p.coverImage().imagePath}}); background-repeat:no-repeat; background-position:center; background-size:contain; height:10vh; width:100%; background-color:white;">
                                                        <ng-container *ngIf="currentUser.role!='guest' && currentUser.role!='agent' && currentUser.role!='external'">
                                                            <span *ngIf="p.stock>0 && view_stock" class="badge badge-success text-xs m-1 float-left"><small>{{p.stock}} {{p.unit}}</small></span>
                                                        </ng-container>
                                                        <i class="fas fa-info-circle text-primary m-1 float-right pointer" (click)="openModalProductDetails(p)"
                                                         title="Clicca per avere maggiori informazioni"></i>
                                                    </div>
                                                    <!--<img (click)="addProduct(p)" src="{{p.coverImage().imagePath}}" alt="{{p.id}}"  title="{{p.name}}" style="text-align:center;color:#ccc;height:8vh;">-->
                                                    <div class="bg-white text-center text-truncate"  title="{{p.name}}">
                                                        <b>
                                                            <small *ngIf="p.title!=''">{{p.title}}</small>
                                                            <small *ngIf="p.title==''">{{p.name}}</small>
                                                        </b>
                                                    </div>
                                                    <div class="p-2">
                                                        <button (click)="addProduct(p)" class="btn-sm btn-block btn btn-outline-primary">
                                                            <i class="fas fa-plus mr-2"></i>Aggiungi</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-6 px-1">
                <div class="card mb-0 bg-light mt-2 mt-lg-0">
                    <div class="card-header p-1">
                        <b>Carrello</b>
                        <!-- <small class="float-right" *ngIf="priceList"> Tariffa: <b>{{priceList.name}}</b></small> -->
                        <small class="float-right"  *ngIf="priceList && this.type_booking==1"> Tariffa: <b>{{priceList.name}}</b>
                            <i class="fas fa-info-circle text-primary mx-2 pointer" (click)="openModalRentalDetails()"  title="Clicca per avere maggiori dettagli sulla tariffa"></i>
                              <small>({{record.duration}} <span *ngIf="record.duration>1">giorni</span><span *ngIf="record.duration==1">giorno</span> di prenotazione)</small></small>
                    </div>
                    <ul class="list-group bg-white text-dark" style="overflow-y: scroll;min-height:10vh;max-height: 40vh;">
                        <li class="list-group-item bg-light border-bottom" *ngIf="record.details.length>0">
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-10">
                                    <div class="row" >
                                        <div class="col-2 px-1">
                                            <small *ngIf="record.type==1">Qt</small>
                                            <small *ngIf="record.type==2">Pax/Qt</small>
                                        </div>
                                        <div class="col-4 px-1 text-right">
                                            <small>Prezzo Un.</small>
                                        </div>
                                        <div class="col-4 pl-0 pr-1 text-right">
                                            <small>Totale</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li  class="list-group-item" *ngIf="record.details.length==0"><div class="mt-4 text-center">Il carrello è vuoto.</div></li>
                        <ng-container *ngFor="let d of record.details;let i=index">
                            <li  class="list-group-item" *ngIf="!d['onlyDescription']">
                                <div class="row border-bottom">
                                    <div class="col-2" style="background-image: url({{d.product.coverImage().imagePath}});background-repeat:no-repeat; background-position:center; background-size:cover;height:55px;width:100%;background-color:white;">
                                    </div>
                                    <div class="col-10 mb-1">
                                        <div class="row">
                                            <div class="col-12 px-1 pb-1" >
                                                <small>{{d.product.name}}</small>
                                                <i *ngIf="record.type==2" class="fas fa-info-circle ml-1 pointer" (click)="openModalTourDetails(d.product['tour'])"
                                                 title="Clicca per avere maggiori dettagli sul Tour"></i>
                                            </div>
                                            <div class="col-2 px-1 text-right" >
                                                <input type="number" class="form-control form-control-sm mb-0" min="1" step="1" name="{{i}}_quantity" [(ngModel)]="d.quantity" (change)="calculateTotal()">
                                            </div>
                                            <div class="col-4 px-1" >
                                                <input *ngIf="currentUser.role!='external' && currentUser.role!='agent' && currentUser.role!='guest' " class="form-control form-control-sm mb-0 text-right " name="{{i}}_price" value="{{d.gross}}" (change)="onChangeAmount($event,d)">
                                                <span *ngIf="currentUser.role=='external' || currentUser.role=='agent' || currentUser.role=='guest'" class="bg-light form-control form-control-sm mb-0 text-right " >{{d.price*(1+d.tax/100) | currency:' &euro;'}}</span>
                                            </div>
                                            <div class="col-4 pl-0 pr-1 text-right" >
                                                <span class="bg-light form-control form-control-sm mb-0 bg-light text-right">{{d.price*(1+d.tax/100)*d.quantity | currency:' &euro;'}}</span>
                                            </div>
                                            <div class="col-2 px-0 pt-1 text-center">
                                                <a class="btn btn-xs btn-danger float-right" (click)="removeProduct(d)"  title="Elimina"><i class="fas fa-trash "></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                    <div class="card-footer py-1">
                        <div class="row">
                            <div class="col-8">
                                <h6 class="mb-0">TOTALE <small>(IVA inclusa)</small></h6>
                            </div>
                            <div class="col-4 px-0 text-right">
                                <h6 class="mb-0"><b>{{total_gross | currency:"&euro; "}}</b></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center mt-3">
                <button *ngIf="type_booking!=2 && (step!=2 && !autocompileRent)" class="btn btn-secondary float-left" (click)="goToStep1(true)">
                    <i class="fas fa-chevron-left mr-2"></i>Indietro
                </button>
                <!-- <span>2 / 5</span> -->
                <button [disabled]="record.details.length==0 || total_gross==0" class="btn btn-success float-right" (click)="goToStep3()">Avanti<i class="fas fa-chevron-right ml-2"></i></button>
            </div>
        </div>
    </ng-container>

    <!-- SELEZIONE PARTECIPANTI-->
    <ng-container *ngIf="step==3">
        <div class="row">
            <div class="col-12 mb-2">
                <h5>Partecipanti</h5>
                <small><i class="fas fa-info-circle mr-2"></i>
                    <i>Inserisci per ogni partecipante il suo <b>nome</b> e <b>cognome</b>, il suo <b>documento di riconoscimento</b> (scatta una foto oppure caricala dal tuo dispositivo) e gli altri dati richiesti.<br>
                    Per non perdere tempo, noi ti consigliamo di farlo ora! Ma, se vuoi potrai inserirli al <b>check-in</b></i>.</small>
            </div>
            <ng-container *ngFor="let passenger of record.confirmDetail.passengers;let i=index">
                <div class="col-md-8">
                    <b>Partecipante {{i+1}}</b>
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control form-control-sm" name="passenger_{{passenger.id}}" placeholder="Nome e cognome..." [(ngModel)]="passenger.value" />
                        <span class="input-group-append input-group-append-sm">
                            <!-- <span class="input-group-text">Documento di riconoscimento</span> -->
                            <button *ngIf="passenger.images.length<2" class="btn btn-flat btn-info" (click)="takephoto(passenger)"><i class="fas fa-camera"></i><span class="d-none d-lg-inline-block ml-2">Scatta foto I.D.</span></button>
                            <button *ngIf="passenger.images.length<2" class="btn btn-flat btn-secondary" (click)="passengerSelected=passenger;fileToUpload.click()"><i class="far fa-image"></i><span class="d-none d-lg-inline-block ml-2">Sfoglia</span></button>
                        </span>
                        <ng-container *ngFor="let rpp of requestparamspartecipants">
                            <div class="input-group input-group-sm mt-1">
                                <span class="input-group-append input-group-prepend-sm">
                                    <span class="input-group-text input-group-text-sm bg-gray">{{rpp.label}}</span>
                                </span>
                                <ng-container *ngIf="rpp.type=='select'">
                                    <select class="form-control form-control-sm" aria-placeholder="{{rpp.label}}..." name="passenger_requestparam_{{passenger.id}}" [(ngModel)]="passenger.requestparams[rpp.name]">
                                        <option *ngFor="let v of rpp.params.split(';')" value="{{v}}">{{v}}</option>
                                    </select>
                                    <span class="input-group-append input-group-append-sm">
                                        <span class="input-group-text input-group-text-sm bg-light"><small><i>{{rpp.description}}</i></small></span>
                                    </span>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="pt-3 mr-2">
                        <ng-container *ngFor="let image of passenger.images">
                        <a href="{{image.imagePath}}" target="_blank"> <img *ngIf="image" src="{{image.imagePath}}" style="height:70px" /></a>
                        <button class="btn btn-xs btn-danger m-1" (click)="removeImage(passenger,image)"><i class="fas fa-trash "></i></button>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
         </div>
       
        <input type="file" style="display: none;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
        <div class="row">
            <div class="col-12 text-center mt-3">
                <button class="btn btn-secondary float-left" (click)="step=2"><i class="fas fa-chevron-left mr-2"></i>Indietro</button>
                <!-- <span>3 / 5</span> -->
                <button [disabled]="record.details.length==0" class="btn btn-success float-right" (click)="step=4">Avanti<i class="fas fa-chevron-right ml-2"></i></button>
            </div>
        </div>
    </ng-container>

    <!-- SELEZIONE ANAGRAFICA-->
    <ng-container *ngIf="step==4">
        <ng-container *ngIf="currentUser.role=='external' && record.id_address>0">
            <div class="col-12 mb-3">
                <h5>Identificativo prenotazione</h5>
                <small><i class="fas fa-info-circle mr-2"></i><i>Inserisci dati identificativi della prenotazione ad uso interno (queste informazioni non saranno visibili al cliente)</i></small>
            </div>
            <div class="form-group row">
                <div class="col-12 col-lg-3">
                    <label>Nominativo</label>
                </div>
                <div class="col-12 col-lg-9">
                    <div class="input-group">
                        <input [class.alert-danger]="!record.clientname" type="text"  class="form-control form-control-sm" placeholder="inserisci il nominativo cliente..." name="clientname" [(ngModel)]="record.clientname">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12 col-lg-3">
                    <label>Email Cliente</label>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="input-group">
                        <input [class.alert-danger]="!record.clientemail"  type="email"  class="form-control form-control-sm" placeholder="inserisci l'email del Cliente (su cui riceverà le notifiche)..." name="clientemail" [(ngModel)]="record.clientemail">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12 col-lg-3">
                    <label>Tel. cellulare Cliente</label>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="input-group">
                        <input [class.alert-danger]="!record.clientphone" type="text"  class="form-control form-control-sm" placeholder="inserisci un numero di telefono cellulare del Cliente..." name="clientphone" [(ngModel)]="record.clientphone">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12 col-lg-3">
                    <label>Stanza</label>
                </div>
                <div class="col-12 col-lg-9">
                    <div class="input-group">
                        <input type="text"  class="form-control form-control-sm" placeholder="inserisci il numero di stanza e/o altre indicazioni (nome operatore, esigenze particolari del cliente, etc)..." name="clientnote" [(ngModel)]="record.clientnote">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center mt-3">
                    <button class="btn btn-secondary float-left" (click)="goToStep3(true)"><i class="fas fa-chevron-left mr-2"></i>Indietro</button>
                    <!-- <span>3 / 5</span> -->
                    <button class="btn btn-success float-right" class="btn btn-success  float-right" (click)="step=5" 
                        [disabled]="!record.clientname || !record.clientemail || !record.clientphone">
                        Avanti<i class="fas fa-chevron-right ml-2"></i>
                    </button>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="currentUser.role!='external'">
            <div class="row mb-3">
                <div class="col-12">
                    <h5>Dati di fatturazione</h5>
                    <small *ngIf="currentUser.role!='guest'"><i class="fas fa-info-circle mr-2"></i>Se il cliente è già <b>registrato</b> premi su <i class="fas fa-user"></i> oppure inserisci il suo <b>Codice Fiscale</b> o la sua <b>Partita IVA</b> o la sua <b>email</b> e premi su <i class="fas fa-search"></i>.</small>
                    <small *ngIf="currentUser.role=='guest'"><i class="fas fa-info-circle mr-2"></i>Se sei già <b>registrato</b>, inserisci la tua <b>email</b>, oppure il tuo <b>Codice Fiscale</b> o la <b>Partita IVA</b>, e premi su <b>cerca</b>.</small>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12 col-lg-2">
                    <label>Nominativo</label>
                </div>
                <div class="col-12 col-lg-10">
                    <div class="input-group">
                        <input [class.alert-danger]="!record.addressItem.name" type="text" [readonly]="record.id_address>0" class="form-control form-control-sm" name="name"
                        [(ngModel)]="record.addressItem.name">
                        <div class="input-group-prepend" *ngIf="currentUser.role!='agent' && currentUser.role!='guest'">
                            <button class="btn btn-xs btn-secondary" (click)="searchAddresses()"><i class="fas fa-user mx-1"></i></button>
                            <!-- <button class="btn btn-xs btn-success" (click)="newAddresses()"><i class="fas fa-plus mx-2"></i><span class="d-none d-lg-inline-block">Nuovo</span></button> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12 col-lg-2">
                    <label>Email</label>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="input-group input-group-sm">
                        <input [class.alert-danger]="!record.addressItem.username" type="email" [readonly]="record.id_address>0" required id="username" name="username" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                        [(ngModel)]="record.addressItem.username" #username_ref="ngModel" class="form-control form-control-sm" 
                        [class.is-invalid]="record.addressItem.username==''"> <!--(focusout)="verifyUsername()"-->
                        <div class="input-group-append" >
                            <button *ngIf="record.id_address==0 && currentUser.role!='guest'" class="btn btn-secondary btn-sm" (click)="verifyUsername()"><i class="fa fa-search"></i><span >&ensp;cerca</span></button>
                            <button *ngIf="record.id_address>0 && currentUser.role=='guest'" class="btn btn-secondary btn-sm" (click)="logout()">Disconnetti</button>
                        </div>
                    </div>
                    <small *ngIf="record.addressItem.username==''"><div class="badge badge-danger">Obbligatorio</div></small>
                </div>
                <div class="col-12 col-lg-2 text-lg-right">
                    <label>Telefono</label>
                </div>
                <div class="col-12 col-lg-4">
                    <input [class.alert-danger]="!record.addressItem.phone" type="tel" [readonly]="record.id_address>0"  class="form-control form-control-sm" name="phone" [(ngModel)]="record.addressItem.phone">
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12 col-lg-2">
                    <label>Residente in</label>
                </div>
                <div class="col-12 col-lg-4">
                    <app-nations [disabled]="record.id_address>0"  [class]="'form-control form-control-sm'" [nation]="record.addressItem.nation"  (onSelect)="onChangeNation($event);"></app-nations> <!-- (onSelect)="record.addressItem.nation=$event" -->
                </div>
            </div>

            <div class="form-group row mt-2" *ngIf="record.addressItem.nation=='IT'">
                <div class="col-12 col-lg-2">
                    <label>Codice Fiscale</label>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="input-group">
                        <input [class.alert-danger]="!record.addressItem.tax_code && !verifyTaxCode() && !record.addressItem.vat_number && !verifyVATNumber()" type="text" [readonly]="record.id_address>0 || record.addressItem.nation!='IT'" 
                        maxlength="16" [class.is-invalid]="record.addressItem.tax_code && !verifyTaxCode()" (ngModelChange)="tax_code = $event.toUpperCase()"
                        pattern="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$" title="{{tax_code_error}}"  
                        class="form-control form-control-sm text-uppercase" name="tax_code" [(ngModel)]="record.addressItem.tax_code" >  <!---- (focusout)="searchAddress(record.addressItem.tax_code)" -->
                        <div class="input-group-append input-group-append-sm" *ngIf="currentUser.role!='guest'">
                            <button  class="btn btn-secondary btn-sm" (click)="searchAddress(record.addressItem.tax_code)"><i class="fa fa-search"></i><span *ngIf="currentUser.role=='guest'">&ensp;cerca</span></button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-2 text-lg-right">
                    <label>Partita IVA</label>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="input-group">
                        <input [class.alert-danger]="!record.addressItem.tax_code && !verifyTaxCode() && !record.addressItem.vat_number && !verifyVATNumber()" type="text" [readonly]="record.id_address>0 || record.addressItem.nation!='IT'"
                            maxlength="11" [class.is-invalid]="record.addressItem.vat_number && !verifyVATNumber()" 
                            pattern="[0-9]{11}" title="{{vat_number_error}}"  
                            class="form-control form-control-sm" name="vat_number" [(ngModel)]="record.addressItem.vat_number" > <!---- (focusout)="searchAddress(record.addressItem.vat_number)" -->
                            <div class="input-group-append input-group-append-sm" *ngIf="currentUser.role!='guest'">
                                <button class="btn btn-secondary btn-sm" (click)="searchAddress(record.addressItem.vat_number)"><i class="fa fa-search"></i><span *ngIf="currentUser.role=='guest'">&ensp;cerca</span></button>
                            </div>
                    </div>
                </div>
            </div>

            <app-cities name="city"  [readonly]="record.id_address>0" [foreign]="record.addressItem.nation!='IT'" [address]="record.addressItem.address" [country]="record.addressItem.country" [city]="record.addressItem.city"  [zip]="record.addressItem.zip" (onEntrySelected)="record.addressItem.address=$event.address;record.addressItem.city=$event.city;record.addressItem.country=$event.country;record.addressItem.zip=$event.zip;"></app-cities>
            
            <div class="form-group row mt-2" *ngIf="record.addressItem.nation=='IT'">
                <div class="col-12 col-lg-2">
                    <label>SdI</label>
                </div>
                <div class="col-12 col-lg-2">
                    <input [class.alert-danger]="!record.addressItem.pec && !record.addressItem.sdi" type="text" [readonly]="record.id_address>0 || record.addressItem.nation!='IT'" class="form-control form-control-sm text-uppercase" name="sdi"
                        maxlength="7" [class.is-invalid]="record.addressItem.sdi && record.addressItem.sdi.toString().length < 6" 
                        [(ngModel)]="record.addressItem.sdi" (ngModelChange)="sdi = $event.toUpperCase()">
                </div>
                <div class="col-12 col-lg-2"></div>
                <div class="col-12 col-lg-2 text-lg-right">
                    <label>PEC</label>
                </div>
                <div class="col-12 col-lg-4">
                    <input  [class.alert-danger]="!record.addressItem.pec && !record.addressItem.sdi" type="email" required name="pec" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                    [(ngModel)]="record.addressItem.pec" #pec_ref="ngModel" class="form-control form-control-sm" 
                    [readonly]="record.id_address>0 || record.addressItem.nation!='IT'" [class.is-invalid]="record.addressItem.pec && pec_ref.invalid">
                </div>
            </div>
            <div class="row mt-2" *ngIf="record.id_address>0">
                <div class="col-12">
                    <ng-container *ngIf="currentUser.role!='agent' && currentUser.role!='external' && currentUser.role!='guest'">
                        <button  class="btn btn-sm btn-outline-secondary ml-1" (click)="editAddress()"><i class="fas fa-pencil-alt"></i><span class="d-none d-lg-inline-block ml-2">Modifica Anagrafica</span></button>
                    </ng-container>
                    <button class="btn btn-sm btn-outline-danger danger ml-1" (click)="resetAddress()">Azzera</button>
                </div>
                <ng-container *ngIf="currentUser.role=='agent' || currentUser.role=='external'">
                    <div class="col-12">
                        <textarea class="form-control form-control-sm" name="note" [(ngModel)]="record.note" placeholder="Qui puoi suggerire modifiche ai dati di fatturazione già registrati"></textarea>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-12 text-center mt-3">
                    <button class="btn btn-secondary float-left" (click)="goToStep3(true)"><i class="fas fa-chevron-left mr-2"></i>Indietro</button>
                    <!-- <span>4 / 5</span> -->
                    <button class="btn btn-success float-right" class="btn btn-success  float-right" (click)="goToStep5()" 
                        [disabled]="!record.addressItem.name || !record.addressItem.address || record.addressItem.zip.length != 5 || (record.addressItem.nation=='IT' && !record.addressItem.country) || !record.addressItem.city || !record.addressItem.username || (record.addressItem.nation=='IT' && (record.tax_code!='' && !verifyTaxCode()) && (record.vat_number!='' && !verifyVATNumber())) || (record.vat_number=='' && record.tax_code=='') || (record.addressItem.sdi.length < 6 && !record.addressItem.pec)">
                        Avanti<i class="fas fa-chevron-right ml-2"></i>
                    </button>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <!-- TOTALE -->
    <ng-container *ngIf="step==5">
        <div class="row">
            <div class="col-12 col-lg-5 p-1 border-right">
                <h5>Riepilogo</h5>
                <ul class="list-group bg-white text-dark">
                    <li class="list-group-item bg-light border-top">
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-10">
                                <div class="row">
                                    <div class="col-2 px-1">
                                        <small>Qt</small>
                                    </div>
                                    <div class="col-5 px-1 text-right">
                                        <small>Prezzo Un.</small>
                                    </div>
                                    <div class="col-5 pl-0 pr-1 text-right">
                                        <small>Totale</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <ng-container *ngFor="let d of record.details;let i=index">
                        <li  class="list-group-item" *ngIf="!d['onlyDescription']">
                            <div class="row border-bottom">
                                <div class="col-2" style="background-image: url({{d.product.coverImage().imagePath}});background-repeat:no-repeat; background-position:center; background-size:cover;height:55px;width:100%;background-color:white;">
                                </div>
                                <div class="col-10 mb-1">
                                    <div class="row">
                                        <div class="col-12 px-1 pb-1" >
                                            <small>{{d.product.name}}</small>
                                        </div>
                                        <div class="col-2 px-1 text-right" >
                                            <input type="number" class="form-control form-control-sm mb-0" min="1" step="1" name="{{i}}_quantity" [(ngModel)]="d.quantity" (change)="calculateTotal()">
                                        </div>
                                        <div class="col-5 px-1" >
                                            <span class="bg-light form-control form-control-sm mb-0 text-right " >{{d.price*(1+d.tax/100) | currency:' &euro;'}}</span>
                                        </div>
                                        <div class="col-5 pl-0 pr-1 text-right" >
                                            <span class="bg-light form-control form-control-sm mb-0 bg-light text-right">{{d.price*(1+d.tax/100)*d.quantity | currency:' &euro;'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
                <h3 class="text-center py-2">Totale prenotazione   
                    <strong>{{total_gross | currency:"&euro; "}}</strong>
                </h3>
            </div>
            <div class="col-12 col-lg-7 pt-2">
                <ng-container *ngIf="(currentUser.role!='guest' && currentUser.role!='external')">
                    <div class="form-group row">
                        <div class="col-12 col-lg-3">
                            <label>Luogo di partenza</label>
                        </div>
                        <div class="col-12 col-lg-9">
                            <input type="text" class="form-control form-control-sm" name="location_start" placeholder="inserisci il luogo esatto di partenza..." [(ngModel)]="record.location_start">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12 col-lg-3">
                            <label>Luogo di arrivo</label>
                        </div>
                        <div class="col-12 col-lg-9">
                            <input type="text" class="form-control form-control-sm" name="location_end" placeholder="inserisci il luogo esatto di arrivo..." [(ngModel)]="record.location_end">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12 col-lg-3">
                            <label>Annotazioni in contratto</label>
                        </div>
                        <div class="col-12 col-lg-9">
                            <textarea class="form-control form-control-sm" name="note2" placeholder="inserisci eventuali annotazioni visibili nel contratto..." [(ngModel)]="record.note2"></textarea>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="(currentUser.role!='guest' && currentUser.role!='external')">
                    <div class="form-group row">
                        <div class="col-12 col-lg-3">
                            <label>Totale pagato (&euro;)</label>
                        </div>
                        <div class="col-12 col-lg-3">
                                <input type="number" class="form-control form-control-sm" [(ngModel)]="paid">
                        </div>
                        <div class="col-12 col-lg-6 pt-1">
                            <span *ngIf="total_gross - paid > 0" class="badge badge-warning">Acconto</span>
                            <span *ngIf="total_gross - paid < 0" class="badge text-danger">Pagato in eccesso ?</span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="currentUser.role!='guest'">
                    <div class="form-group row" *ngIf="currentUser.role!='external'">
                        <div class="col-12 col-lg-3">
                            <label>Tipo di prenotazione</label>
                        </div>
                        <div class="col-6 col-lg-4">
                            <a class="btn btn-block btn-outline-secondary" [class.bg-primary]="record.state!=6" (click)="record.state=1;record.type_address=0;"><small>Prenotazione verso</small><br><b>Cliente</b><br>(B2C)</a>
                        </div>
                        <div class="col-6 col-lg-4">
                            <a class="btn btn-block btn-outline-secondary" [class.bg-primary]="record.state==6" (click)="record.state=6;record.methodpayment='Contanti';record.type_address=1;"><small>Prenotazione verso</small><br><b>Partner</b><br>(B2B)</a>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="record.state==6 && currentUser.role!='external' && currentUser.role!='guest'">
                    <div class="card bg-light mt-3 p-3">
                        <h5>Identificativo prenotazione</h5>
                        <div class="form-group row">
                            <div class="col-12 col-lg-3">
                                <label>Nominativo</label>
                            </div>
                            <div class="col-12 col-lg-9">
                                <div class="input-group">
                                    <input [class.alert-danger]="!record.clientname" type="text"  class="form-control form-control-sm" placeholder="inserisci il nominativo cliente..." name="clientname" [(ngModel)]="record.clientname">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 col-lg-3">
                                <label>Email Cliente</label>
                            </div>
                            <div class="col-12 col-lg-9">
                                <div class="input-group">
                                    <input [class.alert-danger]="!record.clientemail" type="email"  class="form-control form-control-sm" placeholder="inserisci l'email del Cliente (su cui riceverà le notifiche)..." name="clientemail" [(ngModel)]="record.clientemail">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12 col-lg-3">
                                <label>Tel. cellulare Cliente</label>
                            </div>
                            <div class="col-12 col-lg-9">
                                <div class="input-group">
                                    <input [class.alert-danger]="!record.clientphone"  type="text"  class="form-control form-control-sm" placeholder="inserisci un numero di telefono cellulare del Cliente..." name="clientphone" [(ngModel)]="record.clientphone">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                            <div class="col-12 col-lg-3">
                                <label>Stanza</label>
                            </div>
                            <div class="col-12 col-lg-9">
                                <div class="input-group">
                                    <input type="text"  class="form-control form-control-sm" placeholder="inserisci il numero di stanza e/o altre indicazioni (nome operatore, esigenze particolari del cliente, etc)..." name="clientnote" [(ngModel)]="record.clientnote">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
        
                <div class="form-group row" *ngIf="record.state!=6">
                    <ng-container *ngIf="currentUser.role!='guest'">
                        <div class="col-12 col-lg-3" >
                            <label>Metodo di pagamento</label>
                        </div>
                        <div class="col-12 col-lg-9">
                            <select class="form-control form-control-sm" name="methodpayment" placeholder="..." #methodpayment="ngModel" [(ngModel)]="record.methodpayment">
                                <option value="" *ngIf="currentUser.role!='agent'">In attesa di pagamento</option>
                                <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                                <option  value="stripe">Stripe</option>
                            </select>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="record.methodpayment=='stripe'">
                        <div class="col-12 mt-2">
                            <div class="card">
                                <div class="card-header py-1" style="color:white;background-color:#635bff">
                                    <h4 style="color:#80e9ff;" class="float-left mb-0">Pagamento <span class="d-none d-lg-inline">con carta online</span></h4><i class="fab fa-2x fa-stripe float-right"></i>
                                </div>
                                <div class="card-body bg-light">
                                    <app-stripe [class.d-none]="loading_stripe" [class]="'form-control'" [showPayButton]="false" [amount]="total_gross" (onsuccess)="confirmPayment($event)" (onerror)="errorCard($event)" (oninvalid)="invalidPayment($event)" (paymentMethodId)="createPaymentMethod($event)"></app-stripe>
                                    <div class="alert alert-info" *ngIf="loading_stripe">
                                        Procedura di pagamento in corso... Attendi per favore
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="form-group row" *ngIf="(currentUser.role!='guest' && currentUser.role!='external') && (record.methodpayment!='' || record.type_address==1)">
                    <div class="col-12">
                        <small>
                            <input type="checkbox" (click)="goToCheckin==true?goToCheckin=false:goToCheckin=true">
                                &nbsp;                           
                                <i *ngIf="record.state==6">Passa direttamente al <b>check-in</b> dopo la prenotazione</i>
                                <i *ngIf="record.state!=6">Passa direttamente al <b>check-in</b> dopo il pagamento</i>
                        </small>
                    </div>
                </div>
                <div class="form-group row" *ngIf="currentUser.role=='guest'">
                    <div class="col-12">
                        <small>
                            <input type="checkbox" (change)="privacy==true?privacy=false:privacy=true"><i>
                                &nbsp;Accetto le condizioni sulla privacy. <a class="pointer" (click)="openModalCondition()">Leggi</a></i>
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center mt-3">
                <button class="btn btn-secondary float-left" (click)="step=4"><i class="fas fa-chevron-left mr-2"></i>Indietro</button>
                <button *ngIf="record.state!=6 && record.methodpayment==''" [disabled]="clicked" class="btn btn-success float-right" (click)="confirm(); clicked=true">Fine<i class="fas fa-chevron-right ml-2"></i></button>
                <button *ngIf="record.state==6" [disabled]="clicked || !record.clientname || !record.clientemail || !record.clientphone" class="btn btn-success float-right" (click)="confirm(); clicked=true">Fine<i class="fas fa-chevron-right ml-2"></i></button>
                <button *ngIf="record.methodpayment=='stripe'" class="btn btn-success float-right" [disabled]="clicked || !privacy" (click)="typedocument=1;pay(); clicked=true;">Paga<i class="fas fa-chevron-right ml-2"></i></button>
                <button *ngIf="(record.methodpayment!='' && record.methodpayment!='stripe') && record.state!=6" class="btn btn-success float-right" [disabled]="clicked" (click)="typedocument=1;confirm(); clicked=true;">Paga con fattura<i class="fas fa-chevron-right ml-2"></i></button>
                <button *ngIf="currentUser.role!='agent' && (record.methodpayment!='' && record.methodpayment!='stripe') && record.state!=6" class="btn btn-secondary float-right mr-2"  (click)="openModalOtherMetodPayments()"><small>Altri metodi di pagamento</small></button>
            </div>
        </div>
    </ng-container>

     <!-- TOUR -->
    <ng-container *ngIf="step==99">
        <h5>Scegli il Tour</h5>
        <app-tours-list [mode]="'embedded'" (tourSelected)="addTour($event);step=2;"></app-tours-list>
    </ng-container>

</div>

<div *ngIf="currentUser.role=='guest' || currentUser.role=='external'" class="modal-body border-top bg-light">
    <div class="d-inline-block"><i class="fas fa-2x fa-question mr-2"></i> </div>
    <div class="d-inline-block">


        <b>Hai bisogno di aiuto per la prenotazione</b><br>
            Chiamaci al <a  class="text-primary" href="tel:+{{config.company.telephone}}" target="_blank">{{config.company.telephone}}</a> o scrivi un messaggio 
            <ng-container *ngIf="config.company.whatsapp">
            <a class="text-primary" href="https://api.whatsapp.com/send?text=Vorrei chiedere...&phone=+393405369150" target="_blank">WhatsApp</a> 
            </ng-container>
            oppure inviaci una mail a <a class="text-primary" href="mailto:{{config.company.email}}" target="_blank">{{config.company.email}}</a>.
    </div>
</div>


<ng-template #modalOtherMetodPayments let-modal>
    <div class="modal-header py-1 bg-light">
        <b>Altri metodi di pagamento:</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close('cancel')" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body text-center">
        <button class="btn btn-block btn-success m-1" (click)="typedocument=6;confirm(); clicked=true;">Pagamento con Scontrino</button>
    </div>
</ng-template>


<ng-template #modalNoPricelistSelected let-modal>
    <div class="modal-body text-center">
        <h3>Siamo spiacenti!</h3>
        <h5>Non abbiamo in Catalogo un offerta per te.</h5>
        <br>
            <p>
                Chiamaci per un <b>offerta personalizzata</b> al <a  class="text-primary" href="tel:{{config.company.telephone}}" target="_blank">{{config.company.telephone}}</a> o scrivi un messaggio 
                <ng-container *ngIf="config.company.whatsapp">
                    <a class="text-primary" href="https://api.whatsapp.com/send?text=Vorrei chiedere...&phone={{config.company.whatsapp}}" target="_blank">WhatsApp</a> 
                </ng-container>
                oppure inviaci una mail a <a class="text-primary" href="mailto:{{config.company.email}}" target="_blank">{{config.company.email}}</a>.
            </p>
            <button (click)="modal.close('cancel')" class="btn btn-secondary mt-4">Chiudi</button>
    </div>
</ng-template>


<ng-template #modalRentalDetails let-modal>
    <div class="modal-body text-justify">
        <h5>{{priceList.name}}</h5>
        <span class="text-xs" [innerHTML]="priceList.description"></span>
        <button (click)="modal.close('cancel')" class="btn text-center btn-secondary mt-4">Ok</button>
    </div>
</ng-template>


<ng-template #modalProductDetails let-modal>
    <div class="modal-body text-center">
        <img src="{{productSelected.coverImage().imagePath}}" class="img-thumbnail"/>
        <h3 *ngIf="productSelected.title!=''">{{productSelected.title}}</h3>
        <h3 *ngIf="productSelected.title==''">{{productSelected.brand}} {{productSelected.name}}</h3>
        <p [innerHTML]="productSelected.description"></p>
        <div class="text-center mt-4">
            <button (click)="modal.close('cancel')" class="btn btn-secondary">Ok</button>
        </div>
    </div>
</ng-template>


<ng-template #modalTourDetails let-modal>
    <div class="modal-body text-justify">
        <h5 class="text-center">{{tourSelected.title}}</h5>
        <small>
            <p [innerHTML]="tourSelected.description"></p>
            <hr>
            <small>
                <ng-container *ngFor="let d of record.details;let i=index">
                    <li  class="list-group-item px-0">
                        <span><b>{{d.quantity}}</b>&ensp;✕&ensp;{{d.product.name}}</span>
                    </li>
                </ng-container>
            </small>
        </small>
        <div class="text-center mt-4">
            <button (click)="modal.close('cancel')" class="btn btn-secondary ">Ok</button>
        </div>
    </div>
</ng-template>


<ng-template #modalInsertPassword>
    <div class="modal-header py-1 bg-light">
        <b>Utente registrato</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalPassword()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        L'indirizzo email <b>{{access_username}}</b> risulta già registrato.<br>
        Inserisci la password per poter procedere
        <input type="password" class="form-control form-control-sm" name="user_password" [(ngModel)]="password">
        <p>Hai dimenticato la password? <a class="pointer" (click)="resendPassword()">Inviala sulla mail</a></p>
        <!-- <button class="btn btn-block btn-info mt-5" (click)="resendPassword()">Rinvia password su {{access_username}}</button> -->
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModalPassword()">Annulla</button>
        <button class="btn btn-success" (click)="access()">Accedi</button>
    </div>
</ng-template>


<ng-template #modalUserExist>
    <div class="modal-header py-1 bg-light">
        <b>Utente registrato</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalUserExist()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <br>
        L'indirizzo email <b>{{access_username}}</b> risulta già registrato.<br>
        <button class="btn btn-block btn-success mt-4" (click)="verifyUsername();closeModalUserExist()">Utilizza i dati già registrati</button>
        <button class="btn btn-block btn-secondary mt-2" (click)="closeModalUserExist()">Chiudi</button>
    </div>
</ng-template>

<ng-template #modalThankyou  let-modal>
    <div class="modal-body text-center">
        <h2>Grazie per l'acquisto!</h2>
        <p>A breve riceverai all'indirizzo email <b>{{access_username}}</b> il riepilogo del tuo ordine.</p>
        <button (click)="modal.close('cancel')" class="btn btn-secodary mt-4">Ok</button>
    </div>
</ng-template>

<ng-template #modalConditions let-modal>
    <div class="modal-header bg-light">
      <h5 class="modal-title">Privacy Policy</h5>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="modal.close('cancel')" >
            <i class="fas fa-times"></i>
        </button>
    </div>
    </div> 
    <div class="modal-body">
		<iframe style="overflow-y: scroll;height:40vh;width:100%;border:none;" 
			src="/assets/privacy.html" ></iframe>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">Ok</button>
    </div>
</ng-template>

