import { Component,EventEmitter, Inject, LOCALE_ID, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'projects/core/src/globals';
import { User } from 'projects/core/src/include/structures';
import { Shops } from 'projects/c1-backend/src/app/db/shops';
import { PointComponent } from './modules/point/point.component';
import { LoginComponent } from 'projects/core/src/common/login/login.component';
import { SystemService } from 'projects/core/src/lib/system.service';
import { DOCUMENT } from '@angular/common';
import { AddressComponent } from './views/address/address.component';
import { AddressDetailsComponent } from './views/address/address-details/address-details.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  version="1.2";
  build="267";
  enableSelectPoint=false;
  enableC1Connector=false;
  enableChat=false;
  privacy=false;
  info=false;

  get currentIsLogin():boolean{
    return Globals.isLogin;
  }

  get currentUser():User{
    return Globals.user;
  }

  get loading():boolean{
    return Globals.loading;
  }

  get loadingstatusbar():boolean{
    return Globals.loadingstatusbar;
  }

  get loadingstatusbarmessage():string{
    return Globals.loadingstatusbarmessage;
  }

  get softwarebackground():string {
    return Globals.config.softwarebackground;
  }

  get softwarecolor():string {
    return Globals.config.softwarecolor;
  }

  get config():any{
    return Globals.config;
  }

  get changeLog():any{
    return Globals.config.changeLog;
  }

  get company():any{
    return Globals.config.company;
  }

  get history():any{
    return Globals.navigation.history;
  }


  get c1connector_status():any{
    return Globals.c1connector.connected;
  }
  get c1connector_c1connectorid():any{
    return Globals.c1connector.c1connectorid;
  }

  get copyright(){
    return Globals.config.copyright;
  }

  get readonly(){
    return Globals.readonly;
  }


  get total_occupied_space(){
    return Globals.total_occupied_space;
  }

  get total_space(){
    return Globals.total_space;
  }
  
  @ViewChild("modalRegistrationInfo")
  modalRegistrationInfo:TemplateRef<any>;
  modalRegistrationInfoRef;

  @ViewChild("modalChangeLog")
  modalChangeLog:TemplateRef<any>;
  modalChangeLogRef;

  @ViewChild("modalUserInfo")
  modalUserInfo:TemplateRef<any>;
  modalUserInfoRef;

  menu=[]; //menu nella sidebar
  menu_settings=[]; //menu nella sidebar
  current_module;

  constructor(
    private globals:Globals,
    private modalService:NgbModal,
    private systemService:SystemService,
     @Inject(LOCALE_ID) public locale: string,
     @Inject(DOCUMENT) private document: Document
    ){
    Globals.config.loadConfig(()=>{
      Globals.navigation.skipLocationChange=true;
      //Globals.access.loginPage="/login";
      
      //crea alcuni eventi globali
      Globals.events['savingDeadlineEvent']=new EventEmitter();
      Globals.events['savingActivityEvent']=new EventEmitter();
      
      
      let params=Globals.navigation.getQueryString();
      
      
      let username:string=Globals.navigation.getParam(params,"user",localStorage.getItem(Globals.access.prefix+"_username"));
      let password:string=Globals.navigation.getParam(params,"pwd",localStorage.getItem(Globals.access.prefix+"_password"));

      Globals.access.loginEvent.subscribe(()=>{
       
        this.current_module=Globals.parameters.getDefaultModule(true);
        this.enableC1Connector=Globals.parameters.get("general").getParam("enableC1Connector",false);
        this.enableSelectPoint=Globals.parameters.get("general").getParam("selectPoint",false);
        this.enableChat=Globals.parameters.get("general").getParam("enableChat",false);
        if(this.enableSelectPoint)
          this.openSelectPoint();
        else{

          //se l'utente ha un parametro di id_shop assegnalo
          if(Globals.user.getParam("id_shop",0)>0){
            let shop=new Shops();
            shop.id=Globals.user.getParam("id_shop",0);
            Globals.user['shop']=shop;
          }


          if(this.enableC1Connector)
            Globals.c1connector.connectMqtt();
        }
   
        Globals.navigation.onNavigate([this.current_module.url]);
        
      });
      
      Globals.access.logoutEvent.subscribe((result)=>{
        this.openLogin();
      })

      Globals.parameters.loadModulesEvent.subscribe((result)=>{
        this.createMenu();

        Globals.parameters.createParam(
          "general",
          "default_tax",
          "IVA di default",
          "number",
          null,
          22
        );

        Globals.parameters.createParam(
          "general",
          "default_address",
          "ID Anagrafica di default",
          "number"
        );

        Globals.parameters.createParam(
          "general",
          "selectPoint",
          "Seleziona punto",
          "boolean",
          null,
          false
        );
        
        Globals.parameters.createParam(
          "general",
          "enableC1Connector",
          "Abilita C1 Connector",
          "boolean",
          null,
          false
        );
        
        Globals.parameters.createParam(
            "general",
            "sidebar_view",
            "Modalità visualizzazione sidebar",
            "select",
            [
                {"value":"","label":"Sidebar aperta"},
                {"value":"collapse","label":"Sidebar chiusa"},
            ],
            ""
        );

        Globals.parameters.createParam(
          "registration",
          "id_type_address",
          "ID Tipologia anagrafica",
          "type"
        );

        Globals.parameters.createParam(
          "registration",
          "id_type_email",
          "ID Tipologia email",
          "type"
        );

        Globals.parameters.createParam(
          "registration",
          "id_type_phone",
          "ID Tipologia telefono",
          "type"
        );

        Globals.parameters.createParam(
          "registration",
          "id_notification",
          "ID notifica per invio conferma registrazione",
          "notification"
        );

        Globals.parameters.createParam(
          "notification",
          "sms_c1connector",
          "Abilita le notifiche con C1 Connector",
          "boolean"
        );

        if(Globals.parameters.get("general").getParam("sidebar_view")=="collapse")
          this.document.body.classList.add('sidebar-collapse');      

      })

      //auto login in fase di startup
      if((username!="" && username!=null && username!=undefined) && (password!="" && password!=null && password!=undefined)){
        Globals.access.login(username,password,(success)=>{
          if(!success){
            this.openLogin();
          }
        },false);
      }else{
        this.openLogin();
      }
      

      //Globals.access.onLogin(username,password);
    });
    Globals.config.loadChangeLog(()=>{
      console.log("✅ ChangeLog loaded!");
    });

    console.log("is ____ ___  ____ ___ _   _  ____ \n  / ___/ _ \\|  _ \\_ _| \\ | |/ ___|\n | |  | | | | | | | ||  \\| | |  _ \n | |__| |_| | |_| | || |\\  | |_| |\n  \\____\\___/|____/___|_| \\_|\\____| software\n ");
		
  }

  openLogin(){
    Globals.modal.showModal(LoginComponent,[
      {"name":"mode","value":"modal"},      
    ],(instance)=>{
      if(instance){
        if (this.readonly)
          this.openModalRegistrationInfo();
      }
    },"sm");
  }


  createMenu(){
    this.menu=[];
    this.menu_settings=[];
    
    for(let m of Globals.parameters.modules){
      if(m.permissions.indexOf(this.currentUser.role)>-1 && m.status==1){
        if(m.label!=""){
          if(m.type==1 || m.type==4)
            this.menu.push(m);
          if(m.type==2)
            this.menu_settings.push(m);
        }
      }        
    }
  }


  logout(){
    Globals.access.logout();
  }


  goToView(item){
    this.current_module=item;
    Globals.navigation.deleteLastHistory();
    /*
    if(this.isDetail){
      if(!this.currentModel.verifyUnsavedRecord())
        this.onNavigate([item.url]);
    }*/

    Globals.navigation.onNavigate([item.url]);
 }


  openSelectPoint(){
    if(Globals.isLogin){
      Globals.modal.showModal(PointComponent,[],(instance)=>{
        if(instance!=null){
          let shop=new Shops();
          Object.assign(shop,instance['shop']);
          Globals.user['shop']=shop;
          if(this.enableC1Connector)
            Globals.c1connector.connectMqtt();
        }
      },'md');
    }
  }


  openModalRegistrationInfo(){
    this.modalRegistrationInfoRef=this.modalService.open(this.modalRegistrationInfo);
  }


  openModalChangeLog(){
    this.modalChangeLogRef=this.modalService.open(this.modalChangeLog);
  }


  openModalUserInfo(r){
    this.modalUserInfoRef=this.modalService.open(this.modalUserInfo);
  }

}
