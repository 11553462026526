<div class="modal-body" *ngIf="type==1">
    <h5>Inserisci i numeri seriali dei prodotti da caricare (<b class="text-primary pointer" (click)="type=2">è uno Scarico?</b>)</h5>
    <br>
    <i class="fas fa-info-circle mr-2"></i>Inserisci i numeri intervallati dal segno <b>;</b> o da <b>ritorno a capo</b>
    <div class="form-group">
        <textarea #serialsToLoadBox  autofocus class="form-control" rows="10" cols="20" [(ngModel)]="serialsToLoad"></textarea>
        <i class="mt-1">Numeri seriali inseriti: <strong>{{countLoaded}}</strong></i>
        <!--<div class="alert alert-danger" *ngIf="countLoaded>quantity">Inseriti numeri seriali in eccesso !</div>-->
    </div>
    <div class="row">
        <div class="col-lg-3 text-lg-right"><label>Pattern per autogenerazione SN</label></div>
        <div class="col-lg-6">
            <div class="input-group input-group-sm">
                <input type="text" class="form-control form-control-sm" name="snpattern" placeholder="per esempio: AA{sn}" [(ngModel)]="snpattern" />
                <div class="input-group-append">
                    <input type="number" class="form-control form-control-sm" name="quantity" [(ngModel)]="quantity">
                </div>
                <div class="input-group-append">
                    <button class="btn text-success" *ngIf="type==1" [disabled]="snpattern=='' || snpattern==undefined" (click)="generate()"><i class="fas fa-plus-circle mr-2"></i>Genera</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
            <button class="btn btn-primary btn-block" (click)="scan()"><i class="fas fa-qrcode mr-2"></i>Scansiona QRCode/Barcode</button>
        </div>
    </div>   
</div>


<div class="modal-body" *ngIf="type==2">
    <h5>Seleziona i numeri seriali dei prodotti da scaricare (<b class="text-primary pointer" (click)="type=1">è un Carico?</b>)</h5>
    <input type="checkbox" class="mr-1" (click)="filterSerials(true)"><label>Mostra tutti</label>
    <div class="text-center" *ngIf="loading">
        <i class="fas fa-search blink mr-2"></i><i>Ricerca in corso...</i>
    </div>
    <table class="table table-head-fixed table-sm" *ngIf="!loading">
        <thead class="bg-light">
            <th></th>
            <th>Seriale</th>
            <th>Disponibilità</th>
            <th>Magazzino / Ubicazione</th>
        </thead>
        <tbody>
            <ng-container *ngFor="let s of serialsList">
            <tr *ngIf="s.avaible>0">
                <td><input type="checkbox" [(ngModel)]="s.selected" /></td>
                <td>{{s.sn}}</td>
                <td>{{s.avaible}}</td>
                <td>{{s.inventoryname}}</td>
            </tr>
            </ng-container>
        </tbody>
    </table>
    <i class="mt-1">Numeri seriali selezionati: <strong>{{countSelected}}</strong> su <strong>{{quantity}}</strong></i>
    <div class="alert alert-danger" *ngIf="countSelected>quantity">Selezionati numeri seriali in eccesso !</div>
</div>


<div class="modal-footer">
    <button *ngIf="serialsList.length==0" class="btn btn-success" (click)="confirm(true)">Continua</button>
    <button *ngIf="serialsList.length>0" class="btn btn-success" (click)="confirm()">Ok (F8)</button>
    <button class="btn btn-secondary" (click)="close()">Annulla</button>
</div>