import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { User } from '../include/structures'
import { WSService } from '../include/service'
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})

export class UserService  extends WSService<User> {

  constructor() { super("users") }
  static users:User[]=[];

  login(username:string,password:string):Observable<User>{
    return Globals.ws.requestUrl<User>("client","login",[username,password]);
  }

  getUsers():Observable<User[]>{

    if(UserService.users.length==0){
      Globals.ws.requestUrl<User[]>("users","getItems",["NOT role='guest'","u.name ASC"],true,0,1000000).subscribe((items:any)=>{
        UserService.users=items;
      });
      return Globals.ws.requestUrl<User[]>("users","getItems",["NOT role='guest'","u.name ASC"],true,0,1000000);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {
        observer.next(UserService.users);
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }
    return  Globals.ws.requestUrl<User[]>("users","getItems",["NOT role='guest'","u.name ASC"],true,0,1000000);
    
  }

  getUser(username,password):Observable<User>{

    

    return  Globals.ws.requestUrl<User>("users","getUser",[username,password]);
    
  }

  getUserById(id):Observable<User>{
    //controlla se presente già nell'elenco degli utenti
    if(UserService.users.length>0){
      for(let u of UserService.users){
        if(u.id==id){
          function sequenceSubscriber(observer: Observer<User>) {
            observer.next(u);
            observer.complete();
            return {unsubscribe() {}};
          }
          
          return new Observable(sequenceSubscriber);
        }
      }


      
    }


    return Globals.ws.requestUrl<User>("users","getItem",[id,false]);
    
    
  }

  getUserByContact(contact,password):Observable<User>{
    return  Globals.ws.requestUrl<User>("users","getUserByContact",[contact,password]);
    
  }

  getUserByAddress(id_address:number):Observable<User[]>{
    return  Globals.ws.requestUrl<User[]>("users","getItems",["id_address="+id_address.toString()]);
    
  }

  checkPassword(id_user:number,password:string):Observable<User[]>{
    return  Globals.ws.requestUrl<User[]>("users","getItems",["u.id="+id_user.toString()+" AND u.password='"+password+"'"]);
  }


  resendPassword(username:string):Observable<boolean>{
    return  Globals.ws.requestUrl<boolean>("users","resendPassword",[username]);
    
  }


  requestResetPassword(username:string):Observable<any>{
    return  Globals.ws.requestUrl<boolean>("users","requestResetPassword",[username],false);
  }

  resetPassword(username:string,newpassword:string,pin:string):Observable<any>{
    return  Globals.ws.requestUrl<boolean>("users","resetpassword",[username,newpassword,pin],false);
  }

}
