<app-windowlist [model]="this">
    
    <div class="row">
        <div class="col-lg-2 pr-lg-0">
            <app-filtercontainer [title]="'Filtra per date o tipologie'">
                <app-filterdate [date_type]="default_filterdate" [future]="true" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="updateList($event)"></app-filterdate>
                <ul class="card small-box list-group p-1 mt-2" *ngIf="type_activities.length>0">
                    <li class="list-group-item pointer" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';updateList()">Tutte le tipologie</li>
                    <li class="list-group-item pointer" [class.active]="filter_id_type.value==t.id" *ngFor="let t of type_activities" (click)="filter_id_type.value=t.id;updateList()">{{t.name}}</li>
                    <div *ngIf="type_activities.length>2" class="icon"><i class="fas fa-stream"></i></div>
                </ul>
            </app-filtercontainer>
        </div>
        <div class="col-lg-10">
            <div class="card elevation-2 mb-0">
                <div class="p-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="input-group input-group-sm p-1" >
                                <input type="text" name="table_search" class="form-control float-right" placeholder="cliente o descrizione..." [(ngModel)]="filter_search.value" (keyup.enter)="updateList()">
                                <div class="input-group-append">
                                    <input type="text"  class="form-control form-control-sm float-right" placeholder="comune..." [(ngModel)]="filter_search_city.value" (keyup.enter)="updateList()">
                                </div>
                                <div class="input-group-append">                                    
                                    <button type="submit" class="btn btn-default text-primary"  title="Avvia ricerca" (click)="updateList()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="p-1">
                                <select class="form-control form-control-sm" [(ngModel)]="filter_id_user.value" (ngModelChange)="updateList()" [class.alert-info]="filter_id_user.value">
                                    <option value="">Tutti gli operatori</option>
                                    <ng-container *ngFor="let u of users">
                                        <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 col-lg-2">
                            <select class="form-control form-control-sm m-1" [(ngModel)]="filter_phase.value" (ngModelChange)="updateList()" 
                                [class.alert-warning]="filter_phase.value=='1'"
                                [class.alert-success]="filter_phase.value=='2'"
                                [class.alert-success]="filter_phase.value=='3'" >
                                <option value="">Tutte le fasi</option>
                                <option value="1">In corso</option>
                                <option value="2">Completate</option>
                                <option value="3">Confermate</option>
                            </select>
                        </div>                        
                        <div class="col-6 col-lg-2" *ngIf="mode!='modal'">
                            <div class="pr-1">
                                <app-windowlistbuttons 
                                [model]="this" 
                                [table]="'activities'" 
                                [openDetailExtra]="[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference}]" 
                                [funs]="[]"
                                ></app-windowlistbuttons>
                            </div>
                            <!-- <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mx-1 mt-1"><i class="far fa-trash-alt"></i></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mx-1 mt-1"><i class="fas fa-trash"></i></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                            <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                            <button (click)="openDetail(null,[{'name':'id_address','value':id_address},{'name':'id_table','value':id_table},{'name':'table','value':table},{'name':'reference','value':reference}])" class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i></button> -->
                        </div>
                    </div>                    
                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
    
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap table-sm table-striped m-0 text-sm" *ngIf="list.length>0">
                            <thead class="bg-light">
                                <th>                                   
                                    <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                        <input type="checkbox" title="Seleziona tutti"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                </th>
                                <th>Data pagamento</th>
                                <th>Attività</th>
                                <th class="d-none d-lg-table-cell">Anagrafica</th>
                                <th class="d-none d-lg-table-cell">Note</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let activities of list">
                                    <td>
                                        <div class="text-indigo blink help mb-2">
                                            <i *ngIf="activities.priority==2" title="Priorità media" class="fa-2x fas fa-thermometer-half-quarters"></i>
                                            <i *ngIf="activities.priority==3" title="Priorità elevata" class="fa-2x fas fa-thermometer-three-quarters"></i>
                                        </div>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{activities.id}}" title="{{activities.id}}"
                                            (change)="onChange(activities.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <i class="far fa-calendar-alt text-gray mr-1"></i>
                                        <a (click)="openDetail(activities)" title="Dettaglio attività" class="pointer">
                                            {{activities.reschedule_payment | date:'dd/MM/yyyy'}}
                                        </a>
                                    </td>
                                    <td style="max-width:12rem">
                                        <i class="far fa-calendar-alt text-gray mr-1"></i>{{activities.date | date:'dd/MM/yyyy'}}<i class="far fa-clock text-gray ml-2  mr-1"></i>{{activities.time}}<br/>
                                        {{activities.type}}&nbsp;
                                        <ng-container *ngIf="activities.amount>0 || activities.paid>0">
                                            <span *ngIf="activities.id_cashflow==0" class="text-danger ml-1 help" title="Nessun pagamento associato"><i class="fas fa-coins"></i></span>
                                            <span *ngIf="activities.id_cashflow>0" class="text-success ml-1 help" title="Pagamento associato"><i class="fas fa-coins"></i></span>
                                            <br/>
                                        </ng-container>                                                           
                                        <!-- <i *ngIf="activities.priority==2" title="Priorità 2" class="text-warning fas fa-exclamation help"></i> 
                                        <i *ngIf="activities.priority==3" title="Priorità 3" class="text-danger fas fa-exclamation help"></i>&nbsp; -->
                                        <small><i class="fas fa-info-circle text-gray mr-1"></i><i>{{activities.description}}</i></small>
                                        <ng-container *ngIf="activities.note">                                
                                            <br><small><i class="fas fa-thumbtack text-info mr-1"></i> {{activities.note}}</small><br>
                                        </ng-container>
                                        <!-- <hr/>
                                        <b>{{activities.name}}</b><br/>
                                        {{activities.address}}<br/>
                                        {{activities.city}} -->
                                        <!-- <app-reference *ngIf="mode!='embedded'" [reference]="activities.reference" [table]="activities.table"></app-reference> -->                                        
                                    </td>
                                    <td class="d-none d-lg-table-cell" style="max-width:12rem">
                                        <i class="fa fa-user text-gray mr-1"></i><b>{{activities.name}}</b><br/>
                                        {{activities.address}}<br/>
                                        {{activities.city}} {{activities.country}}
                                    </td>
                                    <td  class="d-none d-lg-table-cell" style="max-width:12rem"><i>{{activities.note_reschedule_payment}}</i></td>
                                    <td>
                                        <button type="button" (click)="openDetail(activities)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1 mt-1">
                                            <i class="fas fa-pencil-alt"></i></button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="notify(activities);" title="Notifica" class="btn btn-primary btn-xs float-right ml-1 mt-1">
                                            <i class="far fa-paper-plane"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>