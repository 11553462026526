import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { WSService } from '../include/service';

@Injectable({
  providedIn: 'root'
})
export class FormsService extends WSService<any> {

  constructor() { super("forms") };

  getForms(table):Observable<any[]>{
    return Globals.ws.requestUrl("forms","getItems",["f.table='"+table+"' and f.status=1"]);
  }

  /*getFormValue(id,reference,oncomplete){
    return Globals.ws.send("forms","getFormValue",reference,[id],oncomplete);
 
  }*/

  getForm(id,reference,oncomplete,id_formvalue=0){

    
    
    return Globals.ws.send("forms","getForm",reference,[id,id_formvalue,Globals.ws.api_version=="v2"?reference:null],oncomplete);
 
  }

}
