
<div class="card" *ngFor="let p of plugins">
    <div class="card-body">
        <div class="row">
            <div class="col-md-3">
                <b>{{p.name}}</b><br/>
                <small>{{p.version}}</small><br/>
                <pre>{{p.description}}</pre>
            </div>
            
            <div class="col-md-9">
                <table class="table table-sm">
                    <tr *ngFor="let setting of p.settings">
                        <td>
                            <ng-container *ngIf="setting.type!='function'">
                            {{setting.label}}
                        </ng-container>
                        
                        </td>
                        <td>
                            <ng-container [ngSwitch]="setting.type">
                                <ng-container *ngSwitchCase="'password'">
                                    <input type="password" class="form-control form-control-sm" [(ngModel)]="setting.value">
                                </ng-container>
                                <ng-container *ngSwitchCase="'number'">
                                    <input type="number" class="form-control form-control-sm" [(ngModel)]="setting.value">
                                </ng-container>
                                <ng-container *ngSwitchCase="'boolean'">
                                    <select class="form-control form-control-sm" [(ngModel)]="setting.value">
                                        <option  [ngValue]="true">Si</option>
                                        <option  [ngValue]="false">No</option>
                                    </select>
                                </ng-container>

                                <ng-container *ngSwitchCase="'select'">
                                    <select class="form-control form-control-sm" [(ngModel)]="setting.value">
                                        <option *ngFor="let option of setting.options" [ngValue]="option">{{option}}</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngSwitchCase="'function'">
                                    <button class="btn btn-sm btn-secondary" (click)="runFunction(p.name,setting.name)">{{setting.label}}</button>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <input type="text" class="form-control form-control-sm" [(ngModel)]="setting.value">
                                </ng-container>

                            </ng-container>
                        </td>
                        <td>
                            <small>
                            {{setting.description}}
                        </small>
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    </div>
</div>
