import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { WSService } from '../include/service';

@Injectable({
  providedIn: 'root'
})
export class SystemService extends WSService<any> {

  constructor() { super("system") };

  exportSchemaDb():Observable<any>{
    return Globals.ws.requestUrl<any>("system","exportSchemaDb",[]);
  }

  compareSchemaDb():Observable<any>{
    return Globals.ws.requestUrl<any>("system","compareSchemaDb",[]);
  }

  createArchive():Observable<any>{
    return Globals.ws.requestUrl<any>("system","createArchive",[]);
  }


  createServerFile():Observable<any>{
    return Globals.ws.requestUrl<any>("system","createServerFile",["true"]);
  }

  createClientFile():Observable<any>{
    return Globals.ws.requestUrl<any>("system","createClientFile",["true"]);
  }

  createDbUpdateFile():Observable<any>{
    return Globals.ws.requestUrl<any>("system","createDbUpdateFile",["true"]);
  }


  

  upgradeWithArchive(filename):Observable<any>{
    return Globals.ws.requestUrl<any>("system","upgradeWithArchive",[filename]);
  }

  sendEmail(from,to,cc,bcc,subject,message):Observable<boolean>{
    return Globals.ws.requestUrl<any>("system","sendEmail",[from,to,cc,bcc,subject,message]);
  }

  getStructureTable(table):Observable<any>{
    return Globals.ws.requestUrl<any>("system","getStructureTable",[table]);
  }

  getMethodsClass(table,onlypublic="true"):Observable<any>{
    return Globals.ws.requestUrl<any>("system","getMethodsClass",[table,onlypublic]);
  }

  updateManifest(modulename):Observable<any>{
    return Globals.ws.requestUrl<any>("system","updateManifestModule",[modulename]);
  }

  updateDBModule(modulename):Observable<any>{
    return Globals.ws.requestUrl<any>("system","updateDBModule",[modulename]);
  }

  getServerPluginsSettings(type):Observable<any>{
    return Globals.ws.requestUrl<any>("system","getPlugins",[type]);
  }

  saveServerPlugins(type,pluginname,settings):Observable<any>{
    return Globals.ws.requestUrl<any>("system","saveSettingsPlugins",[type,pluginname,settings]);
  }

  runServerFuncitonPlugins(type,pluginname,method):Observable<any>{
    return Globals.ws.requestUrl<any>("system","runFunctionPlugin",[type,pluginname,method]);
  }

  
}
