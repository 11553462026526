<app-windowlist [showCard]="showCard" [model]="this">
    
    <div class="row py-1">
        <div class="col-lg-2 pr-lg-0" *ngIf="filterbox">
            <app-filtercontainer [title]="'Filtra per tipologia o date'" >
                <div class="pb-1 d-block d-lg-none"> <!-- for mobile -->
                    <span (click)="setFilterTypeValue('filter_typedocument',0)" [class.bg-primary]="checkFilterTypeValue('filter_typedocument',0)"
                    class="badge border text-sm m-1" >Tutte</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',1)" [class.bg-olive]="checkFilterTypeValue('filter_typedocument',1)"
                        class="badge border text-sm m-1" >FS</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',2)" [class.bg-lime]="checkFilterTypeValue('filter_typedocument',2)"
                        class="badge border text-sm m-1" >FV</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',12)" [class.bg-teal]="checkFilterTypeValue('filter_typedocument',12)"
                        class="badge border text-sm m-1" >FA</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',3)" [class.bg-maroon]="checkFilterTypeValue('filter_typedocument',3)"
                        class="badge border text-sm m-1">NC</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',4)" [class.bg-warning]="checkFilterTypeValue('filter_typedocument',4)"
                        class="badge border text-sm m-1">PR</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',5)" [class.bg-lightblue]="checkFilterTypeValue('filter_typedocument',5)"
                        class="badge border text-sm m-1">DDT</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',6)" [class.bg-gray]="checkFilterTypeValue('filter_typedocument',6)"
                        class="badge border text-sm m-1" *ngIf="type==1">SC</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',7)" [class.bg-secondary]="checkFilterTypeValue('filter_typedocument',7)"
                        class="badge border text-sm m-1" *ngIf="type==1">OC</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',8)" [class.bg-secondary]="checkFilterTypeValue('filter_typedocument',8)"
                        class="badge border text-sm m-1" *ngIf="type==2">OF</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',9)" [class.bg-secondary]="checkFilterTypeValue('filter_typedocument',9)"
                        class="badge border text-sm m-1">NF</span>
                    <span (click)="setFilterTypeValue('filter_typedocument',11)" [class.bg-secondary]="checkFilterTypeValue('filter_typedocument',11)"
                        class="badge border text-sm m-1">DA</span>
                </div>               
                <ul class="card small-box list-group p-1 d-none d-lg-block">
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',0)" 
                        (click)="setFilterTypeValue('filter_typedocument',0)">
                        Tutte le tipologie {{filter_typedocument.length}}</li>
                    <li class="list-group-item pointer" *ngIf="checkFilterTypeValue('filter_typedocument',0)" 
                        (click)="setFilterTypeValueFromList([1,2,3,12])">
                        Tutte le fatture e note 
                        <span *ngIf="type==1">emesse</span>
                        <span *ngIf="type==2">ricevute</span>
                    </li>
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',1)" (click)="setFilterTypeValue('filter_typedocument',1)"                            >
                        <span *ngIf="checkFilterTypeValue('filter_typedocument',0)" class="mx-2">&#10551;</span>
                        Fattura<span class="badge d-none d-xl-inline bg-olive float-right">FS</span></li>
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',2)" (click)="setFilterTypeValue('filter_typedocument',2)"                            >
                        <span *ngIf="checkFilterTypeValue('filter_typedocument',0)" class="mx-2">&#10551;</span>
                        Fattura accompagnatoria<span class="badge d-none d-xl-inline bg-teal float-right">FV</span></li>
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',12)" (click)="setFilterTypeValue('filter_typedocument',12)"                          >
                        <span *ngIf="checkFilterTypeValue('filter_typedocument',0)" class="mx-2">&#10551;</span>
                        Fattura di acconto<span class="badge d-none d-xl-inline bg-lime float-right">FA</span></li>                    
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',3)" (click)="setFilterTypeValue('filter_typedocument',3)"                            >
                        <span *ngIf="checkFilterTypeValue('filter_typedocument',0)" class="mx-2">&#10551;</span>
                        Nota di <span *ngIf="type==1">credito</span><span *ngIf="type==2">debito</span><span class="badge d-none d-xl-inline bg-maroon float-right">NC</span></li>
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',4)" (click)="setFilterTypeValue('filter_typedocument',4)"                            >
                        Preventivo<span class="badge d-none d-xl-inline bg-warning float-right">PR</span></li>
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',5)" (click)="setFilterTypeValue('filter_typedocument',5)"                            >
                        Documento Di Trasporto<span class="badge d-none d-xl-inline bg-lightblue float-right">DDT</span></li>
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',6)" (click)="setFilterTypeValue('filter_typedocument',6)" *ngIf="type==1"            >
                        Scontrino<span class="badge d-none d-xl-inline bg-gray float-right">SC</span></li>
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',7)" (click)="setFilterTypeValue('filter_typedocument',7)" *ngIf="type==1"            >
                        Ordine cliente<span class="badge d-none d-xl-inline bg-secondary float-right">OC</span></li>
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',8)" (click)="setFilterTypeValue('filter_typedocument',8)" *ngIf="type==2"            >
                        Ordine fornitore<span class="badge d-none d-xl-inline bg-secondary float-right">OF</span></li>
                    <li class="list-group-item pointer pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',9)" (click)="setFilterTypeValue('filter_typedocument',9)"                            >
                        Non fiscale<span class="badge d-none d-xl-inline bg-secondary float-right">NF</span></li>
                    <li class="list-group-item pointe pr-0" [class.active]="checkFilterTypeValue('filter_typedocument',11)" (click)="setFilterTypeValue('filter_typedocument',11)"                          >
                        Amministrativo<span class="badge d-none d-xl-inline bg-secondary float-right">DA</span></li>
                    <div class="icon"><i class="fa fa-file-invoice-dollar "></i></div>
                </ul>

                <app-filterdate *ngIf="init" [date_type]="this.filterdate_param" [class]="'card p-1 mt-lg-2'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>

              
                <ul *ngIf="checkPermissions('showPaymentSchedule')" class="small-box list-group card p-1 mt-2">
                    <li class="list-group-item border py-1" [class.active]="filter_paymentschedule.value!=''||filter_paymentschedule.value2!=''">
                        <span *ngIf="filter_paymentschedule.value!=''||filter_paymentschedule.value2!=''" title="Azzera" class="btn text-danger float-right px-2 py-0" 
                                (click)="filter_paymentschedule.value='';filter_paymentschedule.value2='';updateList()"><i class="fa fa-times"></i></span>
                        <b>Scadenza<span class="d-none d-xl-inline"> documento</span></b>
                        <div class="input-group input-group-sm border-bottom">
                            <div class="input-group-prepend input-group-prepend-sm d-none d-xl-flex text-center">
                                <span style="width: 1.5rem;" ><small >Dal</small></span>
                            </div>
                            <input style="height: 1rem;" class="form-control form-control-sm border-0 py-0" title="Selezionare la data di inizio ricerca" type="date" [(ngModel)]="filter_paymentschedule.value" (ngModelChange)="updateList()">
                        </div>
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend input-group-prepend-sm d-none d-xl-flex text-center">
                                <span style="width: 1.5rem;"><small>Al</small></span>
                            </div>
                            <input style="height: 1rem;" class="form-control form-control-sm border-0 py-0" title="Selezionare la data di fine ricerca" type="date" [(ngModel)]="filter_paymentschedule.value2" (ngModelChange)="updateList()">
                        </div>                        
                    </li>
                    
                </ul>                
            </app-filtercontainer>
        </div>
        <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
            <div class="card">
                <div class="p-0">
                    <ng-container *ngIf="filterbox">
                        <div class="row" >
                            <div class="col-6 col-lg-8">
                                <div class="input-group input-group-info input-group-sm p-1">
                                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" title="nome cliente o descrizione" placeholder="cerca per nome cliente o descrizione..." [(ngModel)]="filter_search.value" (keyup.enter)="onSearchEnter()">
                                    <input type="text" class="form-control form-control-sm" title="numero documento" placeholder="n. documento" style="max-width:150px;" [(ngModel)]="filter_reference.value" (keyup.enter)="onSearchEnter()">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-default text-primary"  title="avvia ricerca" (click)="onSearchEnter()"><i class="fas fa-search"></i></button>
                                        <button class="btn btn-default text-danger" *ngIf="filter_search.value!='' || filter_reference.value!=''"  title="annulla ricerca" (click)="filter_search.value='';filter_reference.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-4">
                                <ng-container *ngIf="type==1">
                                    <app-windowlistbuttons 
                                    [model]="this" 
                                    [table]="'documents'" 
                                    [funs]="[
                                        {'name':'Fatturazione elettronica','value':''},
                                        {'name':'Invio guidato a SdI','value':'sendMultipleEInvoice'},
                                        {'name':'Verifica stato SdI documenti inviati','value':'checkAllInvoices'},
                                        {'name':'Carica documento (XML)','value':'uploadXML1'},
                                        {'name':'Scarica tutti i file da SdI','value':'resaveDownloadedFileAllDocuments','role':'SuperUser'},
                                        {'name':'Altro','value':''},
                                        {'name':'Riordina la numerazione dei documenti','value':'openReorderNumeration'},
                                        {'name':'Unisci documenti','value':'mergeDocuments'},
                                        {'name':'Assegna conto contabile','value':'assignFinancialAccount','module':'financialaccounts'},
                                        {'name':'Scarica file collegati ai documenti','value':'DownloadFiles'},
                                    ]"
                                    ></app-windowlistbuttons>
                                </ng-container>
                                <ng-container *ngIf="type==2">
                                    <app-windowlistbuttons 
                                    [model]="this" 
                                    [table]="'documents'" 
                                    [openDetailExtra]="{'name':'id_type','value':type}" 
                                    [funs]="[
                                        {'name':'Fatturazione elettronica','value':''},
                                        {'name':'Controlla documenti ricevuti su SdI','value':'checkDocumentSdi'},
                                        {'name':'Carica documento (XML)','value':'uploadXML2'},
                                        {'name':'Scarica tutti i file da SdI','value':'resaveDownloadedFileAllDocuments','role':'SuperUser'},
                                        {'name':'Altro','value':''},
                                        {'name':'Unisci documenti','value':'mergeDocuments'},
                                        {'name':'Assegna conto contabile','value':'assignFinancialAccount','module':'financialaccounts'},
                                        {'name':'Scarica file collegati ai documenti','value':'DownloadFiles'},
                                    ]"
                                    ></app-windowlistbuttons>
                                </ng-container>
                            </div>
                               
                        </div>
                        <div class="small-box bg-light m-0 m-lg-1 mt-lg-0 p-1">
                            <app-filtercontainer [title]="'Altri Filtri'">
                                <div class="row">
                                    <div class="col-6 col-lg-2 text-lg-right p-lg-2">Stato documento</div>
                                    <div class="col-6 col-lg-2">
                                        <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_state.value" (change)="updateList()" 
                                        [class.alert-warning]="this.filter_state.value==1 || this.filter_state.value==6" 
                                        [class.alert-success]="this.filter_state.value==2" 
                                        [class.alert-danger]="this.filter_state.value==3" 
                                        [class.alert-info]="this.filter_state.value==4 || this.filter_state.value==5" >
                                            <option value="0">Tutti</option>
                                            <option value="1">Da confermare</option>
                                            <option value="2">Confermato</option>
                                            <option value="3">Annullato</option>
                                            <option value="4">Ordinato</option>
                                            <option value="5">Spedito</option>
                                            <option value="6">Parziale</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-lg-2 text-lg-right p-lg-2">Metodo di pagamento</div>
                                    <div class="col-6 col-lg-2">
                                        <select class="form-control form-control-sm p-1" [(ngModel)]="filter_method_payment.value" (ngModelChange)="updateList()" [class.alert-info]="filter_method_payment.value">
                                            <option value="">Tutti</option>
                                            <option *ngFor="let m of methodpayments" value="{{m.name}}">{{m.name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-lg-2 text-lg-right p-lg-2">
                                        <span *ngIf="type==1">Destinazione</span>
                                        <span *ngIf="type==2">Provenienza</span>
                                    </div>
                                    <div class="col-6 col-lg-2">
                                        <div class="btn-group btn-group-sm w-100">
                                            <button [class.bg-primary]="this.filter_nation.mode==0" type="button" class="btn w-50 btn-default border btn-sm text-xs" (click)="this.filter_nation.mode=0; updateList()">Tutti</button>
                                            <button [class.bg-info]="this.filter_nation.mode==14" type="button" class="btn w-50 btn-default border btn-sm text-xs" (click)="this.filter_nation.mode=14; updateList()">da/per Estero</button>
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="row">
                                    <div class="col-6 col-lg-2 text-lg-right p-lg-2">Sezionale</div>
                                    <div class="col-6 col-lg-2">
                                        <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_referenceprefix.value" (change)="updateList()" [class.alert-info]="this.filter_referenceprefix.value">
                                            <option value="">Tutti</option>
                                            <option *ngFor="let rp of referenceprefix" value="{{rp.code}}">{{rp.code}} {{rp.label}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-lg-2 text-lg-right p-lg-2">Stato pagamento</div>
                                    <div class="col-6 col-lg-2">
                                        <div class="btn-group btn-group-sm w-100">
                                            <button [class.bg-primary]="!filter_state_payment.enabled" type="button" class="btn w-50 btn-default border btn-sm text-xs" (click)="filter_state_payment.enabled=false; updateList()">Tutti</button>
                                            <button [class.bg-danger]="filter_state_payment.enabled" type="button" class="btn w-50 btn-default border btn-sm text-xs" (click)="filter_state_payment.enabled=true; updateList()">Da pagare</button>
                                        </div>
                                    </div>
                                    <div class="col-6 col-lg-2 text-lg-right p-lg-2 {{filter_id_parent.enabled}}">Documenti collegati</div>
                                    <div class="col-6 col-lg-2">
                                        <div class="btn-group btn-group-sm w-100">
                                            <button [class.bg-primary]="!filter_id_parent.enabled" type="button" class="btn w-50 btn-default border btn-sm text-xs" (click)="filter_id_parent.enabled=false; updateList()">Visualizza</button>
                                            <button [class.bg-warning]="filter_id_parent.enabled" type="button" class="btn w-50 btn-default border btn-sm text-xs" (click)="filter_id_parent.enabled=true; updateList()">Nascondi</button>   
                                        </div>
                                    </div>                                   
                                </div>                                
                                <div class="row">
                                    <ng-container *ngIf="checkPermissions('showCreatedFrom')">
                                        <div class="col-6 col-lg-2 text-lg-right p-lg-2">Sedi</div>
                                        <div class="col-6 col-lg-2">
                                            <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_created_from.value" (change)="updateList()" [class.alert-info]="this.filter_created_from.value">
                                                <option value="">Tutte</option>
                                                <option *ngFor="let s of shops" value="{{s.id}}">{{s.name}}</option>
                                            </select>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="isModuleEnabled('financialaccounts')">
                                        <div class="col-6 col-lg-2 text-lg-right p-lg-2">Conti contabili</div>
                                        <div class="col-6 col-lg-2" [class.text-info]="this.filter_financialaccount.value">
                                            <app-financialaccount [showAll]="true" [id_financialaccount]="this.filter_financialaccount.value" [children]="true" (selected)="this.filter_financialaccount.value=$event['id'];updateList()"></app-financialaccount>
                                        </div>
                                    </ng-container>                                    
                                    <ng-container *ngIf="type==1">
                                        <div class="col-6 col-lg-2 text-lg-right p-lg-2">Stato SdI</div>
                                        <div class="col-6 col-lg-2">
                                            <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_sdi_status.value" (change)="updateList();askForVerifySdi()" 
                                            [class.alert-warning]="this.filter_sdi_status.value==1"
                                            [class.alert-success]="this.filter_sdi_status.value==2"
                                            [class.alert-danger]="this.filter_sdi_status.value==3"
                                            [class.alert-success]="this.filter_sdi_status.value==4">
                                                <option value="">Tutti</option>
                                                <option value="-1">Da inviare</option>
                                                <option value="1">Inviati</option>
                                                <option value="2">Consegnati</option>
                                                <option value="4">Confermati ma mancata consegna</option>
                                                <option value="3">Errore</option>                                            
                                            </select>
                                        </div>
                                    </ng-container>
                                </div>
                            </app-filtercontainer>
                            <div class="d-none d-lg-block icon" style="right:calc(100% - 100px)"><i class="fas fa-microscope"></i></div>
                        </div>                        
                    </ng-container>
                    <!-- <ng-container *ngIf="mode=='embedded'">
                        <div class="row" >
                            <div class="col-12">
                                <button class="btn btn-sm btn-warning" (click)="export()"><i class="fa fa-print"></i></button>
                            </div>
                        </div>
                    </ng-container> -->
                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>    
                    </div>
                    
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap table-sm table-striped m-0 text-sm">
                            <thead class="bg-light">
                                <th *ngIf="type==0"></th>
                                <th width="20px">
                                    <input #checkboxselectall type="checkbox" title="Seleziona tutti" 
                                    (change)="onChangeAll($event.target.checked)" />
                                </th>
                                <th class="text-center">Tipo</th>
                                <th>Data
                                    <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('i.date')">
                                        <i class="fas fa-sort {{classOrdering('i.date')}}"></i>
                                    </button>
                                </th>
                                <th>Rif.
                                    <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('CAST(i.reference AS UNSIGNED)')">
                                        <i class="fas fa-sort {{classOrdering('i.reference')}}"></i>
                                    </button>
                                </th>
                                <th *ngIf="id_address==0">
                                    <span *ngIf="type==1">Cliente</span>
                                    <span *ngIf="type==2">Fornitore</span>
                                    <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('i.name')">
                                        <i class="fas fa-sort {{classOrdering('i.name')}}"></i>
                                    </button>
                                </th>
                                <th class="text-center d-none d-xl-table-cell" title="Conti contabili" *ngIf="isModuleEnabled('financialaccounts')">Conto</th>
                                <th class="text-center">Metodo
                                    <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('i.methodpayment')">
                                        <i class="fas fa-sort {{classOrdering('i.methodpayment')}}"></i>
                                    </button>
                                </th>
                                <th class="text-center">Totale</th>
                                <th class="text-center d-none d-xl-table-cell">
                                    <span *ngIf="type==1">Incassato</span>
                                    <span *ngIf="type==2">Pagato</span>
                                    <span *ngIf="type==0">Pagato</span></th>
                                <th class="text-center d-none d-xl-table-cell">
                                    <span *ngIf="type==1">Da incassare</span>
                                    <span *ngIf="type==2">Da pagare</span>
                                    <span *ngIf="type==0">Rimanente</span></th>
                                <th class="text-center d-none d-xl-table-cell">
                                    Scadenza
                                    <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('i.paymentschedule')">
                                        <i class="fas fa-sort {{classOrdering('i.paymentschedule')}}"></i>
                                    </button>
                                </th>
                                <th class="text-center">Stato</th>
                                <th class="text-center d-none d-xl-table-cell">Operatore</th>
                                <th>
                                    <button *ngIf="mode=='embedded'" class="btn btn-xs btn-warning float-right" (click)="export()"><i class="fa fa-print"></i></button>
                                </th>
                            </thead>
                            <tbody>
                            
                                <!-- elemento ripetuto -->
                                <ng-container *ngFor="let record of list">
                                    <tr [class.bg-grayline]="record.showReference" [class.text-gray]="record.typedocument==10 || record.typedocument==13">
                                        <td *ngIf="type==0" [class.bg-gray-dark]="record.id_type==2" [class.bg-gray]="record.id_type==1">
                                            <small class="float-left help" title="Documento in ingresso" *ngIf="record.id_type==2"><i class="fas fa-sign-in-alt fa-2x"></i></small>
                                            <small class="float-right help" title="Documento in uscita" *ngIf="record.id_type==1"><i class="fas fa-sign-out-alt fa-2x"></i></small>
                                        </td>
                                        <td>
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}" title="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </td>
                                        <td class="text-center">
                                            <span *ngIf="record.typedocument==1"    title="Fattura" class="badge bg-olive help"                        >FS</span>
                                            <span *ngIf="record.typedocument==2"    title="Fattura Accompagnatoria" class="badge bg-teal help"         >FV</span>
                                            <span *ngIf="record.typedocument==12"   title="Fattura Acconto" class="badge bg-lime help"                 >FA</span>
                                            <span *ngIf="record.typedocument==3"    title="Nota di Credito" class="badge bg-maroon help"               >NC</span>
                                            <span *ngIf="record.typedocument==4"    title="Preventivo" class="badge bg-warning help"                   >PR</span>
                                            <span *ngIf="record.typedocument==5"    title="Documento Di Trasporto" class="badge bg-lightblue help"     >DDT</span>
                                            <span *ngIf="record.typedocument==6"    title="Scontrino" class="badge bg-gray help"                       >SC</span>
                                            <span *ngIf="record.typedocument==7"    title="Ordine Cliente" class="badge bg-secondary help"             >OC</span>
                                            <span *ngIf="record.typedocument==8"    title="Ordine Fornitore" class="badge bg-secondary help"           >OF</span>
                                            <span *ngIf="record.typedocument==9"    title="Non Fiscale" class="badge bg-secondary help"                >NF</span>
                                            <span *ngIf="record.typedocument==10"   title="Trasferimento" class="badge border help"                    >TR</span>
                                            <span *ngIf="record.typedocument==11"   title="Documento Amministrativo" class="badge bg-secondary help"   >DA</span>
                                            <span *ngIf="record.typedocument==13"   title="Inventario" class="badge border help"                       >INV</span>
                                            <br>
                                            <small>
                                                <ng-container *ngIf="record.islinked">
                                                    <i class="fas fa-link mx-1 help" title="Ci sono dei documenti collegati"></i>
                                                </ng-container>
                                                <small *ngIf="record.private">
                                                    <container-element [ngSwitch]="record.private">
                                                        <some-element *ngSwitchCase="1">Privato</some-element>
                                                        <some-element *ngSwitchCase="2">Estero</some-element>
                                                        <some-element *ngSwitchDefault></some-element>
                                                    </container-element>
                                                </small>                                                
                                            </small>
                                        </td>
                                        <td>
                                            <span [class.trashed]="record.status == 2" (dblclick)="record.showReference=true" title="Doppio click per modifica rapida" class="pointer">
                                                {{record.date | date:'dd/M/yyyy'}}<br><small class="text-gray">{{record.date | date:'EEEE'}}</small>
                                            </span>
                                        </td>
                                        <td style="max-width:5rem">
                                            <span (dblclick)="record.showReference=true" title="Doppio click per modifica rapida" class="pointer">
                                                <span>{{ record.reference }}</span>
                                                <span *ngIf="record.referenceprefix && record.referenceprefix!=''">/{{ record.referenceprefix }}</span>
                                            </span>
                                        </td>
                                        <td *ngIf="id_address==0" style="max-width:12rem">
                                            <ng-container *ngIf="record.addressItem"><b>{{record.addressItem.name}}</b></ng-container>
                                            <span *ngIf="(record.typedocument==1 || record.typedocument==2 || record.typedocument==12 || record.typedocument==3) && record.id_type==1">
                                                <i  title="{{verifyBillingAddress(record)}}" class="text-warning fas fa-exclamation-triangle ml-2 help"
                                                        *ngIf="verifyBillingAddress(record)!=''"></i>
                                            </span><br>
                                            <ng-container *ngIf="record.typedocument==5 && record.private">
                                                <small class="d-inline-block overflow-hidden w-100" style="max-height:1.7rem"><i class="fas fa-map-marker-alt text-primary mr-1"></i>Destinazione: {{record.name_destination}}</small>
                                            </ng-container>
                                            <ng-container *ngIf="record.description!=undefined && record.description!=''">
                                                <small class="d-inline-block overflow-hidden w-100" style="max-height:1.7rem">
                                                    <i class="help" title="{{record.description}}"><i class="fas fa-info-circle text-gray mr-1"></i>{{record.description}}</i>
                                                        <i *ngIf="record.description2" title="{{record.description2}}"><span *ngIf="record.description">&ensp;/&ensp;</span>{{record.description2}}</i>
                                                </small>
                                            </ng-container>
                                            <ng-container *ngIf="record.note!=undefined && record.note!=''">
                                                <small class="d-inline-block overflow-hidden w-100 help" style="max-height:1.7rem;" title="{{record.note}}"><i class="fas fa-thumbtack text-info mr-2"></i>{{record.note}}</small>
                                            </ng-container>
                                        </td>
                                        <td class="text-center d-none d-xl-table-cell" *ngIf="isModuleEnabled('financialaccounts')">
                                            <div class="text-purple m-auto " (click)="record['editFinancial']=true" style="max-width:6rem" title="Selezione conto contabile">
                                                <small>
                                                    <i class="fas fa-coins pointer" 
                                                        [class.fa-2x]="!record.financialaccount" 
                                                        [class.text-gray]="!record.financialaccount"></i><br>
                                                    <span class="pointer">{{record.financialaccount}}</span>
                                                    <ng-container *ngIf="record['editFinancial']">
                                                        <app-financialaccount [id_financialaccount]="record.id_financialaccount" [showOnlyTree]="true" [financialaccount]="record.financialaccount" (selected)="updateFinancialAccount(record,$event);"></app-financialaccount>
                                                    </ng-container>
                                                </small>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div *ngIf="record.methodpayment" class="badge border"><b>{{record.methodpayment}}</b></div>
                                        </td>
                                        <td class="text-center">
                                            <ng-container *ngIf="record.typedocument!=5">
                                                <div [class.text-red]="record.total < 0" [class.text-red]="record.typedocument==3" style="text-wrap: nowrap;">                                                    
                                                    <ng-container [ngSwitch]="record.typedocument">
                                                        <span *ngIf="typedocument_to_accounting(record); else total_not_accounting">
                                                            <ng-container *ngIf="type==0">
                                                                <!-- <span *ngIf="record.id_type==1">+</span>
                                                                <span *ngIf="record.id_type==2">-</span> -->
                                                            </ng-container>
                                                            {{record.total | currency:'EUR':true}}
                                                        </span>
                                                        <ng-template #total_not_accounting>
                                                            <span class="d-inline-block help" title="Importo non conteggiato nel totale">
                                                                (<ng-container *ngIf="type==0">
                                                                    <!-- <span *ngIf="record.id_type==1">+</span>
                                                                    <span *ngIf="record.id_type==2">-</span> -->
                                                                </ng-container>
                                                                {{record.total | currency:'EUR':true}})
                                                            </span>
                                                        </ng-template>
                                                    </ng-container>
                                                    <ng-container *ngIf="record.withholdingtax && record.withholdingtax.amount>0">
                                                        <!-- <small><b title="Applicata una ritenuta d'acconto pari a {{record.withholdingtax.amount}} Euro" class="badge bg-pink help ml-1">R</b></small> -->
                                                        <i class="far fa-registered text-pink ml-1 help" title="Applicata una ritenuta d'acconto pari a {{record.withholdingtax.amount}} Euro"></i>
                                                    </ng-container>
                                                </div>
                                                <small class="text-gray">di cui IVA {{ record.tax_total | currency:'EUR':true }}</small>                                    
                                            </ng-container>
                                        </td>
                                        <td class="text-center d-none d-xl-table-cell" [class.text-success]="record.paid > 0">
                                            <span *ngIf="typedocument_to_accounting(record); else total_not_accounting">
                                                <ng-container *ngIf="type==0">
                                                    <span *ngIf="record.id_type==1">+</span>
                                                    <span *ngIf="record.id_type==2 && record.paid>0">-</span>
                                                </ng-container>
                                                {{record.paid | currency:"&euro;"}}
                                            </span>
                                            <ng-template #total_not_accounting>
                                                <span *ngIf="record.paid" class="help" title="Importo non conteggiato nel totale">(
                                                    <ng-container *ngIf="type==0">
                                                        <span *ngIf="record.id_type==1">+</span>
                                                        <span *ngIf="record.id_type==2 && record.paid>0">-</span>
                                                    </ng-container>
                                                    {{record.paid | currency:'EUR':true}})
                                                </span>                                                
                                            </ng-template>                                            
                                        </td>
                                        <td class="text-center d-none d-xl-table-cell">
                                            <ng-container *ngIf="this.record.typedocument==1 || this.record.typedocument==2 || this.record.typedocument==3 || this.record.typedocument==6 || this.record.typedocument==11 || this.record.typedocument==12">
                                                <span title="Registra pagamento" (click)="addPayment(record);" class="pointer">                                                
                                                    <span *ngIf="record.total_pay - abs(record.paid) < -0.01" title="Pagato in eccesso!" class="text-warning">+ {{record.total_pay - abs(record.paid) | currency:'EUR':true}}</span>
                                                    <span *ngIf="record.id_documentsrule!='4' && (record.total_pay - abs(record.paid)) > 0.01" class="text-danger">{{record.total_pay - abs(record.paid) | currency:'EUR':true}}</span>
                                                    <span *ngIf="record.id_documentsrule=='4' && (record.net - abs(record.paid)) > 0.01"  class="text-danger">{{record.net - abs(record.paid) | currency:'EUR':true}}</span>
                                                </span>
                                            </ng-container>
                                        </td>
                                        <td class="text-center d-none d-xl-table-cell">
                                            <ng-container *ngIf="record.paymentschedule">
                                                <span>{{record.paymentschedule | date:"dd/MM/YY"}}</span><br>
                                                <small class="text-gray">{{record.paymentschedule | date:'EEEE'}}</small>                                            
                                            </ng-container>
                                        </td>
                                        <td class="text-center">
                                            <app-statedocument [state]="record.state"></app-statedocument>
                                            <ng-container *ngIf="(record.typedocument==1 || record.typedocument==2 || record.typedocument==12|| record.typedocument==3) && record.id_type==1">
                                                <br>
                                                <div class="badge border" 
                                                    [class.alert-warning]="record.sdi_status==1"
                                                    [class.alert-success]="record.sdi_status==2 || record.sdi_status==4"
                                                    [class.alert-danger]="record.sdi_status==3">
                                                    SdI&nbsp;
                                                    <!-- <i  title="Click per inviare" *ngIf="record.sdi_status==0 || record.sdi_status==-1" class="text-info fas fa-paper-plane pointer" (click)="sendEInvoice(record.id,record.typedocument)"></i> -->
                                                    <i  title="Da inviare (sono trascorsi {{verifySdiDate(record)}} giorni)" *ngIf="record.sdi_status==0 || record.sdi_status==-1" [class.blink]="verifySdiDate(record)>11" [class.text-danger]="verifySdiDate(record)>12" class="text-gray fas fa-paper-plane help"></i>
                                                    <i  title="Inviata, in attesa di risposta" *ngIf="record.sdi_status==1" class="text-warning fa fa-clock"></i>
                                                    <i  title="Consegnata" *ngIf="record.sdi_status==2" class="text-success fa fa-check"></i>
                                                    <i  title="In errore" *ngIf="record.sdi_status==3" class="text-danger fa fa-exclamation-triangle help"></i>
                                                    <i  title="Confermata ma mancata consegna" *ngIf="record.sdi_status==4" class="text-primary fa fa-check"></i>
                                                </div>
                                            </ng-container>
                                        </td>
                                        <td class="text-center d-none d-xl-table-cell" style="max-width:6rem">
                                            <div *ngIf="record.shopname" class="text-truncate mw-100 help" title="{{record.username}}">{{record.username}}</div>                                            
                                            <small>
                                                <div *ngIf="!record.shopname" class="text-gray text-truncate mw-100 help" title="{{record.username}}"><i class="fas fa-user-tie mr-1"></i>{{record.username}}</div>
                                                <div *ngIf="record.shopname" class="badge border text-truncate mw-100 mr-2 help" title="Sede di emissione"><i class="fa-store-alt fas mr-1"></i><b>{{record.shopname}}</b></div>
                                                <div *ngIf="record.origin" class="badge badge-secondary text-truncate mw-100 help" title="Origine del documento">{{record.origin}}</div>
                                            </small>
                                        </td>
                                        <td>
                                            <!--<button type="button" class="btn btn-sm btn-info" (click)="sendEInvoice(record.id)" *ngIf="(record.typedocument==1 || record.typedocument==2) && record.id_type==1 && record.sdi_status==0">SdI</button>-->
                                            <ng-container  *ngIf="mode!='modal'">
                                                <button class="btn btn-xs float-right mt-1 ml-1" type="button" title="Altri comandi" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </button>
                                                <div class="dropdown-menu pt-0" aria-labelledby="dropdownMenuLink">
                                                        <b class="dropdown-item border-bottom bg-light text-center text-primary">ALTRI COMANDI</b>
                                                        <button type="button" class="dropdown-item" (click)="record.showReference=true">Modifica rapida</button>
                                                        <button type="button" class="dropdown-item" (click)="addPayment(record)" *ngIf="record.typedocument!=4 && record.typedocument!=5 && record.typedocument!=7 && record.typedocument!=8 && !record.paid">Registra pagamento</button>
                                                        <button type="button" class="dropdown-item" (click)="duplicate(record)">Duplica</button>
                                                        <!-- <button type="button" class="dropdown-item" (click)="openConvert(record)">Crea da...</button> -->
                                                        <!-- <button type="button" class="dropdown-item" (click)="createXMLFatturaElettronica(record)"
                                                            *ngIf="mode!='modal' && record.id_type==1 && (record.typedocument==1 || record.typedocument==2 || record.typedocument==3 || record.typedocument==12)">Fattura elettronica</button> -->
                                                        <button type="button" class="dropdown-item" (click)="notify(record)" >Invia Notifica</button>
                                                        
                                                    </div>
                                            </ng-container>
                                            <button type="button" *ngIf="mode=='embedded'" title="Cestina" class="btn btn-danger btn-xs float-right mt-1 ml-1" (click)="trash([record.id],true);">
                                                <i class="far fa-trash-alt"></i>
                                            </button>
                                            <button type="button" *ngIf="mode!='modal'"  (click)="openDetail(record)" routerLinkActive="active" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right mt-1 ml-1">
                                                <i class="fas fa-pencil-alt"></i></button>
                                            <button type="button" *ngIf="mode!='modal'" (click)="exportInRow(record)" title="Stampa" class="btn btn-warning btn-xs float-right mt-1 ml-1">
                                                <i class="fas fa-print"></i></button>
                                             <button type="button" *ngIf="mode!='modal' && record.id_type==1 && (record.typedocument==1 || record.typedocument==2 || record.typedocument==3 || record.typedocument==12)"
                                                (click)="createXMLFatturaElettronica(record)" title="Fattura elettronica XML" class="btn btn-primary btn-xs float-right mt-1 ml-1">
                                                <i class="fas fa-file-code"></i></button>
                                            <button type="button" *ngIf="mode!='modal' && record.files && record.files.length>0" (click)="openlistFileModal(record)" title="Mostra file allegati" class="btn btn-info btn-xs float-right mt-1 ml-1">
                                                <i class="fas fa-file-alt"></i></button>
                                            <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                                <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <!-- Modifica rapida -->
                                    <tr *ngIf="record.showReference" class="bg-grayline">
                                        <td colspan="4">
                                            <select title="{{type}}" class="form-control form-control-sm" [(ngModel)]="record.typedocument" name="typedocument" #type="ngModel">
                                                <option value="1">Fattura</option>
                                                <option value="2">Fattura accompagnatoria</option>
                                                <option value="12">Fattura acconto</option>
                                                <option *ngIf="record.id_type==1" value="3">Nota di credito</option>
                                                <option *ngIf="record.id_type==2" value="3">Nota di debito</option>
                                                <option value="4">Preventivo</option>
                                                <option value="5">DDT</option>
                                                <option *ngIf="record.id_type==1" value="6">Scontrino</option>
                                                <option *ngIf="record.id_type==1" value="7">Ordine cliente</option>
                                                <option *ngIf="record.id_type==2" value="8">Ordine fornitore</option>
                                                <option value="9">Non fiscale</option>
                                                <option value="11">Documento amministrativo</option>
                                            </select>
                                        </td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input type="text"  class="form-control form-control-sm" [(ngModel)]="record.reference">
                                                <select class="form-control form-control-sm p-1" [(ngModel)]="record.referenceprefix">
                                                    <option *ngFor="let rp of referenceprefix" value="{{rp.code}}">{{rp.code}} ({{rp.label}})</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td colspan="2">
                                            <div class="input-group input-group-sm">
                                                <input type="date"  class="form-control form-control-sm" [(ngModel)]="record.date">
                                            </div>
                                        </td>
                                        <td colspan="7">
                                            <button class="btn btn-success btn-sm" (click)="update(record);record.showReference=false;"><i class="fa fa-check mr-2"></i>Conferma</button>
                                            <button class="btn btn-danger btn-sm ml-1" (click)="record.showReference=false;updateList()"><i class="fa fa-times"></i></button>
                                            <b class="text-lg float-right d-none d-md-inline" style="color: rgba(0, 0, 0, 0.2);">MODIFICA RAPIDA&ensp;</b>
                                        </td>
                                    </tr>
                                </ng-container>
                                <!-- FINE elemento ripetuto -->

                            </tbody>
                            <tfoot>
                                <tr>
                                    <td *ngIf="type==0"></td>
                                    <td colspan="4"><b>TOTALE</b></td>
                                    <td *ngIf="id_address==0"></td>
                                    <td class="d-none d-xl-table-cell" *ngIf="isModuleEnabled('financialaccounts')"></td>
                                    <td></td>
                                    <td class="text-center" [class.text-red]="total < 0"><b>{{total | currency:'EUR':true }}</b></td>
                                    <td class="text-center d-xl-table-cell" [class.text-red]="paid < 0" [class.text-success]="paid > 0"><b>{{paid | currency:'EUR':true }}</b></td>
                                    <td class="text-center d-xl-table-cell" [class.text-red]="remain>0"><b>{{remain | currency:'EUR':true }}</b></td>                                    
                                    <td class="d-none d-xl-table-cell" colspan="3"></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>


<ng-template #convertDocumentModal let-modal>
    <div class="modal-header">
        <b>Crea da...</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="m-5 text-center text-secondary">
                <i class="icon fas fa-info-circle mr-2"></i>
                <i>Crea un documento con i dati del documento selezionato e lo collega ad esso 
                    (per esempio: crea da uno scontrino la fattura collegata).</i>
            </div>
            <label>Tipo documento da creare</label>
            <select class="form-control form-control-sm " [(ngModel)]="convertTo">
                <option value="1">Fattura</option>
                <option value="2">Fattura accompagnatoria</option>
                <option value="12">Fattura di acconto</option>
                <option value="3">Nota di credito</option>
                <option value="4">Preventivo</option>
                <option value="5">Documento Di Trasporto</option>
            </select>
        </div>
        <div class="form-group">
            <label>Sezionale da assegnare</label>
            <select class="form-control form-control-sm" [(ngModel)]="referenceprefixTo">
                <option *ngFor="let rp of referenceprefix" value="{{rp.code}}">{{rp.code}} {{rp.label}}</option>
                <option value=""></option>
            </select>
        </div>
        <div class="form-group">
            <label>Direzione</label>
            <select class="form-control form-control-sm" [(ngModel)]="typeTo">
                <option [ngValue]="2">in Ingresso</option>
                <option [ngValue]="1">in Uscita</option>
            </select>
        </div>
        <!-- <div class="form-group">
            <label>Anagrafica</label>
            <div class="input-group">
                <input type="text" class="form-control form-control-sm" value="{{addressItemTo}}"/>
                <div class="input-group-append ">
                    <a class="btn btn-sm btn-default" (click)="changeAddressItemTo()"><i class="fa fa-users"></i><span class="d-none d-sm-inline-block ml-2">Cambia</span></a>
                </div>
            </div>
        </div> -->
    </div>
    <div class="modal-footer">        
        <button class="btn btn-success" (click)="convertDocument()">Crea</button>
        <!-- <button class="btn btn-success" (click)="convertDocument()">Crea & modifica</button> -->
        <button class="btn btn-secondary" (click)="modal.close()">Annulla</button>
    </div>
</ng-template>


<ng-template #reorderReferenceModel let-modal>
    <div class="modal-header">
        <b>Rinumerazione documenti</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body" style="overflow-y: auto;max-height: 400px;">
        <ng-container *ngIf="reorderTab==0" >
            <div class="form-group">
                <label>Sezionale</label>
                <select class="form-control form-control-sm p-1" [(ngModel)]="reorderNumber_referenceprefix" >
                    <option *ngFor="let rp of referenceprefix" value="{{rp.code}}">{{rp.code}} ({{rp.label}})</option>
                </select>
            </div>
            <div class="form-group">
                <label>Anno</label>
                <input type="text" class="form-control form-control-sm p-1" [(ngModel)]="reorderNumber_year">
            </div>
        </ng-container>
        <ng-container *ngIf="reorderTab==1">
            <div *ngIf="!reorderList || reorderList.length==0" class="m-5 text-center text-secondary">
                <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento in lista</i>
            </div>
            <table class="table table-sm" *ngIf="reorderList && reorderList.length > 0">
                <thead>
                    <th>Num. attuale</th>
                    <th>Nuova num.</th>
                    <th>Data</th>
                    <th>Nominativo</th>
                    <th>SdI</th>
                </thead>
                <tbody>
                    <tr *ngFor="let r of reorderList" [class.bg-warning]="r.toupdate">
                        <td><span class="">{{r.reference}}/{{r.referenceprefix}}</span></td>
                        <td [class.font-weight-bold]="r.toupdate">
                            <span *ngIf="!r['showInputReference']" title="Click per modificare" (click)="r['showInputReference']=true" class="form-control form-control-sm bg-light pointer">
                                {{r.new_reference}}/{{r.referenceprefix}}</span>
                            <div *ngIf="r['showInputReference']" class="input-group input-group-sm">
                                <input  type="text" class="form-control form-control-sm  py-1" name="new_reference_{{r.id}}" [(ngModel)]="r['new_reference']" (change)="verifyChangeReorder(r)">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-success" (click)="r['showInputReference']=false"><i class="fa fa-check"></i></button>
                                </div>
                            </div>
                        </td>
                        <td><small>{{r.date | date:"dd/MM/yyyy"}}</small>
                        <td><small >{{r.name}}</small>
                        </td>
                        <td>
                            <i  title="Inviata ed in attesa di risposta" *ngIf="r.sdi_status==1" class="fa fa-clock"></i>
                            <i  title="Consegnata" *ngIf="r.sdi_status==2" class="fa fa-check"></i>
                            <i  title="In errore" *ngIf="r.sdi_status==3" class="fa fa-exclamation"></i>
                            <i  title="Confermata ma con mancata consegna" *ngIf="r.sdi_status==4" class="fa fa-check"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
    <div class="modal-body">
        <button *ngIf="reorderTab==0" class="btn btn-sm btn-secondary m-1 float-left" (click)="btn_prev_reorder();">Annulla</button>
        <button *ngIf="reorderTab==0" class="btn btn-sm btn-secondary m-1 float-right" (click)="btn_next_reorder();">Avanti<i class="fas fa-chevron-right ml-1"></i></button>
        <button *ngIf="reorderTab==1" class="btn btn-sm btn-secondary m-1 float-left" (click)="btn_prev_reorder();"><i class="fas fa-chevron-left mr-1"></i>Indietro</button>
        <button *ngIf="reorderTab==1" [disabled]="!reorderList || reorderList.length==0" class="btn btn-sm btn-success m-1 float-right" (click)="btn_next_reorder();">Rinumera</button>
    </div>
</ng-template>


<ng-template #uploadXMLModal let-modal>
    <div class="modal-body">
        <div class="form-group text-center">
            <label>Inserisci un file XML <small>(formato Fattura Elettronica)</small> da caricare<br>o un pacchetto ZIP contenente più file XML.</label>
            <div class="m-auto text-center p-2" style="width:90%; height:200px; color:#ccc; border: 1px dashed #ccc; border-radius:10px">
                <i class="fas fa-2x fa-bullseye mt-5"></i><br>
                <i>Trascina e rilascia <b>qui</b>.</i><br>
                <a class="btn btn-outline-primary btn-sm mt-4" (click)="fileToUpload.click()"><i class="fas fa-upload mr-2"></i>Carica file</a>
            </div>
            <input class="pointer d-block" style="opacity:0;width:90%;height:200px;margin:-200px auto 0;" type="file" #fileToUpload name="fileXMLToUpload" (change)="handleFileXMLInput($event.target.files);confirmUploadXMLModal();">
        </div>
        <button class="btn btn-sm btn-secondary float-right" (click)="closeUploadXMLModal();">Annulla</button>
    </div>
</ng-template>


<ng-template #listFileModal let-modal>
    <div class="modal-header">
        <b>Seleziona il file da visionare</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closelistFileModal()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
            <button type="button" class="btn btn-default btn-block btn-light text-left" *ngFor="let f of documentSelected.files" (click)="downloadFile(f)">
                <i  title="{{getExtension(f) | uppercase }}" class="mr-2 fa-2x fas fa-file fa-file-{{getExtension(f)}}"
                [class.fa-file-zipper]="getExtension(f)=='zip' || getExtension(f)=='rar'"
                [class.fa-file-image]="getExtension(f)=='bmp' || getExtension(f)=='jpg' || getExtension(f)=='png'"
                [class.fa-file-video]="getExtension(f)=='mov' ||getExtension(f)=='avi' || getExtension(f)=='mp4'"
                [class.fa-file-audio]="getExtension(f)=='wav' ||getExtension(f)=='mp3' || getExtension(f)=='wma'"
                [class.fa-file-word]="getExtension(f)=='doc' || getExtension(f)=='docx'"
                [class.fa-file-excel]="getExtension(f)=='xls' || getExtension(f)=='xlsx'"
                [class.fa-file-powerpoint]="getExtension(f)=='ppt' || getExtension(f)=='pptx'"
                [class.fa-file-code]="getExtension(f)=='xml'"
                [class.fa-file-signature]="getExtension(f)=='p7m'">
                </i>&nbsp;
                <span class="text-truncate d-inline-block" style="width:90%">{{f.name}}</span>
            </button>
    </div>
</ng-template>