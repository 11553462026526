import { Input,Output, Component,OnInit,EventEmitter } from '@angular/core';


@Component({

    template:''
  })

export class Modal{
    @Input()
    mode:string;


    @Input()
    selecttype:string='single';

    @Output()
    selectedRecordOut:EventEmitter<any>=new EventEmitter();

    recordSelected:any;

    titleModal:string="";
    
    constructor(){

    }


    selectRecord(r:any):void{
        this.recordSelected=r;
        this.selectedRecordOut.emit(r);
        /*if(Globals.modal.selectedRecordFromModal)
            Globals.modal.selectedRecordFromModal(r);*/
        //AppComponent.app.closeBtn.nativeElement.click();
        if(this['modalWindow'])
            this['modalWindow'].close("success");
    }

    
    

   
  
}