import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { ToastMode } from 'projects/core/src/include/structures';
import { SystemService } from 'projects/core/src/lib/system.service';

@Component({
  selector: 'settingwindow-listplugins',
  standalone:true,
  imports:[CommonModule,FormsModule],
  templateUrl: './listplugins.component.html',
  styleUrl: './listplugins.component.css'
})
export class ListpluginsComponent {
  @Input()
  plugins=[];

  @Input()
  type="";

  constructor(private systemService:SystemService){}


  saveServerPlugin(pluginname,settings){
    this.systemService.saveServerPlugins(this.type,pluginname,settings).subscribe(()=>{
      
    });
  }

  saveServerPlugins(){
    for(let p of this.plugins){
      this.saveServerPlugin(p.name,p.settings);
    }
  }

  runFunction(pluginname,method){
    this.systemService.runServerFuncitonPlugins(this.type,pluginname,method).subscribe((result)=>{
      console.log(result);
      Globals.message.showToaster(result,ToastMode.SUCCESS);
    });
  }


}
