

  <div class="wrapper" *ngIf="currentIsLogin==true" style="background-color:#a8b6c4" [style.background-color]="config.softwarecolor" >

    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-light border-bottom-0">
      <!-- Left navbar links -->
      <ul class="navbar-nav" *ngIf="menu.length>1">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" ><i class="fas fa-bars pt-2" title="Scopri/Nascondi menu"></i></a>
        </li>
      </ul>
      <div class="navbar-nav" >
        <h5 class="text-secondary m-0" *ngIf="history.length > 0"><b>{{history[history.length-1].label}}</b></h5>
      </div>
      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto mr-1">

        <!-- User login -->
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <div *ngIf="!currentUser['shop']"></div>
            <i class="fas fa-user-tie mr-1"></i>
            <span  title="{{currentUser.name}}" class="d-none d-sm-inline-block"><span style="max-width:150px;" class="mb-0 text-truncate">{{currentUser.name}}</span></span><br/>
            <small  title="{{currentUser['shop']['name']}}" style="max-width:120px; font-size:7pt" class="d-none d-sm-inline-block float-left text-uppercase text-truncate" 
              *ngIf="currentUser['shop']">{{currentUser['shop']['name']}}
            </small>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0">
            <div class="card mb-0">
                <div class="card-header text-center text-xs p-2" style="line-height: 12px;">
                  <b style="max-width:150px;" class="mb-0 text-truncate"><i class="fas fa-user-tie mr-1"></i>{{currentUser.username}}</b><br>
                  <!-- <small class="text-uppercase text-xs"><small>{{currentUser.role}}</small></small> -->
                </div>
                <div class="card-body p-0">                  
                  <button type="button" (click)="openModalUserInfo(currentUser)" class="dropdown-item"><i class="fas fa-id-card mr-2"></i><small>I tuoi dati</small>
                    <small style="max-width:150px; font-size:7pt" class="d-block d-sm-none text-uppercase text-truncate">{{currentUser.name}}</small>
                  </button>
                  <div class="dropdown-divider"></div>
                  <ng-container *ngIf="enableSelectPoint">
                    <button type="button" (click)="openSelectPoint()" class="dropdown-item"><i class="fas fa-house-user mr-2"></i><small>Cambia sede di lavoro</small>
                      <small style="max-width:150px; font-size:7pt" class="d-block d-sm-none text-uppercase text-truncate" *ngIf="currentUser['shop']">{{currentUser['shop']['name']}}</small>
                    </button>
                    <div class="dropdown-divider"></div>
                  </ng-container>                  
                  <button type="button" (click)="logout()" class="dropdown-item"><i class="fas fa-user-times mr-2"></i><small>Esci dall'account</small></button>
                </div>
            </div>
          </div>
        </li>

         <!-- Connector -->
        <li class="nav-item" style="color: rgba(0, 0, 0, 0.5)"  *ngIf="enableC1Connector">
          <span class="nav-link help" title="{{c1connector_status?'Connesso':'Non connesso'}} ">
            <i [class.text-success]="c1connector_status" class="fas fa-network-wired pr-2"></i><br>
              <span class="float-left" style="font-size:7pt">{{c1connector_c1connectorid}}</span>
          </span>
        </li>

        <!-- Notifiche -->
        <li class="nav-item dropdown">
          <app-alarm></app-alarm>
        </li>

         <!-- Chat -->
         <li class="nav-item dropdown" *ngIf="enableChat">
          <app-chat></app-chat>
        </li>

        <!-- Impostazioni -->
        <li class="nav-item dropdown" *ngIf="menu_settings.length>0" >
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false" title="Impostazioni">
            <i class="fas fa-cog"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0">
            <div class="card mb-0">
              <div class="card-header text-center p-2 text-xs"><i class="fas fa-cog mr-1"></i><b>IMPOSTAZIONI</b></div>
              <div class="card-body p-0">
                <ng-container *ngFor="let m of menu_settings"  >
                  <a (click)="goToView(m)" class="btn btn-light dropdown-item">
                      <i class="{{m.icon}} mr-2"></i><small>{{m.label}}</small>
                  </a>
                  <div class="dropdown-divider"></div>
                </ng-container>
              </div>
             </div>
          </div>
        </li>

      </ul>
    </nav>
    <!-- /.navbar -->


    <!-- Main Sidebar Container -->
    <aside  class="main-sidebar sidebar-dark-primary position-fixed elevation-3" >
      <!-- Brand Logo -->
      <p class="brand-link elevation-1 mb-0">
        <img *ngIf="config.softwareicon" alt="logo" class="brand-image" src="{{config.softwareicon}}">
        <span style="max-width:125px;" class="brand-text text-truncate d-inline-block text-white" title="{{config.softwarename}}">{{config.softwarename}}</span>
      </p>
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column nav-compact" data-widget="treeview" role="menu" data-accordion="false"> <!-- per comprimere: nav-compact -->
              <li class="nav-item" *ngFor="let m of menu" >
                  <a class="d-none d-lg-block nav-link" (click)="goToView(m)" [class.active]="m==current_module" >
                    <i class="nav-icon {{m.icon}} mr-2"></i><p>{{m.label}}</p>
                  </a>
                  <a class="d-block d-lg-none nav-link" (click)="goToView(m)" [class.active]="m==current_module" data-widget="pushmenu" > <!-- per auto-nascondersi data-widget="pushmenu"  -->
                    <i class="nav-icon {{m.icon}} mr-2"></i><p>{{m.label}}</p>
                  </a>
              </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
    <div id="sidebar-overlay">
      <a class="close_sidebar d-block d-lg-none" data-widget="pushmenu" href="#" ><i class="fas fa-times"></i></a>
    </div>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper px-1 pb-3" > <!--style="margin-top:56px;"-->
        <router-outlet></router-outlet>
        <div>
          <!-- Use ng-template to ensure that the generated components end up in the right place -->
          <ng-template #mainContent></ng-template>
        </div>
    </div>
    <!-- /.content-wrapper -->
    
    <!-- Right Sidebar -->

    <aside *ngIf="info" class="control-sidebar bg-secondary position-fixed d-block" style="height: calc(100% - 56px - 16px)">
      <button type="button" class="btn float-right" (click)="info==false?info=true:info=false">
        <i class="fas fa-times text-light"></i>
      </button>
      <div class="p-3 pt-4 control-sidebar-content">
        <small>
        <b>PER INIZIARE</b><br>
          Lorem ipsum....

        </small>
      </div>
    </aside>

    <!-- /.Right-sidebar -->
  
    <!-- Main Footer width: calc(100% - 250px); -->
    <footer class="main-footer d-none d-sm-inline-block fixed-bottom text-xs" style="background-color:#a8b6c4" [style.background-color]="config.softwarecolor" >
      <div class="float-left mx-2"><span class="text-primary"><b>C1</b></span></div>
      <div class="float-left mr-5 pointer"  title="Changelog (Premi CTRL+F5 per aggiornare)" (click)="openModalChangeLog()">versione {{version}} ({{build}})</div>
      <div class="float-left mr-5" *ngIf="loadingstatusbar"><i class="fas fa-spinner fa-spin mr-2"></i>{{loadingstatusbarmessage}}</div>      
      <div *ngIf="!currentUser.isSuperUser() && copyright" class="float-left mr-5 d-none d-lg-block">is a <a href="http://codingict.it" target="_blank">Coding</a> software</div>

      <ng-container *ngIf="company.name">
        <div (click)="openModalRegistrationInfo()" title="Dati di registrazione" class="float-right d-none d-sm-inline-block ml-5 mr-2 pointer">Licenza:&ensp;<span class="text-primary">{{company.name}}</span></div>
        <div (click)="openModalRegistrationInfo()" title="Dati di registrazione" class="float-right ml-5 d-none d-lg-block pointer">Scadenza: <span [class.text-primary]="!readonly" [class.text-danger]="readonly">{{config.expired_license | date:"dd/M/yyyy"}}</span></div>
        <div class="float-right ml-5 d-none d-lg-block"><i class="fa-hdd far mr-1"></i><span [class.text-primary]="true" [class.text-danger]="false">{{total_occupied_space}}b</span> / {{total_space}}Gb</div>
      </ng-container>
      <div *ngIf="currentUser.isSuperUser()" class="float-right d-none d-sm-inline-block mx-2">Server:&ensp;<span class="text-primary">{{config.serverUrl}}</span></div>
    </footer>

</div>


<ng-template #modalUserInfo let-modal>
  <div class="modal-header">
    <b><i class="fas fa-user-tie mr-3"></i>I tuo dati</b>
</div>
  <div class="modal-body p-4">
    <i class="fas fa-user-circle float-right text-light fa-8x mr-3"></i>    
    <p>Nome:<br><b>{{currentUser.name}}</b></p>
    <p>Login:<br><b>{{currentUser.username}}</b></p>   
    <p>Ruolo:<br>     
        <span *ngIf="currentUser.role=='admin'"     ><b>Amministratore</b><br><small><i>Accesso a tutte le sezioni ed autorizzazioni complete</i></small></span>
        <span *ngIf="currentUser.role=='manager'"   ><b>Manager</b><br><small><i>Accesso solo alle sezioni assegnate dall'Amministratore ed autorizzazioni complete (visualizzazione, inserimento, modifica, eliminazione, ...)</i></small></span>
        <span *ngIf="currentUser.role=='agent'"     ><b>Agente</b><br><small><i>Accesso solo alle sole sezioni assegnate dall'Amministratore ed autorizzazioni limitate (visualizzazione, inserimento, modifica)</i></small></span>
        <span *ngIf="currentUser.role=='external'"  ><b>Partner</b><br><small><i>Accesso limitato solo alle sezioni assegnate dall'Amministratore ed autorizzazioni minime (visualizzazione, inserimento)</i></small></span>  
    </p>
    <p>Dal:<br><b>{{currentUser.created  | date:"dd MMMM yyyy"}}</b></p>
    <p>ID:<br><b>{{currentUser.id}}</b></p>
    <p>Stato:<br>
      <b *ngIf="currentUser.enabled==1" [class.text-success]="true">ATTIVO</b>
      <b *ngIf="currentUser.enabled==0" [class.text-danger]="true">NON ATTIVO</b>
    </p>
    <ng-container *ngIf="showInputPassword" >
      Password:
      <div class="input-group input-group-sm">
        <input class="form-control form-control-sm" type="password" placeholder="inserisci nuova password..."  [(ngModel)]="currentUser.password">
        <div class="input-group-append input-group-append-sm">
            <span class="input-group-text input-group-text-sm"><i class="fa fa-key"></i></span>
        </div>
      </div>   
    </ng-container>
    <div class="text-center mt-4">
      <button *ngIf="!showInputPassword" (click)="showInputPassword=true" class="btn btn-outline-info btn-block">Cambia password</button>
      <button (click)="modal.close('cancel')" class="btn btn-outline-secondary btn-block">Chiudi</button>
    </div>
  </div>
</ng-template>


<ng-template #modalRegistrationInfo let-modal>
  <div class="modal-header">
    <b><i class="fas fa-key mr-3"></i>Dati di registrazione</b>
</div>
  <div class="modal-body p-4">
    <p class="text-center">
      <b class="text-primary" style="font-size:5rem">C1</b><br>
      <small>Versione {{version}} build: {{build}}</small>
    </p>
    <hr><br>
    <p>Concesso in licenza a:<br><b>{{company.name}}</b><span *ngIf="company.vat_number"> (P.IVA: {{company.vat_number}})</span></p>    
    <p>Scadenza:<br><b>{{config.expired_license | date:"dd/M/yyyy"}}</b></p>
    <p>Stato licenza:<br> 
      <b *ngIf="!readonly" [class.text-success]="true">ATTIVA</b>
      <b *ngIf="readonly" [class.text-danger]="true">NON ATTIVA</b><small *ngIf="readonly"> (solo lettura dati)</small>
    </p>
    <!--
    <p>Dettagli:</p>
    <small style="line-height:0rem;">
      <p>- Spazio di archiviazione: <b>{{total_space}}Gb</b></p>
      <p>- Utenti: <b>1</b></p>
      <p>- Sedi: <b>1</b></p>
      <p>- Magazzini: <b>1</b></p>
      <p>- Prodotti: max <b>1000</b></p>
      <p>- Documenti: max <b>1000</b></p>
    </small>
    -->
    <div class="text-center mt-5">
      <button (click)="modal.close('cancel')" class="btn btn-outline-secondary btn-block">Chiudi</button>
    </div>
  </div>
</ng-template>


<ng-template #modalChangeLog let-modal>
  <div class="modal-header">
    <b><i class="fas fa-bug mr-3"></i>ChangeLog</b>
</div>
  <div class="modal-body text-xs">
    <div style="max-height:80vh; overflow:auto">
      <div class="m-4 text-center text-secondary">
          <i class="icon fas fa-info-circle mr-2"></i>
          <i>Non sei sicurə di visualizzare la versione più aggiornata? Premi <b>CTRL+F5</b> per aggiornare.</i>
      </div>
      <ng-container *ngFor="let cl of changeLog">
        <div class="card m-1 border">
          <div class="card-header py-1">
            Versione: {{cl.version}} &ensp; Build: {{cl.build}}
          </div>
          <div class="card-body py-1">
            <small>
              <ng-container *ngFor="let l of cl.log">
                  <label>Modulo: <b>{{l.module}}</b></label>
                  <ul>
                    <li *ngFor="let lr of l.log">{{lr}}</li>
                  </ul>
              </ng-container>
            </small>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="text-center">
      <button (click)="modal.close('cancel')" class="btn btn-outline-secondary btn-block mt-3">Chiudi</button>
    </div>
  </div>
</ng-template>


<ng-container *ngIf="loading">
  <div class="loading"></div>
  <div class="spinner text-white">
    <div class="text-center">
      <h1>C1</h1>
      <!-- <i class="fas fa-satellite-dish fa-3x"></i><br> -->
      Scaricamento...
    </div>   
    <div class="spinner-border"  role="status" >
      <span class="sr-only">Loading...</span>
    </div>    
  </div>
</ng-container>


<!--
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
-->