<app-windowlist [model]="this">
    <div class="row selectPeriodCalendar">
        <div class="col-lg-12">
          <div class="p-2">
            <div class="row mb-2">
                <div class="col-lg-3 text-light"></div>
                <div class="col-lg-6">
                    <div class="btn-group btn-group-sm w-100">
                        <button type="button" class="btn btn-default border btn-sm" [class.btn-secondary]="modeView==1" [class.active]="modeView==1" (click)="modeView=1">Mensile</button>
                        <button type="button" class="btn btn-default border btn-sm" [class.btn-secondary]="modeView==2" (click)="modeView=2;getItemsByMonth()">Annuale</button>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row" *ngIf="modeView==2">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-6">
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text font-weight-bold bg-light">Anno</span>
                                </div>
                                <input class="form-control font-weight-bold text-center" (click)="getItemsByMonth()" type="number" min="2000" step="1" name="year" [(ngModel)]="year">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary" (click)="getItemsByMonth()"><i class="fa fa-sync-alt"></i><span class="d-none d-xl-inline-block ml-2">Aggiorna</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table class="table table-sm table-striped ">
                        <thead>
                            <th class="text-center">Mese</th>
                            <th class="text-center">Scadenze</th>
                            <th class="text-right">Totale</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of list_month">
                                <td class="text-center" style="background-color: rgba(0,0,0,0.1);"><span class="text-lg">{{r.label}}</span></td>
                                <td class="">
                                    <ng-container>
                                            <table class="table table-sm mb-0">
                                                <tr *ngFor="let d of r.deadlines" (click)="monthClicked(d);" class="pointer" title="Visualizza lista">                                                    
                                                    <td style="width:10rem">
                                                        <div style="min-width:1rem; width: calc(0.02rem * {{d.count}}); max-width:10rem" class="badge badge-secondary px-0" [class.bg-success]="d.total>0" [class.bg-danger]="d.total<0">{{d.count}}</div>
                                                    </td>
                                                    <td>
                                                        <b>{{d.type}}</b>
                                                    </td>
                                                    <td  class="text-right">
                                                        <small *ngIf="d.total!=0" [class.text-success]="d.total>0" [class.text-danger]="d.total<0">{{d.total | currency:"&euro;"}}</small>
                                                    </td>
                                                </tr>
                                            </table>
                                    </ng-container>
                                </td>
                                <td class="text-right" style="background-color: rgba(0,0,0,0.05);">
                                    <b [class.text-success]="r.total>0" [class.text-danger]="r.total<0">{{r.total | currency:" &euro;"}}</b>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="bg-light">
                            <td class="text-center py-5" style="background-color: rgba(0,0,0,0.1);"><b class="text-lg">Anno {{year}}</b></td>
                            <td></td>
                            <td class="text-right py-5" style="background-color: rgba(0,0,0,0.05);"><b [class.text-danger]="total<0">{{total | currency:" &euro;"}}</b></td>
                        </tfoot>
                    </table>
                </div>
            </div>

            <div class="row" *ngIf="modeView==1">
                <div class="d-none d-lg-inline col-lg-3">
                    <div class="btn btn-block btn-default" mwlCalendarToday [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate">
                        <i class="fas fa-calendar-day text-info mr-2"></i>Oggi<span class="d-none d-lg-inline">, {{today | date: 'EEEE dd MMMM yyyy':'':'It' }}</span></div>
                </div>
                <div class="col-lg-6 date text-center">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button type="button" class="btn btn-sm btn-secondary" mwlCalendarPreviousView [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate"><i class="fas fa-angle-left"></i></button>
                        </div>
                        <b class="form-control font-weight-bold text-center bg-light text-truncate text-uppercase">{{ viewDate | calendarDate:(view + 'ViewTitle'):'It'}}</b>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-sm btn-secondary" mwlCalendarNextView [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate"><i class="fas fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-12 mt-2 calendarView" title="Visualizza dettagli">
                    <mwl-calendar-month-view-header ></mwl-calendar-month-view-header>
                    <mwl-calendar-month-view
                    [viewDate]="viewDate"
                    [events]="events"
                    [locale]="locale"
                    [cellTemplate]="customCellTemplate"
                    (dayClicked)="dayClicked($event.day)"  >
                    </mwl-calendar-month-view>
                </div>
            </div>
          </div>
        </div>
    </div>


    <ng-template  #customCellTemplate let-day="day" let-locale="locale">
            <span class="border-left border-top border-secondary text-center text-lg">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            <div class="h-100 border-left border-secondary">
                <ng-container *ngIf="day.events.length>0">
                    <table class="text-secondary">
                        <tr *ngFor="let e of day.events">
                            <ng-container *ngIf="!e.meta.total || e.meta.total==0">
                                <td class="badge badge-secondary">{{e.meta.count}}</td>
                                <td><small>{{e.meta.type}}</small></td>
                            </ng-container>
                            <ng-container *ngIf="e.meta.total && e.meta.total!=0">
                                <td class="badge badge-secondary" 
                                    [class.bg-success]="e.meta.total>=0" 
                                    [class.bg-danger]="e.meta.total<0">
                                    {{e.meta.count}}</td>
                                <td [class.text-success]="e.meta.total>=0" [class.text-danger]="e.meta.total<0"><small>{{e.meta.total | currency:"&euro;"}}</small></td>
                            </ng-container>
                        </tr>
                    </table>
                </ng-container>
            </div>
    </ng-template>

</app-windowlist>