
<div class="mb-2">
    <button class="btn btn-outline-success btn-sm m-1" (click)="addSection()"><i class="fas fa-folder-plus mr-2"></i>Aggiungi Sezione</button>
    <button [disabled]="!sections" class="btn btn-success btn-sm m-1" (click)="addField()"><i class="far fa-plus-square mr-2"></i>Aggiungi Campo</button>    
</div>
<ul class="nav nav-tabs">
    <li class="nav-item" *ngFor="let s of sections;let i=index">
        <a class="nav-link" [class.active]="tabsection==i" (click)="tabsection=i">{{s.name}}
            <i title="Rinomina sezione" (click)="renameSection(s)" *ngIf="tabsection==i && i>0" class="text-secondary fas fa-pencil-alt ml-1"></i>
            <i title="Elimina sezione" (click)="deleteSection(s)" *ngIf="tabsection==i && i>0" class="text-danger fas fa-trash ml-1"></i>
        </a>
    </li>
</ul>
<div class="tab-content" id="myTabContent">
    <ng-container *ngIf="isArray(sections)">
        <div *ngFor="let s of sections;let j=index;" class="tab-pane fade show" [class.active]="tabsection==j" style="min-height:200px;">
            <ul class="list-group">
                <ng-container *ngIf="isArray(s.fields)">
                    <ng-container *ngFor="let f of s.fields;let i=index" >
                        <ng-container *ngIf="f.type!='section'">
                            <li class="list-group-item border" >
                                <div class="row">
                                    <div class="col-lg-1 pl-0">
                                        <small *ngIf="f.id" class="text-gray">ID: {{f.id}}</small>
                                    </div>
                                    <div class="col-lg-9 px-0">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="">
                                                    <small>Nome / Titolo colonna</small>
                                                    <input class="form-control form-control-sm" type="text" name="{{j}}_{{i}}_name" [(ngModel)]="f.name">
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="">
                                                    <small>Tipologia</small>
                                                    <select class="form-control form-control-sm" name="{{j}}_{{i}}_type" [(ngModel)]="f.type" (change)="f.params='';suggestParams(f)">
                                                        <option value="text">Testo</option>
                                                        <option value="textarea">Area di testo</option>
                                                        <option value="select">Casella di riepilogo</option>
                                                        <option value="boolean">Si/No</option>
                                                        <option value="number">Numero</option>
                                                        <option value="currency">Valuta</option>
                                                        <option value="separator">Separatore</option>
                                                        <option value="date">Data</option>
                                                        <option value="time">Orario</option>
                                                        <option value="checkbox">Casella di controllo</option>
                                                        <option value="file">File</option>
                                                        <!--<option value="section">Sezione</option>-->
                                                        <option value="photo">Foto</option>
                                                        <option value="formula">Formula</option>
                                                        <option value="address">Anagrafica</option>
                                                        <option value="table">Tabella</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-2">
                                                
                                                <div class="">
                                                    <small>Obbligatorio</small>
                                                    <select class="form-control form-control-sm" name="{{j}}_{{i}}_required" [(ngModel)]="f.required">
                                                        <option selected [ngValue]="0">No</option>
                                                        <option [ngValue]="1">Si</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" [class.collapse]="!f['expand']">
                                            <div class="col-lg-9">
                                                <div class="">
                                                    <small>Etichetta</small>
                                                    <input class="form-control form-control-sm"  name="{{j}}_{{i}}_label"  type="text" [(ngModel)]="f.label" />
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="">
                                                    <small>Mostra Etichetta</small>
                                                    <select class="form-control form-control-sm"  name="{{j}}_{{i}}_show_label" [(ngModel)]="f.show_label">
                                                        <option value="0">No</option>
                                                        <option value="1">Si</option>
                                                    </select>                                              
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="">
                                                    <small>Valore di default</small>
                                                    <input type="text" class="form-control form-control-sm"  name="{{j}}_{{i}}_defaultvalue" [(ngModel)]="f.defaultvalue" />

                                                    <small *ngIf="f.type=='date'">                                
                                                        Inserire una formula per il calcolo automatico del tipo <br/>
                                                        <pre>new Date('&#123; &#123; nomecampo data &#125; &#125;').setDate(new Date('&#123; &#123; nomecampo data &#125; &#125;').getDate() + &#123;nome campo giorni &#125; &#125;)</pre>
                                                    </small>
                                                    <small *ngIf="f.type=='formula'">
                                                        Inserire una formula che verrà calcolata con linguaggio Javascript. I riferimenti ai campi del form potranno essere scritti &#123; &#123; nomecampo  &#125; &#125;
                                                        <pre>Funzioni speciali
                                                            <br/>
                                                            $SUMTABLE(nome tabella,nome del campo tabella da sommare)
                                                            $AVERAGETABLE(nome tabella,nome del campo tabella su cui effettuare la media)

                                                        </pre>
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="">
                                                    <small>Descrizione estesa</small>
                                                    <textarea class="form-control form-control-sm" rows="1" onfocus="this.rows=3;" name="{{j}}_{{i}}_description" [(ngModel)]="f.description"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" [class.collapse]="!f['expand']">
                                            
                                            <ng-container [ngSwitch]="f.type">
                                                <div class="col-12" *ngSwitchCase="'select'">
                                                    <small>Valori della lista</small>                                                    
                                                    <select class="form-control form-control-sm"  multiple="multiple" [(ngModel)]="param_selected">                                                    
                                                        <option *ngFor="let s of f.params" value="{{s}}">{{s}}</option>
                                                    </select>
                                                    <div class="text-right">
                                                        <button class="btn btn-xs btn-success mt-1 ml-1" (click)="addValueToParams(f)"><i class="fa fa-plus"></i></button>
                                                        <button class="btn btn-xs btn-outline-success mt-1 ml-1" (click)="f.params=colors"><i class="fa fa-plus-square mr-2"></i>Colori</button>
                                                        <button class="btn btn-xs btn-outline-success mt-1 ml-1" (click)="f.params=months"><i class="fa fa-plus-square mr-2"></i>Mesi dell'anno</button>
                                                        <button class="btn btn-xs btn-outline-success mt-1 ml-1" (click)="f.params=qualifications"><i class="fa fa-plus-square mr-2"></i>Titoli di studio</button>
                                                        <button class="btn btn-xs btn-outline-success mt-1 ml-1" (click)="f.params=nations"><i class="fa fa-plus-square mr-2"></i>Stati del mondo</button>
                                                        <button class="btn btn-xs btn-outline-success mt-1 ml-1" (click)="f.params=italian_regions"><i class="fa fa-plus-square mr-2"></i>Regioni italiani</button>
                                                        <button class="btn btn-xs btn-outline-success mt-1 ml-1" (click)="f.params=italian_country"><i class="fa fa-plus-square mr-2"></i>Province italiani</button>
                                                        <button class="btn btn-xs btn-danger mt-1 ml-1" [disabled]="param_selected==''" (click)="removeValueFromParams(f)"><i class="fa fa-trash"></i></button>
                                                    </div>                                                   
                                                </div>

                                                <div class="col-12" *ngSwitchCase="'table'">                                                    
                                                    <app-fields [sections]="f.params"></app-fields>
                                                </div>

                                                <div class="col-12" *ngSwitchDefault>
                                                    <ng-container *ngIf="isArray(f.params) && f.params && f.params.length>0">
                                                            <small>Parametri</small>
                                                            <div class="border p-2">
                                                            <ng-container *ngFor="let s of f.params">
                                                                <small>
                                                                    <app-field [field]="s"></app-field>
                                                                </small>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            
                                            <!--<div class="col-lg-12" *ngIf="f.type=='address'">
                                                <small>Tipologia di default</small>
                                                <select class="form-control form-control-sm" [(ngModel)]="f.params" >
                                                    <option *ngFor="let t of type_addresses" value="{{t.id}}">{{t.name}}</option>
                                                </select>
                                            </div>-->

                                        </div>
                                        <div class="row" [class.collapse]="!f['expand']">
                                            <div class="col-12">
                                                <div class="">
                                                    <small>Note interne</small>
                                                    <textarea type="text" rows="1" onfocus="this.rows=3;" class="form-control form-control-sm"  name="{{j}}_{{i}}_note" [(ngModel)]="f.note" ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" [class.collapse]="!f['expand']">
                                            <div class="col-lg-3">
                                                <div class="">
                                                    <small>Mostra colonna in lista</small>
                                                    <select class="form-control form-control-sm"  name="{{j}}_{{i}}_show_list" [(ngModel)]="f.show_list">
                                                        <option value="0">No</option>
                                                        <option value="1">Si</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="">
                                                    <small>Sezione</small>
                                                    <select class="form-control form-control-sm"  name="{{j}}_{{i}}_id_parent" [(ngModel)]="f.id_parent" (change)="moveToSection(f)">
                                                        <option *ngFor="let s of sections" [ngValue]="s.id">{{s.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="">
                                                    <small>Accesso</small>
                                                    <select class="form-control form-control-sm"  name="{{j}}_{{i}}_id_parent" [(ngModel)]="f.access" >
                                                        <option value="1">Pubblico</option>
                                                        <option value="3">Pubblico (solo visione)</option>
                                                        <option value="2">Privato</option>
                                                    </select>                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="">
                                                    <small>Abilita se è compilato il campo</small>
                                                    <select class="form-control form-control-sm"  name="{{j}}_{{i}}_condition_enable_fieldname" [(ngModel)]="f.condition_enable_fieldname" >
                                                        <option value="">Nessuno</option>
                                                        <option *ngFor="let f2 of s.fields " value="{{f2.name}}">{{f2.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div *ngIf="f.condition_enable_fieldname!=''">
                                                    <small title="valorizzare con il ; se vi sono più valori possibili">...e il campo è valorizzato come</small>
                                                    <input type="text" class="form-control form-control-sm"  name="{{j}}_{{i}}_condition_fieldname_value" [(ngModel)]="f.condition_fieldname_value" >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 pr-0 text-right">
                                        <button class="btn btn-default text-danger btn-xs" (click)="removeField(f)"><i class="fa fa-trash"  title="Elimina campo"></i></button>
                                        <button class="btn btn-default text-info btn-xs" (click)="duplicateField(f)"><i class="fa fa-clone"  title="Dupplica campo"></i></button>
                                        <button class="btn btn-default btn-xs" (click)="moveField(f,'up');"><i class="fas fa-chevron-up"  title="Sposta sopra"></i></button>
                                        <button class="btn btn-default btn-xs" (click)="moveField(f,'down');"><i class="fas fa-chevron-down"   title="Sposta sotto"></i></button>
                                        <button class="btn btn-default btn-xs" *ngIf="!f['expand']" (click)="f['expand']=true"><i class="fas fa-expand-alt"  title="Espandi"></i></button>
                                        <button class="btn btn-default btn-xs" *ngIf="f['expand']" (click)="f['expand']=false"><i class="fas fa-compress-alt"  title="Comprimi"></i></button>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </ng-container>

</div>