import { Injectable } from '@angular/core';
import {User,ToastMode } from '../include/structures';
import { HttpClient,HttpHeaders,HttpErrorResponse, HttpParams, HttpEventType, HttpResponse, HttpEvent} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {Observable, of,throwError} from 'rxjs';
import { Globals } from '../globals';
import * as CryptoJS from 'crypto-js';
import { Helper } from '../helper';
import { jwtDecode } from 'jwt-decode';
import { setThrowInvalidWriteToSignalError } from '@angular/core/primitives/signals';

export interface SaveResult{
  value:number;
};

@Injectable({
  providedIn: 'root'
})


export class WebserverService {

  api_version="v2";
  serverKey="5f2b5cdbe5194f10b3241568fe4e2b24";
  base:string;
  basev2:string;
  private token:string="";

  public static showMessage=true;

  constructor(private http: HttpClient) { 

  }


  getToken(username:string,password:string,onGetTokenComplete,showMessage=true,onerror:Function=null):void{
    if(this.api_version=="v1")
      this.getTokenv1(username,password,onGetTokenComplete,showMessage);
    if(this.api_version=="v2")
      this.getTokenv2(username,password,onGetTokenComplete,onerror);

  }

  
  getTokenv1(username:string,password:string,onGetTokenComplete,showMessage=true):void{
    //ottieni il token
    WebserverService.showMessage=showMessage?showMessage:true;
    
    const cpassword=this.encode(password,this.serverKey);


    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-C1-getToken':"true",
        'X-C1-Username':username,
        'X-C1-Password':cpassword
     
      })
    }

    var url=this.base;
    //var url=this.base+"?getToken&username="+username+"&password="+password;
    this.http.get<string>(url,httpOptions).pipe(catchError(this.handleError)).toPromise<string>().then((value)=>{
      if(value.hasOwnProperty("error")){
        onGetTokenComplete(false);
      }
      if(value.hasOwnProperty("token")){
        this.token=value['token'];
        Globals.user=new User();

        Object.assign(Globals.user,value['user']);
        Globals.user.setParams();

        
        Globals.config.expired_license=value['expired_license'];
        //Globals.config.expired_license='2022-12-31';

        //verifica se la licenza è scaduta o meno
        let date_expired_license=Helper.convertString2Date(Globals.config.expired_license);
        let today=new Date();
        if(today.toISOString()>=date_expired_license.toISOString()){
          Globals.readonly=true;
        }

        Globals.total_space=value['space'];
        Globals.total_occupied_space=value['occupied_space'];
        

        //AppComponent.user=value['user'];
        onGetTokenComplete(true);
      }

      
    });
  }

  private encode(text, skey) {
    var encoded = "";
    for (var i=0; i<text.length;i++) {
        var a = text.charCodeAt(i);
        var b = a ^ 123;    // bitwise XOR with any number, e.g. 123
        encoded = encoded+String.fromCharCode(b);
    }
    return btoa(encoded);
}

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${JSON.stringify(error.error)}`);
    }
    if(WebserverService.showMessage)
      switch(error.status){
        case 0:
          Globals.message.showToaster("Impossibile raggiungere il server",ToastMode.DANGER);
          break;
      default:
          Globals.message.showToaster("Errore durante la connessione al server",ToastMode.DANGER);
          break;
    }

    
    Globals.setLoading(false);

    return throwError(
      error.error);
    
  }

  requestUrl<T>(model:string,task:string,args:string[],nokeyvalue:boolean=true,paging_start:number=0,paging_count:number=10,onerror:Function=null):Observable<T>{
    if(this.api_version=="v1")
      return this.requestUrlv1<T>(model,task,args,nokeyvalue,paging_start,paging_count,onerror);
   else{

      let reqargs={"paging_start":paging_start,"paging_count":paging_count,"structuredOutput":!nokeyvalue};

      


      return this.getv2<T>(model,task,reqargs,args,null,onerror,"",null,null);
   }

  }




  requestUrlv1<T>(model:string,task:string,args:string[],nokeyvalue:boolean=true,paging_start:number=0,paging_count:number=10,onerror:Function=null){

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-C1-Model':model,
        'X-C1-Task':task,
        'X-C1-Args':btoa(unescape(encodeURIComponent(args.join("|")))).replace('+','xMl3Jk').replace('/','Por21Ld'),
        'X-C1-Token':this.token

      }),
      params: new HttpParams()
                              .set('structuredOutput',nokeyvalue==false?"true":"false")
                              .set('paging_start',paging_start.toString())
                              .set('paging_count',paging_count.toString())
                              .set('id_user',Globals.user!=undefined?Globals.user.id.toString():"")
                              .set('created_from',((Globals.user!=undefined && Globals.user['shop'])?Globals.user['shop']['id']:""))
                        
       
    };


     
    //crea chiave random per risolvere problemi di cache
    //let r=Math.random().toString()+new Date();
    

    //var url=this.base+"?"+(nokeyvalue==false?"":"no_key_value=true&")+"param_model="+model+"&param_task="+task+"&paging_start="+paging_start.toString()+"&paging_count="+paging_count.toString()+(Globals.user!=undefined?"&id_user="+Globals.user.id:"")+((Globals.user!=undefined && Globals.user['shop'])?"&created_from="+Globals.user['shop']['id']:"")+"&token="+this.token+"&args="+btoa(unescape(encodeURIComponent(args.join("|")))).replace('+','xMl3Jk').replace('/','Por21Ld');
    var url=this.base; //+"?r="+r;
    //console.log(model+"|"+task+"|");
    //console.warn(url);
    
    return this.http.get<T>(url,httpOptions ).pipe(catchError(this.handleError));
    
  }


  
  send<T>(model:string,task:string,item:T,args:any[]=[],oncomplete:Function,message:string="",file:File=null,onProgress=null,onError:Function=null,nokeyvalue:boolean=true){
      if(this.api_version=="v1")
        this.sendv1(model,task,item,args,oncomplete,message,file,onProgress);
      if(this.api_version=="v2")
        this.sendv2(model,task,item,args,oncomplete,onError,message,file,onProgress,nokeyvalue);
  
  
  }
  


  sendv1<T>(model:string,task:string,item:T,args:any[]=[],oncomplete:Function,message:string="",file:File=null,onProgress=null){
    

    const reportProgress=file==null?false:true;

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-C1-Token':this.token,
        'X-C1-Model':model,
        'X-C1-Task':task,
        'X-C1-Args':btoa(unescape(encodeURIComponent(args.join("|")))).replace('+','xMl3Jk').replace('/','Por21Ld')

      }),
      reportProgress:reportProgress
    }


    const formData = new FormData();

    //formData.append("token",this.token);
    //formData.append("param_model",model);
    //formData.append("param_task",task);
    if(Globals.user){
      if(Globals.user.id)
        formData.append("id_user",Globals.user.id.toString());
      if(Globals.user['shop'])
        formData.append("created_from",Globals.user['shop']['id'].toString());
    
      }
    
    /*if(args.length>0)
      formData.append("args",btoa(args.join("|")));
    */
    if(item!=null){
      if(Array.isArray(item)){
        let p:string;
        p=JSON.stringify(item);
        formData.append("multiple",p);
      
      }else{
        var made = Object.getOwnPropertyNames(item);

        for (var i of made) { 
          if(item[i]!=null){
            let p:string;
            if(Array.isArray(item[i]))
              p=JSON.stringify(item[i]);
            else{
              switch(typeof(item[i])){
                case 'boolean':
                  p=item[i]==true?"1":"0";
                  break;
                case 'object':
                  p=JSON.stringify(item[i]);
                  break;
                default:
                  p=item[i];
                  break;
              }

              
              
            }
            formData.append("field["+i+"]",p);
          }else{
            //console.log(i);
          }
        }
    }
  }
    
   if(file!=null){
     formData.append("file",file,file.name);
   }
    

    var url=this.base;
    

    if(file!=null){
      this.http.post(url,formData,httpOptions).subscribe(
      {
        next: (event: any) => {
          console.log(event);  
          if (event.type === HttpEventType.UploadProgress) {
            if(onProgress)
              onProgress(Math.round((100 * event.loaded) / event.total));
          } else if (event instanceof HttpResponse) {
            
            onProgress(100);
          }
        },
        error: (err: any) => {
          Globals.message.showToaster("Errore nel salvataggio",ToastMode.DANGER);
          console.log(err);
        },
        complete: () => {
          //let id:number=(res as SaveResult).value
          //oncomplete(id);
        }
      }
    );
    }else{
      this.http.post(url,formData,httpOptions).subscribe((res)=>{
        
        let id:number=(res as SaveResult).value

        oncomplete(id);
      },(err)=>{
        Globals.message.showToaster("Errore nel salvataggio",ToastMode.DANGER);
        console.log(err);
      },
      ()=>{
        if(message!="")
          Globals.message.showToaster(message,ToastMode.SUCCESS);
      
      
      }
      );
    }
  }


  

  /********** V2 **************/

  getTokenv2(username:string,password:string,onsuccess?:Function,onerror?:Function){

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    var url=this.basev2+"/users/login";
    this.http.post(url,{"username":username,"password":password},httpOptions).subscribe((res:any)=>{
      if(res.error){
        if(onerror){
          onerror(res);
        }
      }else{

        this.setToken(res.token)
     
        if(onsuccess)
          onsuccess(res);
      }

    });
  }

  setToken(token:string){
    this.token=token;
    localStorage.setItem("c1token",this.token);
    this.decodeToken();
  }

  decodeToken(){
    //parserizza il token
    try{
      const payload:any=jwtDecode(this.token);
      Object.assign(Globals.user,payload.user);
      Globals.isLogin=true;



      Globals.config.expired_license=payload['expired_license'];
      //Globals.config.expired_license='2022-12-31';

      //verifica se la licenza è scaduta o meno
      let date_expired_license=Helper.convertString2Date(Globals.config.expired_license);
      let today=new Date();
      if(today.toISOString()>=date_expired_license.toISOString()){
        Globals.readonly=true;
      }

      Globals.total_space=payload['space'];

      let tos=payload['occupied_space'];
      if (tos.substr(-1)!='G'&& tos.substr(-1)!='K') tos=tos+'M';
      Globals.total_occupied_space=tos;


    }catch{
      console.log("token invalid");
    }
  }

  sendv2<T>(model:string,task:string,data:any,args:any[]=[],oncomplete:Function | undefined,onerror:Function | undefined,message:string="",file:any=null,onprogress:Function=null,nokeyvalue:Boolean=false){
    
    var httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Token':this.token,
        'Content-type':'application/json'
      })
    }
    
    

    var url=this.basev2+"/"+model+"/"+task;
    var querystring=[];
    if(args.length>0){
      if(data==null)
        data={};
      for(let i=0;i<args.length;i++){
        data["#ARG"+i]=args[i];
        querystring.push("arg"+i+"="+args[i]);
      }
    }

    url=url+(querystring.length>0?"?"+querystring.join("&"):"");

   
    if(nokeyvalue)
      data['structuredOutput']=false;
      

    this.http.post(url,data,httpOptions).pipe(catchError(this.handleError)).subscribe((res:any)=>{
      if(oncomplete)
        oncomplete(res);
      
    },(err:any)=>{
      if(onerror)
        onerror(err);
        console.error(err);
    },
    ()=>{
    
    
    }
    );
    
  }


  sendfile<T>(model:string,task:string,file:File,args:any):Observable<HttpEvent<any>>{

    var url=this.basev2+"/"+model+"/"+task;

    var querystring=[];

    querystring.push("structuredOutput=false");

    if(args.length>0){
      for(let i=0;i<args.length;i++){
        querystring.push("arg"+i+"="+args[i]);
      }
    }

    url=url+(querystring.length>0?"?"+querystring.join("&"):"");

    const formData = new FormData();
    formData.append("file",file,file.name);
    
    return this.http.post(url,formData,{headers: new HttpHeaders(
      { 'Accept': 'application/json','Token':this.token }),reportProgress:true,observe:"events"});
  }

  getv2<T>(model:string,task:string,data:any,args:any[]=[],oncomplete:Function | undefined,onerror:Function | undefined,message:string="",file:any=null,onprogress:Function=null):Observable<T>{
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-type':'application/json',
        'Token':this.token
      })
    }
    
    if(args.length>0){
      if(data==null)
        data={};
      for(let i=0;i<args.length;i++){
        data["#ARG"+i]=args[i];
       
      }
    }

    var url=this.basev2+"/"+model+"/"+task;
    
    return this.http.post<T>(url,data,httpOptions).pipe(catchError(this.handleError));
    
  }


}
