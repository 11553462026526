<div class="card elevation-2 mb-0 pb-1">
    <!-- Barra di ricerca -->
    <div class="p-1 card-header" *ngIf="canAdd">
        <div class="row">
            <!-- <div [class.col-12]="!showQuantity" [class.col-9]="showQuantity" > -->
            <div class="col-11"     [class.section_disabled]="locked" >
                <app-searchproduct  [canExploreCatalog]="canExploreCatalog"
                                    [showPrice]="showPrice" 
                                    [canNewProduct]="canNewProduct" 
                                    [canEdit]="canEdit" 
                                    [autoSelect]="autoSelect" 
                                    [id_address]="id_address"
                                    [address]="address" 
                                    [showDescription]="false" 
                                    [resetOnEnter]="true" 
                                    [code]="" 
                                    [description]="" 
                                    (onEntrySelected)="add($event)"></app-searchproduct>
            </div>
            <!-- <div class="col-2">
                <div class="form-group mb-0 row" *ngIf="showQuantity">
                    <div class="d-none d-sm-block px-0 pt-2 col-4 text-right"><span>Qt</span></div>
                    <div class="col-sm-8">
                        <div class="input-group" >
                            <input type="number" min="1" step="1" class="form-control form-control-sm pr-0"  name="quantity" placeholder="..." [(ngModel)]="quantity" >
                        </div>
                    </div>                    
                </div>
            </div> -->
            <div class="col-1">
                <div *ngIf="list && list.length > 0">
                    <i class="fas fa-expand-alt pointer text-primary float-right" *ngIf="!edit_row_all" (click)="expandAll(true)" title="Espandi tutto"></i>
                    <i class="fas fa-compress-alt pointer text-primary float-right" *ngIf="edit_row_all" (click)="expandAll(false)" title="Comprimi tutto"></i>
                </div>
            </div>
        </div>
    </div>
    <div id="list-products" style="overflow-y: auto">

        <!-- Lista vuota -->
        <ng-container *ngIf="list==null || list.length==0">
            <div class="text-secondary text-center">
                <div class="m-5">                    
                    <i class="fas fa-3x fa-search text-primary m-3"></i>
                    <i class="fas fa-3x fa-qrcode text-purple m-3"></i>
                    <i *ngIf="!showOpenDocuments" class="fas fa-3x fa-plus text-success m-3"></i><br/>
                    <b>Cerca</b> o <b>Scansiona</b><span *ngIf="!showOpenDocuments"> o <b>Crea</b></span>  un prodotto oppure...
                </div>
                <div class="m-5" *ngIf="showOpenDocuments">
                    <i class="fas fa-3x fa-list text-gray m-3"></i><br/>
                    <button class="btn btn-primary" (click)="openDocuments()">Rintraccia un ordine cliente</button>
                </div>
            </div>
        </ng-container>
                
        <!-- View 2 (CORRENTE)-->
        <ul class="list-group alternate text-sm" *ngIf="viewmode==2" >
            <ng-container *ngFor="let d of list;let i=index">
                <li class="list-group-item p-0">                    
                    <div class="pb-1 border-bottom" [class.p-2]="d.edit_row && !checkSpecialProduct(d)" [class.bg-light]="d.edit_row && !checkSpecialProduct(d)" >
                        <!-- [class.bg-light]="d.edit_row && !checkSpecialProduct(d)" [class.alert-warning]="d.id_product==0" 
                             [class.alert-danger]="showNotes && d.no_avaible==true && d.state!=2 && type==1 && d.stockable" -->

                        <!-- View ridotta -->
                        <div class="col-12" *ngIf="!d.edit_row || checkSpecialProduct(d)">
                            <div class="row">
                                <div class="col-3 px-1" *ngIf="!checkSpecialProduct(d)">
                                    <small>
                                        <b class="help mr-1" title="Numero di riga">{{i+countSpecialProduct(d)}}&rpar;</b> 
                                        <i class="far fa-trash-alt text-danger blink help mr-1" title="Cestinato" *ngIf="d.product.status==2"></i>                                         
                                        <i class="fas fa-pencil-alt mr-1 pointer mr-1" *ngIf="d.id_product!=0" (click)="openProductDetail(d.product)" title="Clicca per dettaglio prodotto"></i> 
                                        <i class="far fa-sticky-note text-info help mr-1" title="NOTE: {{d.note}}" *ngIf="d.note"></i>
                                        <span class="d-none d-md-inline">                                            
                                            <i class="fa fa-exclamation-triangle text-danger help mr-1" title="Qt non disponibili!" *ngIf="showNotes && d.no_avaible==true && d.state!=2 && type==1 && d.stockable"></i>
                                            <i class="fa fa-exclamation-triangle text-orange blink help mr-1" title="Hai superato i 1000 caratteri (i caratteri eccedenti non saranno salvati!)" *ngIf="d.description && d.description.length>1000"></i>
                                            <i class="fa fa-exclamation-triangle text-warning help mr-1" title="L'articolo non è presente nel catalogo!" *ngIf="d.id_product==0"></i>
                                            <span class="badge border text-secondary help mr-1" *ngIf="d.code" title="Codice Articolo: {{d.code}}">{{d.code}}</span>
                                            <span class="badge border text-purple help mr-1" title="ID prodotto: {{d.id_product}}" *ngIf="d.id_product>0">{{d.id_product}}</span>
                                            <span class="badge border text-primary help mr-1" title="Serial Number: {{d.sn}}" *ngIf="d.sn">{{d.sn}}</span>
                                        </span>
                                        <span *ngIf="d.id_inventory_detail_unload>0" class="badge bg-danger pointer mr-1 " title="Clicca per dettaglio magazzino" (click)="openInventoryDetail(d.id_inventory_detail_unload,d,2)">
                                            <i class="fas fa-boxes mr-1"></i>Scaricato
                                        </span>
                                        <span *ngIf="d.id_inventory_detail_load>0 pointer" class="badge bg-success pointer mr-1 "  title="Clicca per dettaglio magazzino" (click)="openInventoryDetail(d.id_inventory_detail_load,d,1)">
                                            <i class="fas fa-boxes mr-1"></i>Caricato
                                        </span>
                                    </small><br>                                       
                                    <input  [readonly]="descriptionReadonly || locked" type="text" class="form-control form-control-sm font-weight-bold" style="height:1.5rem;" (paste)="verifyChars(d)" (keyup)="verifyChars(d)" title="{{d.description}}" name="description" [(ngModel)]="d.description">
                                </div>
                                <div class="col-4 pr-2 pt-4 text-xs font-weight-bold text-right" *ngIf="checkSpecialProduct(d)">                                    
                                    {{getNameSpecialProduct(d)}}
                                </div>
                                <div [class.section_disabled]="locked" class="col-1 pl-0 pr-1" *ngIf="!checkSpecialProduct(d)">
                                    <small>
                                        <span *ngIf="!d.unit">Qt</span>{{d.unit}}
                                        <span class="d-none d-lg-inline">
                                            <!-- <span *ngIf="!d.product.stockable" title="Prodotto non in magazzino (servizio)" class="ml-1 help"><i class="far fa-smile text-info"></i></span>    -->
                                            <span *ngIf="d.product.stockable && id_document==0" title="Quantità in magazzino" [class.text-danger]="d.product.stock<=0" [class.text-success]="d.product.stock>0" class="ml-1 help">(<i class="fa fa-boxes mr-1"></i>{{d.product.stock}})</span>
                                            <span *ngIf="d.product.stockable && id_document!=0" title="Giacenza alla data del documento" class="ml-1 help">(<i class="fas fa-cubes mr-1"></i>{{d.stock}})</span>    
                                        </span>
                                    </small>
                                    <input type="number" class="form-control form-control-sm font-weight-bold" min="1" [class.text-danger]="d.quantity<1"
                                        style="height:1.5rem;" name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                                </div>
                                <div [class.section_disabled]="locked" class="col-1 pl-0 pr-1">
                                    <ng-container *ngIf="!checkSpecialProduct(d)">
                                        <small>€/<span *ngIf="!d.unit">UM</span>{{d.unit}}</small>
                                        <input type="number" class="form-control form-control-sm font-weight-bold" min="1" style="height:1.5rem;" name="netprice" [(ngModel)]="d.netprice" (keyup)="calculateTotal()" (change)="checkPrice(d);calculateTotal()"/>
                                    </ng-container>
                                    <ng-container *ngIf="checkSpecialProduct(d)">
                                        <small>Imponibile</small>
                                        <div class="form-control form-control-sm py-0 text-truncate section_disabled" style="height:1.5rem">{{d.netprice | currency:'EUR'}}</div>
                                    </ng-container>
                                    <!-- <div class="pt-1 w-100 text-truncate" style="text-overflow:clip;">{{d.netprice}}</div> -->
                                    
                                </div>
                                <div *ngIf="!checkSpecialProduct(d)" class="col-2 px-0 text-center help" title="Espandi la riga per modificare">
                                    <small>Sconti (%)</small>
                                    <div class="form-control form-control-sm py-0 text-truncate section_disabled" style="height:1.5rem">
                                        {{d.discount1}} + {{d.discount2}} + {{d.discount3}}</div>
                                </div>
                                <ng-container *ngIf="checkSpecialProduct(d)">
                                    <div class="col-1 pl-0 pr-1 text-center">
                                        <small>Percentuale (%)</small>
                                        <input [class.section_disabled]="locked" class="form-control form-control-sm font-weight-bold" type="number" name="percentual" [(ngModel)]="d.percentual" style="height:1.5rem" (keyup)="calculateTotal()" (change)="calculateTotal()">
                                    </div>
                                    <div class="col-1 px-0 text-center help">
                                        <small>Valore netto</small>
                                        <div class="form-control form-control-sm py-0 text-truncate section_disabled" style="height:1.5rem">{{calculateTotalRow(d) | currency:"EUR"}}</div>
                                    </div>
                                </ng-container>
                                <div [class.section_disabled]="locked" class="col-1 px-1 text-center">
                                    <ng-container *ngIf="!checkIsWithHoldingTax(d)">
                                        <small>IVA</small>
                                        <select class="form-control form-control-sm text-xs font-weight-bold" style="height:1.5rem;" name="tax" [(ngModel)]="d.taxItem" (ngModelChange)="setTax(d,$event);calculateTotal()">
                                            <option *ngFor="let t of taxes" [ngValue]="t"><b>{{t.tax}}%</b> - {{t.label}}</option>
                                        </select>
                                    </ng-container>                                    
                                    <!-- <div class="pt-1 w-100 text-truncate"  title="{{calculateTotalRow(d,true)-calculateTotalRow(d)  | currency:'&euro; '}}" style="text-overflow:clip;">{{d.tax}}</div>  -->
                                </div>
                                <div [class.section_disabled]="locked" class="col-1 px-0">
                                    <small>
                                        <b>TOT<span class="d-none d-lg-inline">ALE</span></b>
                                        <ng-container *ngIf="d.product && d.product.netcost>0">
                                            <b class="d-none d-xl-inline ml-1 help" title="Margine di profitto">
                                                <span *ngIf="((calculateTotalRow(d)/d.quantity)-calculateNetCost(d))/calculateNetCost(d)*100>0" class="text-success ">
                                                    (+{{((calculateTotalRow(d)/d.quantity)-calculateNetCost(d))/calculateNetCost(d)*100 | number: '1.0-1'}}%)
                                                </span>
                                                <span *ngIf="((calculateTotalRow(d)/d.quantity)-calculateNetCost(d))/calculateNetCost(d)*100<=0" class="text-danger">
                                                    ({{((calculateTotalRow(d)/d.quantity)-calculateNetCost(d))/calculateNetCost(d)*100 | number: '1.0-1'}}%)
                                                </span>
                                            </b>
                                        </ng-container>
                                    </small>
                                        <!-- <div class="input-group-append" *ngIf="iswithholdingtax">
                                            <select class="form-control form-control-sm" name="iswithholdingtax" [(ngModel)]="d.iswithholdingtax" (ngModelChange)="calculateTotal()">
                                                <option [ngValue]="0">Non soggetto a ritenuta</option>
                                                <option [ngValue]="1">Soggetto a ritenuta</option>
                                            </select>
                                        </div> -->
                                        <!-- <div class="input-group input-group-sm">
                                        <div class="input-group-prepend" *ngIf="d.editGross">
                                            <div (click)="d.editGross=false" class="input-group-text bg-success pointer"><i class="fa fa-check"></i></div>
                                        </div>
                                        <input *ngIf="!d.editGross" class="form-control form-control-sm font-weight-bold text-right"  title="{{calculateTotalRow(d)  | currency:'EUR'}} (IVA esclusa)" (click)="d.editGross=true" style="height:1.5rem;" value="{{calculateTotalRow(d,true) | currency:'EUR'}}" />
                                        <input *ngIf="d.editGross" class="form-control form-control-sm font-weight-bold text-right"  title="{{calculateTotalRow(d) | currency:'EUR'}} (IVA esclusa)" min="0"  style="height:1.5rem;" name="total_gross" value="{{calculateTotalRow(d,true)}}" (change)="calculateNetDetail($event,d)" />
                                        </div> -->
                                    <ng-container *ngIf="checkSpecialProduct(d)">
                                        <div class="form-control form-control-sm py-0 text-truncate text-right section_disabled" style="height:1.5rem">{{calculateTotalRow(d,true) | currency:'EUR'}}</div>
                                    </ng-container>
                                    <ng-container *ngIf="!checkSpecialProduct(d)">
                                        <input class="form-control form-control-sm font-weight-bold text-right" min="0" style="height:1.5rem;" name="total_gross" 
                                            title="{{calculateTotalRow(d) | currency:'EUR'}} (IVA esclusa)"
                                            value="{{calculateTotalRow(d,true) | currency:'EUR'}}" 
                                            (focus)="$event.target.select()" 
                                            (focusout)="calculateNetDetail($event,d)"
                                            (keypress.enter)="calculateNetDetail($event,d)"/>
                                    </ng-container>
                                </div>
                                <div class="col-1 px-0" >
                                    <div class="float-left ml-2" *ngIf="iswithholdingtax && !checkIsWithHoldingTax(d)">
                                        <small>Rit.</small><br>
                                        <input type="checkbox" title="Ritenuta d'acconto" style="height:1.5rem;" name="iswithholdingtax" [(ngModel)]="d.iswithholdingtax" (change)="calculateTotal()">
                                    </div>    
                                    <div class="float-left ml-2" *ngIf="iswelfarefund && !checkIsWithHoldingTax(d) && !checkIsWelfareFund(d)">
                                        <small>Prev.</small><br>
                                        <input type="checkbox" title="Contributo previdenziale" style="height:1.5rem;" name="iswelfarefund" [(ngModel)]="d.iswelfarefund" (change)="calculateTotal()">
                                    </div> 
                                </div>
                                <div *ngIf="!checkSpecialProduct(d)" class="col-2 px-0 d-flex align-items-center justify-content-end">
                                    <a [class.section_disabled]="locked" class="btn text-danger btn-xs ml-1" type="button" (click)="deleteDetail(d)" title="Elimina"><i class="fa fa-trash"></i></a>
                                    <a [class.section_disabled]="locked" class="btn btn-xs text-primary ml-1" type="button" (click)="cloneDetail(d)" title="Duplica"><i class="fas fa-clone"></i></a>                                        
                                    <div [class.section_disabled]="locked" class="ml-1 text-primary ">
                                        <i *ngIf="i > 0" title="Sposta sopra" class="fas fa-chevron-up pointer" (click)="array_move(d['ordering'],d['ordering']-1);"></i>
                                        <ng-container *ngIf="(i > 0) && (i < (list.length-1))"><br></ng-container>
                                        <i *ngIf="i < (list.length-1)" title="Sposta sotto" class="fas fa-chevron-down pointer" (click)="array_move(d['ordering'],d['ordering']+1);"></i>
                                    </div>
                                    <a class="btn btn-xs text-primary ml-1" type="button" (click)="d.edit_row=true" title="Espandi"><i class="fas fa-expand-alt" ></i></a>
                                </div>
                            </div>                           
                        </div>
                        <!-- View completa per modifica -->
                        <div class="col-12" *ngIf="d.edit_row && !checkSpecialProduct(d)">
                            <div class="row">
                                <!-- Alert articolo non presente -->
                                <div class="col-12 text-center pt-1" *ngIf="d.id_product==0" >
                                    <!-- <i class="fa fa-exclamation-triangle text-warning mr-2"></i> -->
                                    <b class="mr-2">L'articolo non è presente nel catalogo!</b>
                                    Vuoi che <a class="btn btn-xs btn-outline-success py-0 px-4" (click)="newProduct(d)">creo</a> l'articolo, oppure 
                                    <a type="button" class="btn btn-xs btn-outline-primary py-0 px-3" (click)="assignProduct(d)">associo</a> ad un articolo in catalogo?
                                </div>
                                <!-- Alert quantità non disponibili -->
                                <div class="col-12 text-center pt-1" *ngIf="showNotes && d.no_avaible==true && d.state!=2 && type==1 && d.stockable">
                                    <i class="fa fa-exclamation-triangle text-danger mr-2"></i>
                                    <span class="badge badge-danger mr-2" *ngIf="d.not_in_stock">Da riordinare</span>
                                    <span class="badge badge-danger mr-2" *ngIf="d.in_other_stock">Disponibile in un altro magazzino</span>
                                    Quantità non disponibili
                                    <span class="mr-2" *ngIf="d.not_in_this_stock"> in questo magazzino (max {{d.max_quantity}}).
                                        <span *ngIf="d.max_quantity>0"> <a type="button" class="btn btn-xs btn-outline-secondary" (click)="resetQuantity(d)">Reimposto</a> le quantità massime?</span>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 col-xl-2 px-1">
                                    <h3 class="d-inline help" title="Numero di riga">{{i+countSpecialProduct(d)}}&rpar;</h3>
                                    <div class="d-inline-block float-right">
                                        <small>Codice</small>
                                        <input [class.bg-warning]="d.id_product==0" [readonly]="locked" type="text" name="code" class="form-control form-control-sm font-weight-bold text-xs" [(ngModel)]="d.code" />
                                    </div>                                  
                                </div>
                                <div class="col-9 col-xl-10 pl-0 pr-1" *ngIf="!checkSpecialProduct(d)">
                                    <small>Descrizione</small>
                                    <i class="fas fa-compress-alt pointer text-primary float-right" (click)="d.edit_row=false" title="Comprimi"></i>
                                    <div class="input-group input-group-sm" >
                                        <div class="input-group-prepend input-group-prepend-sm" *ngIf="d.id_product!=0" [class.section_disabled]="locked">
                                            <div class="input-group-text input-group-text-sm bg-primary pointer">
                                                <span title="Disassocia da articolo in catalogo" (click)="d.product='';d.id_product=0">
                                                    <i class="fa fa-unlink"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="input-group-prepend input-group-prepend-sm" *ngIf="d.product.id" >
                                            <div class="input-group-text input-group-text-sm bg-primary pointer">
                                                <span title="Dettaglio prodotto (ID: {{d.id_product}})" (click)="openProductDetail(d.product)">
                                                    <i class="fa fa-pencil-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <textarea class="form-control form-control-sm font-weight-bold" name="description" [(ngModel)]="d.description" 
                                            [class.bg-warning]="d.id_product==0" 
                                            [readonly]="descriptionReadonly || locked" rows="1" 
                                            onblur="this.rows=1;"
                                            onfocus="this.rows=2;" 
                                            (paste)="verifyChars(d)" 
                                            (keyup)="verifyChars(d)" >
                                        </textarea>
                                        <div *ngIf="d.description && d.description.length>1000" class="input-group-append input-group-append-sm help" 
                                                title="Hai superato i 1000 caratteri (i caratteri eccedenti non saranno salvati!)">
                                            <div class="input-group-text input-group-text-sm bg-white">
                                                <i class="fa fa-2x fa-exclamation-triangle blink text-orange mr-1"></i><br>
                                                <small class="text-gray">{{d.description.length}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-10 pl-0 pr-1" *ngIf="checkSpecialProduct(d)">
                                    <small>{{getNameSpecialProduct(d)}}</small>
                                    <i class="fas fa-compress-alt pointer text-gray float-right" (click)="d.edit_row=false" title="Comprimi"></i>
                                  
                                </div> -->                                                                
                            </div>
                            <div class="row" >
                                <div class="col-2 px-1">
                                    <small>SN</small>
                                    <input [readonly]="locked" type="text" class="form-control form-control-sm font-weight-bold text-xs" name="sn" [(ngModel)]="d.sn" />
                                </div>
                                <div class="col-6 px-0">
                                    <small>Note</small>                                
                                    <textarea [readonly]="locked" class="form-control form-control-sm text-xs font-weight-bold" rows="1" onblur="this.rows=1;" onfocus="this.rows=3;" (paste)="verifyChars(d)" (keyup)="verifyChars(d)" name="note" [(ngModel)]="d.note" ></textarea>
                                </div>                                
                                <div class="col-4 px-1" [class.section_disabled]="locked">
                                    <small>Info<br>
                                        <i class="far fa-trash-alt text-danger help p-1" title="Cestinato" *ngIf="d.product.status==2"></i>
                                        <span class="badge border text-purple help" title="ID prodotto: {{d.id_product}}" *ngIf="d.id_product>0">{{d.id_product}}</span>
                                        <span class="badge border text-primary help" title="Serial Number: {{d.sn}}" *ngIf="d.sn">{{d.sn}}</span>
                                        <span *ngIf="d.product.stockable && d.product && d.product.stock" (click)="openStock(d.product)" title="Stato magazzino" 
                                            [class.badge-danger]="d.product.stock<=0"
                                            [class.badge-success]="d.product.stock>0" class="badge pointer">
                                            <i class="far fa-boxes mr-1"></i>{{d.product.stock}} {{d.product.unit}}</span>
                                        <span *ngIf="d.quantityPartial==0" class="badge badge-warning">aperto</span>
                                        <span *ngIf="d.quantityPartial==d.quantity" class="badge badge-success">completato</span>
                                        <span *ngIf="d.quantityPartial>0 && d.quantityPartial<d.quantity" class="badge badge-danger">parziale (- {{d.quantity-d.quantityPartial}} {{d.unit}})</span>
                                        <span *ngIf="d.quantityPartial>0 && d.quantityPartial>d.quantity" class="badge badge-danger">parziale (+ di {{d.quantityPartial-d.quantity}} {{d.unit}})</span>
                                        <span *ngIf="d.quantityPartial>0 && d.quantity ==0" class="badge badge-danger">in eccesso</span>
                                        <span *ngIf="d.id_inventory_detail_unload>0" class="badge bg-danger" (click)="openInventoryDetail(d.id_inventory_detail_unload,d,2)"><i class="fas fa-boxes mr-1 pointer"></i>Scaricato</span>
                                        <span *ngIf="d.id_inventory_detail_load>0" class="badge bg-success" (click)="openInventoryDetail(d.id_inventory_detail_load,d,1)"><i class="fas fa-boxes mr-1 pointer"></i>Caricato</span>
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <ng-container *ngIf="!checkSpecialProduct(d)">
                                    <div [class.section_disabled]="locked" class="col-1 pl-1 pr-0">
                                        <small>Qt</small>
                                        <input *ngIf="!d.editQuantityPartial" type="number" class="form-control form-control-sm font-weight-bold" [class.alert-danger]="d.quantity<1" min="1"  title="Le quantità devono essere sempre maggiori di 0"
                                            name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                                        <input *ngIf="d.editQuantityPartial" type="number" class="form-control form-control-sm font-weight-bold" min="1"  name="quantityPartial"   title="Indicare le quantità presenti (parziali) rispetto a quelle dichiarate"
                                        [(ngModel)]="d.quantityPartial" />
                                    </div>
                                    <div [class.section_disabled]="locked" class="col-2 pl-0 pr-1">
                                        <small>UM</small>
                                        <div class="input-group input-group-sm">
                                            <input type="text" class="form-control form-control-sm font-weight-bold" name="unit" [(ngModel)]="d.unit"/>
                                            <div class="input-group-append pointer" (click)="d.editQuantityPartial=!d.editQuantityPartial"  title="Quantità Totali/Parziali">
                                                <span class="input-group-text bg-primary" *ngIf="!d.editQuantityPartial">T</span>
                                                <span class="input-group-text bg-warning" *ngIf="d.editQuantityPartial">P</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div [class.section_disabled]="locked" class="col-2 pl-0 pr-1">
                                        <small><span class="d-none d-md-inline">Prezzo Unitario </span>(€/<span *ngIf="!d.unit">UM</span>{{d.unit}})</small>
                                        <input type="number" class="form-control form-control-sm font-weight-bold" min="1" name="netprice" [(ngModel)]="d.netprice" (keyup)="calculateTotal()" (change)="calculateTotal()"/>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="checkSpecialProduct(d)">
                                    <div [class.section_disabled]="locked" class="col-2 pl-0 pr-1">
                                        <small><span class="d-none d-md-inline">Imponibile (€)</span></small>
                                        <input type="number" class="form-control form-control-sm" min="1" name="netprice" [(ngModel)]="d.netprice" (keyup)="calculateTotal()" (change)="calculateTotal()"/>
                                    </div>
                                </ng-container>                                
                                <div [class.section_disabled]="locked" class="col-2 px-0" *ngIf="!checkSpecialProduct(d)">
                                    <small>Sconti (%)</small><small title="max {{d.product.maxdiscount}}%" *ngIf="d.product.maxdiscount" class="text-info ml-2 help">
                                        <i class="d-md-none fa fa-exclamation-circle mr-1"></i><span class="d-none d-md-inline">max {{d.product.maxdiscount}}%</span></small>
                                    <div class="input-group input-group-sm">
                                        <input class="form-control form-control-sm font-weight-bold" min="1"  name="discount1" [(ngModel)]="d.discount1" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                        <input class="form-control form-control-sm font-weight-bold" min="1"  name="discount2" [(ngModel)]="d.discount2" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                        <input class="form-control form-control-sm font-weight-bold" min="1"  name="discount3" [(ngModel)]="d.discount3" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                    </div>
                                </div>
                                <div [class.section_disabled]="locked" class="col-2 px-0" *ngIf="checkSpecialProduct(d)">
                                    <small>Percentuale (%)</small>
                                    <div class="input-group input-group-sm">
                                        <input class="form-control form-control-sm font-weight-bold" min="1"  name="percentual" [(ngModel)]="d.percentual" (keyup)="calculateTotal()" (change)="calculateTotal()"/>
                                    </div>
                                </div>
                                <div [class.section_disabled]="locked" class="col-1 pl-1 pr-0">
                                    <small>IVA</small>
                                    <select class="form-control form-control-sm font-weight-bold"  name="tax" [(ngModel)]="d.taxItem" (ngModelChange)="setTax(d,$event);calculateTotal()">
                                        <option *ngFor="let t of taxes" [ngValue]="t">{{t.tax}}% - {{t.label}}</option>
                                    </select>
                                    <!--
                                    <input class="form-control form-control-sm" min="1" step="1" name="tax2" [(ngModel)]="d.tax" (keyup)="calculateTotal()" (change)="calculateTotal()"/>
                                    -->
                                </div>
                                <div class="col-2 px-1">
                                    <small><span class="d-none d-md-inline">TOTALE </span>(IVA esclusa)</small>
                                    <div class="input-group input-group-sm section_disabled">
                                        <input class="form-control form-control-sm" min="0" name="total_gross" 
                                            value="{{calculateTotalRow(d)  | currency:'EUR'}}" 
                                            (keypress)="calculateNetDetail($event,d)" />
                                    </div>
                                </div>
                                <div [class.section_disabled]="locked" class="col-2 pl-0 pr-1 text-right">
                                    <small><b class="d-none d-md-inline">TOTALE </b>(IVA inclusa)</small>
                                        <!-- <div class="input-group input-group-sm">
                                            <div class="input-group-prepend" *ngIf="d.editGross">
                                                <div (click)="d.editGross=false" class="input-group-text bg-success pointer"><i class="fa fa-check"></i></div>
                                            </div>
                                            <input *ngIf="!d.editGross" class="form-control form-control-sm font-weight-bold text-right"  title="{{calculateTotalRow(d) | currency:'&euro; '}} (IVA esclusa)" (keydown)="d.editGross=true" value="{{calculateTotalRow(d,true) | currency:'&euro; '}}" />
                                            <input *ngIf="d.editGross" class="form-control form-control-sm font-weight-bold text-right"  title="{{calculateTotalRow(d) | currency:'&euro; '}} (IVA esclusa)" min="0" name="total_gross" value="{{calculateTotalRow(d,true)}}" (change)="calculateNetDetail($event,d)" />
                                        </div> -->
                                    <input class="form-control form-control-sm font-weight-bold text-right" min="0" name="total_gross" 
                                        title="{{calculateTotalRow(d) | currency:'EUR'}} (IVA esclusa)" 
                                        value="{{calculateTotalRow(d,true)| currency:'EUR'}}" 
                                        (focus)="$event.target.select()" 
                                        (focusout)="calculateNetDetail($event,d)" 
                                        (keypress.enter)="calculateNetDetail($event,d)"/>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>

        <!-- View 1  (deprecata) -->
        <ul class="list-group alternate" *ngIf="viewmode==1" >
            <ng-container *ngIf="list!=null && list.length>0">
                <li class="list-group-item" *ngFor="let d of list">
                    <div class="row border-bottom">
                        <div class="col-2">
                            <i class="{{d.product.icon}}"></i>
                            <h6 *ngIf="!d.editMode">{{d.quantity}} {{d.unit}}</h6>
                            <input *ngIf="d.editMode==true" class="form-control form-control-sm" type="number" min="1" step="1" name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                            <span *ngIf="d.quantityPartial==0" class="badge badge-warning">aperto</span>
                            <span *ngIf="d.quantityPartial==d.quantity" class="badge badge-success">completato</span>
                            <span *ngIf="d.quantityPartial>0 && d.quantityPartial<d.quantity" class="badge badge-danger">parziale (- {{d.quantity-d.quantityPartial}} {{d.unit}})</span>
                            <span *ngIf="d.quantityPartial>0 && d.quantityPartial>d.quantity" class="badge badge-danger">parziale (+ di {{d.quantityPartial-d.quantity}} {{d.unit}})</span>
                            <span *ngIf="d.quantityPartial>0 && d.quantity ==0" class="badge badge-danger">in eccesso</span>
                            <input *ngIf="d.editMode==true" class="form-control form-control-sm" type="number" min="1" step="1" name="quantityPartial" [(ngModel)]="d.quantityPartial" />       
                        </div>
                        <div class="col-6" [class.col-9]="!showPrice">                           
                            <app-productitem [sn]="d.sn" [id_document]="id_document" [showNotes]="showNotes" [product]="d.product" ></app-productitem>                            
                            <ng-container *ngIf="showNotes && d.quantity!=d.quantityPartial">
                                <div class="badge badge-sm badge-warning" *ngIf="d.id_inventory_source>0">
                                    <i class="fas fa-exclamation"></i> Richiesto da {{d.inventoryname}} il {{d.created}}
                                </div>
                            </ng-container>
                            <small *ngIf="showNotes">{{d.note}}</small>
                            <ng-container *ngIf="showNotes && d.no_avaible==true && d.state!=2 && type==1 && d.stockable">
                                <div class="badge badge-danger">Quantità non disponibili <span *ngIf="d.not_in_this_stock">in questo magazzino (max {{d.max_quantity}})<br/><button *ngIf="d.max_quantity>0" class="btn btn-xs btn-warning mt-2" (click)="resetQuantity(d)">Reimposta le quantità massime</button></span></div>
                                <div class="badge badge-info" *ngIf="d.not_in_stock">Da riordinare</div>
                                <div class="badge badge-info" *ngIf="d.in_other_stock">Disponibile in un altro magazzino</div>                                
                            </ng-container>
                        </div>
                        <div class="col-3 text-right" *ngIf="showPrice">
                            <h6 class="text-success" *ngIf="!d.editMode">{{calculateTotalRow(d,true) | currency:"&euro; "}} <small *ngIf="d.discount1>0">(sc. {{d.discount1}}%)</small></h6>
                            <div class="row" *ngIf="d.editMode==true">
                                <div class="col-4"><label><small>Pr. i.e</small></label></div>
                                <div class="col-8">
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="netprice" [(ngModel)]="d.netprice" (keyup)="calculateTotal()" (change)="checkPrice(d);calculateTotal()"/>
                                </div>
                            </div>
                            <div class="row" *ngIf="d.editMode==true">                                
                                <div class="col-4"><label><small>Sc. %</small></label></div>
                                <div class="col-8">
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="discount1" [(ngModel)]="d.discount1" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="discount2" [(ngModel)]="d.discount2" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="discount3" [(ngModel)]="d.discount3" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                    <small class="badge badge-info">sc max {{d.product.maxdiscount}} %</small>
                                </div>
                            </div>
                            <small *ngIf="!d.editMode">( IVA {{d.tax}} %)</small>
                            <div class="row" *ngIf="d.editMode==true">
                                <div class="col-4"><label><small>IVA %</small></label></div>
                                <div class="col-8"><input class="form-control form-control-sm" type="number" min="1" step="1" name="tax" [(ngModel)]="d.tax" (keyup)="calculateTotal()" (change)="calculateTotal()"/></div>
                            </div>
                            <div class="row" *ngIf="d.editMode==true">
                                <div class="col-4"><small><label>Pr. i.i.</label></small></div>
                                <div class="col-8">
                                    <input class="form-control form-control-sm" type="number" min="1" step="0.01" name="grossprice" value="{{calculateTotalRow(d)}}" (change)="editGrossPrice(d,$event);calculateTotal()"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-1">
                            <button type="button"   (click)="deleteDetail(d)" title="Elimina" class="btn btn-danger btn-xs float-right m-1">
                                <i class="fas fa-trash"></i></button> 
                            <button *ngIf="canEdit && !d.editMode"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1" (click)="editDetail(d)">
                                <i class="fas fa-pencil-alt"></i></button>
                            <button *ngIf="canEdit && d.editMode"  title="Conferma" class="btn btn-success btn-xs float-right m-1" (click)="confirmEditDetail(d)">
                                <i class="fa fa-check"></i></button>
                            <!--<button  title="Sposta" class="btn btn-secondary btn-xs float-right m-1" (click)="move(d)">
                                <i class="fas fa-arrows-alt-h"></i></button>
                            -->
                            <input *ngIf="canEdit && showSelect" type="checkbox" (change)="OnSelect($event)" value="{{d.id}}">
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>

        <!-- View 3 (deprecata)-->
        <ul class="list-group alternate" *ngIf="viewmode==3" >
            <li class="list-group-item" *ngFor="let d of list;let i=index" >
                <div class="row border-bottom pb-2">
                    <div [class.section_disabled]="locked" class="col-1 px-0 bg-light text-center">
                        <h3 class="text-secondary mt-3">{{i+1}}&ensp;</h3>
                        <a type="button" (click)="deleteDetail(d)" title="Elimina" class="btn btn-outline-danger btn-sm mt-3">
                            <i class="fas fa-trash"></i></a>
                    </div>
                    <div [class.col-9]="!d.editGross" [class.col-7]="d.editGross">
                        <div class="row">
                            <div [class.section_disabled]="locked"  class="col-3">
                                <small>Codice</small>
                                <input type="text" name="code" class="form-control form-control-sm" [(ngModel)]="d.code" />
                               
                            </div>
                            <div class="col-7 px-0">
                               <small>Descrizione
                                    <ng-container *ngIf="showNotes && d.no_avaible==true && d.state!=2 && type==1 && d.stockable">
                                        <span class="badge badge-danger">Quantità non disponibili <span *ngIf="d.not_in_this_stock">in questo magazzino (max {{d.max_quantity}})<br/>
                                            <a *ngIf="d.max_quantity>0" class="btn btn-xs btn-warning mt-2" (click)="resetQuantity(d)">Reimposta le quantità massime</a></span></span>
                                        <span class="badge badge-info" *ngIf="d.not_in_stock">Da riordinare</span>
                                        <span class="badge badge-info" *ngIf="d.in_other_stock">Disponibile in un altro magazzino</span>
                                    </ng-container>
                                </small>
                                <small>
                                    <div class="input-group input-group-sm">
                                        <input [class.section_disabled]="locked" [readonly]="descriptionReadonly" type="text" class="form-control form-control-sm"  name="description" [(ngModel)]="d.description">
                                        
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <i *ngIf="!d.collapse" (click)="d.collapse=true"  title="Espandi note" class="fa fa-arrow-down"></i>
                                                <i *ngIf="d.collapse" (click)="d.collapse=false"  title="Riduci note"  class="fa fa-arrow-up"></i>
                                            </div>
                                       
                                            <a *ngIf="d.product" class="btn btn-default" [disabled]="!d.product.id" 
                                                 title="Dettaglio prodotto" (click)="openProductDetail(d.product)">
                                                <i class="fa fa-pencil-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <textarea [class.section_disabled]="locked" class="form-control form-control-sm" name="note" (paste)="verifyChars(d)" (keyup)="verifyChars(d)" [(ngModel)]="d.note" *ngIf="d.collapse"></textarea>
                                </small>
                                <div class="text-center">
                                    <div *ngIf="d.product && d.product.stock" (click)="openStock(d.product)" [class.badge-danger]="d.product.stock<0" [class.badge-success]="d.product.stock>0" class="badge">attualmente in magazzino <strong>{{d.product.stock}} {{d.product.unit}}</strong>&nbsp;<i class="fa fa-boxes"></i></div>
                                </div>
                            </div>
                            <div [class.section_disabled]="locked" class="col-2">
                                <small>SN</small>
                                <input type="text" class="form-control form-control-sm" name="sn" [(ngModel)]="d.sn" />
                            </div>
                        </div>
                        <div [class.section_disabled]="locked"  class="row">
                            <div class="col-3">
                                <small>Qt <span *ngIf="d.editQuantityPartial">parziali</span></small>
                                <div class="input-group input-group-sm">
                                    <input *ngIf="!d.editQuantityPartial" type="number" class="form-control form-control-sm" min="1" step="1" name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                                    <input *ngIf="d.editQuantityPartial" class="form-control form-control-sm" type="number" min="1" step="1" name="quantityPartial" [(ngModel)]="d.quantityPartial" />
                                    <div class="input-group-append pointer" (click)="d.editQuantityPartial=!d.editQuantityPartial">
                                        <span class="input-group-text" [class.bg-info]="d.editQuantityPartial">
                                            <i class="fa fa-sync" ></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 pl-0">
                                <small>UM</small>
                                <input  type="text" class="form-control form-control-sm"  name="unit" [(ngModel)]="d.unit"/>
                            </div>
                            <div class="col-3 pl-0">
                                <small>Pr. Un. (€/um)</small>
                                <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="netprice" [(ngModel)]="d.netprice" (keyup)="calculateTotal()" (change)="checkPrice(d);calculateTotal()"/>
                            </div>
                            <div class="col-2 pl-0">
                                <small>Sc. (%)</small>
                                <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="discount1" [(ngModel)]="d.discount1" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="discount2" [(ngModel)]="d.discount2" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="discount3" [(ngModel)]="d.discount3" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                                
                                <small><small *ngIf="d.product" class="badge badge-info">sc max {{d.product.maxdiscount}} %</small></small>
                            </div>
                            <div class="col-2 pl-0">
                                <small>IVA (%)</small>
                                <input  type="number" class="form-control form-control-sm" min="1" step="1" name="tax" [(ngModel)]="d.tax" (keyup)="calculateTotal()" (change)="calculateTotal()"/>
                            </div>
                        </div>
                    </div>
                    <div [class.col-2]="!d.editGross" [class.col-4]="d.editGross" class="px-0 text-right bg-light">
                            <div [class.section_disabled]="locked">
                                <small>Totale</small>
                                <h6 *ngIf="!d.editGross" (click)="d.editGross=true" class="text-success mb-0 pointer"  title="Modifica">{{calculateTotalRow(d,true)  | currency:"&euro; "}}</h6>
                                <div class="input-group input-group-sm" *ngIf="d.editGross">
                                    <input type="number" class="form-control form-control-sm mb-0" min="0" step="0.01" name="total_gross" value="{{calculateTotalRow(d,true)}}" (change)="calculateNetDetail($event,d)" />
                                    <div class="input-group-append">
                                        <div (click)="d.editGross=false" class="input-group-text bg-success pointer"><i class="fa fa-check"></i></div>
                                    </div>
                                </div>
                                <p  title="IVA esclusa">{{calculateTotalRow(d)  | currency:"&euro; "}}</p>
                                
                                <span *ngIf="d.quantityPartial==0" class="badge badge-warning">aperto</span>
                                <span *ngIf="d.quantityPartial==d.quantity" class="badge badge-success">completato</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial<d.quantity" class="badge badge-danger">parziale (- {{d.quantity-d.quantityPartial}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial>d.quantity" class="badge badge-danger">parziale (+ di {{d.quantityPartial-d.quantity}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantity ==0" class="badge badge-danger">in eccesso</span>
                            </div>
                            <ng-container *ngIf="d.id_inventory_detail_unload>0">
                                <span class="badge badge-warning pointer" (click)="openInventoryDetail(d.id_inventory_detail_unload,d,2)"><i class="fas fa-boxes mr-2"></i>Movimentato scarico</span>
                            </ng-container>

                            <ng-container *ngIf="d.id_inventory_detail_load>0">
                                <span class="badge badge-warning pointer" (click)="openInventoryDetail(d.id_inventory_detail_load,d,1)"><i class="fas fa-boxes mr-2"></i>Movimentato carico</span>
                            </ng-container>
                    </div>
                    <div class="col-12 px-0" *ngIf="d.id_product==0">
                        <div class="alert alert-warning">
                            <i class="fa fa-exclamation mr-2"></i>Articolo non presente nel catalogo
                            <a class="btn btn-primary btn-xs float-right" (click)="newProduct(d)"><i class="far fa-plus-square mr-2"></i>Crea articolo</a>
                            <a class="btn btn-info btn-xs ml-2 float-right" (click)="assignProduct(d)"><i class="fas fa-link mr-2"></i>Associa articolo del catalogo</a>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="m-1">
            <a [class.section_disabled]="locked" *ngIf="!descriptionReadonly" class="btn btn-default text-success btn-sm btn-block d-flex align-items-center justify-content-center" (click)="emptyRow()">
                <i class="fa-2x fa fa-plus-circle mr-1"></i>Aggiungi una riga vuota</a>
        </div>
    </div>
</div>



<!--
<div class="row">
    <div class="col-12">
        <table class="table">
            <thead>
                <th>ID</th>
                <th>Codice</th>
                <th>Descrizione</th>
                <th>SN</th>
                <th>Qt</th>
                <th>Um</th>
                <th>Pr. Unitario</th>
                <th>Sc. (%)</th>
                <th>IVA (%)</th>
                <th>Totale</th>
            </thead>
            <tbody *ngFor="let d of list;let i=index">
                <tr>
                    <td>{{i}}</td>
                    <td><input type="text" name="code" class="form-control form-control-sm" [(ngModel)]="d.code" /></td>
                    <td> 
                        <input [readonly]="descriptionReadonly" type="text" class="form-control form-control-sm"  name="description" [(ngModel)]="d.description">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <i *ngIf="!d.collapse" (click)="d.collapse=true" class="fa fa-arrow-down"></i>
                                <i *ngIf="d.collapse" (click)="d.collapse=false" class="fa fa-arrow-up"></i>
                            </div>
                        </div>
                        <div class="input-group-append">
                            <button *ngIf="d.product" class="btn btn-default" [disabled]="!d.product.id" (click)="openProductDetail(d.product)"><i class="fa fa-pencil-alt"></i></button>
                           
                        </div>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="sn" [(ngModel)]="d.sn" />
                    </td>
                    <td>
                        <div class="input-group input-group-sm">
                            <input *ngIf="!d.editQuantityPartial" type="number" class="form-control form-control-sm" min="1" step="1" name="quantity" [(ngModel)]="d.quantity" (keyup)="calculateTotal()" (change)="verifyAvaible(d.product,d.quantity,p);calculateTotal()"/>
                            <input *ngIf="d.editQuantityPartial" class="form-control form-control-sm" type="number" min="1" step="1" name="quantityPartial" [(ngModel)]="d.quantityPartial" />
                            <div class="input-group-append" (click)="d.editQuantityPartial=!d.editQuantityPartial" >
                                <span class="input-group-text" [class.bg-info]="d.editQuantityPartial">
                                    <i   class="fa fa-sync" ></i>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <input  type="text" class="form-control form-control-sm"  name="unit" [(ngModel)]="d.unit"/>
                    </td>
                    <td >
                        <input  type="number" class="form-control form-control-sm" min="1" step="0.01" name="discount" [(ngModel)]="d.discount" (keyup)="calculateTotal()" (change)="checkDiscount(d);calculateTotal()"/>
                        <small *ngIf="d.product" class="badge badge-info">sc max {{d.product.maxdiscount}} %</small>
                    </td>
                    <td>
                        <input  type="number" class="form-control form-control-sm" min="1" step="1" name="tax" [(ngModel)]="d.tax" (keyup)="calculateTotal()" (change)="calculateTotal()"/>
                    </td>
                    <td>
                        
                                <h5 *ngIf="!d.editGross" (click)="d.editGross=true" class="text-success"  title="">{{d.netprice*d.quantity*(1-d.discount/100)*(1+d.tax/100)  | currency:"&euro; "}}</h5>
                                <div class="input-group input-group-sm" *ngIf="d.editGross">
                                    <input type="number" class="form-control form-control-sm" min="0" step="0.01" name="total_gross" value="{{d.netprice*d.quantity*(1-d.discount/100)*(1+d.tax/100)}}" (change)="calculateNetDetail($event,d)" />
                               
                                    <div class="input-group-append">
                                        <div (click)="d.editGross=false" class="input-group-text bg-success"><i class="fa fa-check"></i></div>
                                    </div>
                                </div>
                                <p  title="IVA esclusa">{{d.netprice*d.quantity*(1-d.discount/100)  | currency:"&euro; "}}</p>
                                
                                <span *ngIf="d.quantityPartial==0" class="badge badge-warning">aperto</span>
                                <span *ngIf="d.quantityPartial==d.quantity" class="badge badge-success">completato</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial<d.quantity" class="badge badge-danger">parziale (- {{d.quantity-d.quantityPartial}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantityPartial>d.quantity" class="badge badge-danger">parziale (+ di {{d.quantityPartial-d.quantity}} {{d.unit}})</span>
                                <span *ngIf="d.quantityPartial>0 && d.quantity ==0" class="badge badge-danger">in eccesso</span>
                                
                                <ng-container *ngIf="d.id_inventory_detail>0">
                                    <span class="badge badge-warning" (click)="openInventoryDetail(d.id_inventory_detail)"><i class="fas fa-boxes ml-2"></i>Movimentato</span>
                                </ng-container>
                    </td>
                </tr>
            </tbody>
            
        
        </table>

    </div>
</div>
-->