import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-settingwindow',
  standalone: true,
  imports: [],
  templateUrl: './settingwindow.component.html',
  styleUrl: './settingwindow.component.css'
})
export class SettingwindowComponent {
  component:any;
  @ViewChild("component",{static:true})
  set content(value:any){
    this.component=value;
  }

  
  @Input()
  title:string;

  @Output()
  onClose:EventEmitter<any>=new EventEmitter();

  @Output()
  onSave:EventEmitter<any>=new EventEmitter();


  close(){
    this.onClose.emit();
  }

  save(){
    this.close();
    this.onSave.emit();
  }
}
