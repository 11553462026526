import { Component } from '@angular/core';
import { ISettingWindow } from 'projects/core/src/include/settingwindow';

@Component({
  selector: 'app-settings',
 
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
//@ISettingWindow.register
export class AddressSettingsComponent {

}
