<ng-container *ngIf="!readonly">
    <div class="{{class}}">
        <div class="alert alert-danger" *ngIf="onerror">
            {{errormessage}}
        </div>
        <div class="alert alert-info" *ngIf="percentual>0">
            Caricamento {{percentual}} %
            <br/>
            <small>file: {{filenametoupload}} ({{formatSize(sizetoupload)}})</small>
        </div>
        <div *ngIf="percentual==0 && !onerror" class="m-auto w-100 text-center p-2" style="max-width: 600px; height:200px; color:#ccc; border: 1px dashed #ccc; border-radius:10px">
            <i class="fas fa-2x fa-bullseye mt-5 animation__wobble"></i><br>
            <i>Trascina e rilascia <b>qui</b>.</i><br>
            <a class="btn btn-secondary btn-sm mt-4" (click)="fileToUpload.click()"><i class="fas fa-upload mr-2"></i>Scegli...</a>
        </div>
        <input class="d-block w-100 pointer" type="file" style="max-width: 600px; height:200px; margin:-200px auto 0; opacity:0;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
    </div>
</ng-container>
<ng-container *ngIf="readonly">
    <div class="alert alert-info">Modalità di sola lettura</div>
</ng-container>
<div class="modal-footer" *ngIf="isModal">
    <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Chiudi</button>
</div>