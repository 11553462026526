<app-windowlist [model]="this">
    <div class="card">
        <div class="row p-1">
            <div class="col-lg-4">
                <app-selecttables [name]="'filter_type'" [value]="filter_type.value" [show_all]="true" (onchange)="filter_type.value=$event;getItems()"></app-selecttables>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <button class="btn btn-success btn-sm float-right" (click)="openDetail()"><i class="fa fa-plus"></i></button>
            </div>
        </div>
        <form>
            <table class="table table-sm table-hover text-nowrap m-0" >
            <thead>
                <th>ID</th>
                <th>Nome</th>
                <th>Tabella</th>
                <th></th>
            </thead>
            <tbody>

                <!-- elemento ripetuto -->
                <tr *ngFor="let record of list">
                    <td>{{record.id}}</td>
                    <td class="pl-2">
                        <i *ngIf="record.enabled==1"  title="Abilitato" class="fa fa-check text-success"></i>
                        <i *ngIf="record.enabled==0"  title="Disabilitato" class="fas fa-times text-danger"></i>
                        {{record.name}}
                    </td>
                    <td>
                        <small><i>{{record.table}}</i></small>
                    </td>                    
                    <td class="pr-2">
                        <button  type="button"  title="Elimina" class="btn btn-danger btn-xs float-right ml-1"  (click)="confirm_delete(record.id)">
                            <i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        <button type="button"   title="Duplica" class="btn btn-info btn-xs float-right ml-1" (click)="duplicate(record)">
                            <i class="far fa-clone"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        <button type="button"  title="Modifica" class="btn btn-secondary btn-xs float-right ml-1"  (click)="openDetail(record)">
                            <i class="fas fa-pencil-alt"></i></button>
                    </td>
                </tr>

            </tbody>
            </table>
        </form>
        <div class="card-footer clearfix py-1">
            <small class="mr-1">Visualizzati <b class="text-primary" style="font-size: 110%;">{{recordcount}}</b> elementi</small>
        </div>
    </div>
</app-windowlist>
