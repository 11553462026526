import { Injectable } from '@angular/core';
import {Documents} from '../db/documents';
import {WSService} from 'projects/core/src/include/service'
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { InventoriesDetails } from '../db/inventories';
import { Filter, FilterAdvanced, Ordering } from 'projects/core/src/include/structures';
@Injectable({
  providedIn: 'root'
})

export class DocumentsService extends WSService<Documents> {

  constructor() {super("documents") }

  getCategories():Observable<any>{
    return Globals.ws.requestUrl<number>("documents","getCategories",[]);

  }

  getTransferDocuments(id_shop_end):Observable<any>{
    return Globals.ws.requestUrl<number>("documents","getItems",["i.typedocument=10 AND i.id_shop_end="+id_shop_end+" AND i.state=1 AND i.status=1"]);
   
  }

  addCategory(item,return_id){
    var item2send:any=item;
    if(Globals.ws.api_version=="v2")
      item2send={"values":item}
    
    Globals.ws.send("documents","addCategory",item2send,[],return_id);
  }

  getNextNumeration(date,id_type,typedocument,referenceprefix):Observable<number>{
    return Globals.ws.requestUrl<number>("documents","getNextNumeration",[date.toString(),id_type.toString(),typedocument.toString(),referenceprefix]);

  }

  createReceipt(item:any,oncomplete:Function){
    var item2send:any=item;
    if(Globals.ws.api_version=="v2")
      item2send={"values":item}
    return Globals.ws.send("documents","createReceipt",item2send,[],oncomplete);
  
  }

  confirmReceipt(id_document:number,methodpayments,reference=""):Observable<boolean>{
    return Globals.ws.requestUrl("documents","confirmReceipt",[id_document.toString(),methodpayments,reference]);
  }

  cancelDocumentCashRegister(id_document:number):Observable<boolean>{
    return Globals.ws.requestUrl("documents","cancelDocumentCashRegister",[id_document.toString()]);
  }

  createOrderSupplier(item:any,id_inventory_source:number,oncomplete:Function,checkNoAvaible=true){
    var item2send:any=item;
    if(Globals.ws.api_version=="v2")
      item2send={"values":item}

    return Globals.ws.send("documents","createOrderSupplier",item2send,[id_inventory_source.toString(),checkNoAvaible.toString()],oncomplete);
  }

  

  getLastOrderSupplier(id_supplier:number):Observable<Document>{
    return Globals.ws.requestUrl("documents","getLastOrderSupplier",[id_supplier.toString()]);
    
  }

  getDocument(search:string):Observable<Document>{
    return Globals.ws.requestUrl("documents","getDocument",[search]);
  }

  moveDetails(ids:string,id_document_dest:number):Observable<boolean>{
    return Globals.ws.requestUrl("documents","moveDetails",[ids,id_document_dest.toString()]);
  }

  getRules(typedocument):Observable<any[]>{
    return Globals.ws.requestUrl("documents","getRules",[typedocument]);
    
  }

  getAllReferencePrefix():Observable<any[]>{
    return Globals.ws.requestUrl("documents","getAllReferencePrefix",[]);
  }

  sendEInvoice(id_document:number):Observable<any>{
    return Globals.ws.requestUrl("documents","sendEInvoice",[id_document.toString()]);
  }

  /* deprecata?
  sendCreditNote(id_document:number):Observable<any>{
    return Globals.ws.requestUrl("documents","sendCreditNote",[id_document.toString()]);
  }
  */

  sendMultipleEInvoice(ids:number[]):Observable<any>{
    return Globals.ws.requestUrl("documents","sendMultipleEInvoice",[ids.toString()]);
  }
  
  mergeDocuments(id_document1:number,id_document2:number):Observable<any>{
    return Globals.ws.requestUrl("documents","mergeDocuments",[id_document1.toString(),id_document2.toString()]);
  }

  inventoryAction(values,onComplete:Function=null){

    var item2send:any=values;
    if(Globals.ws.api_version=="v2")
      item2send={"values":values}

    Globals.ws.send("inventoriesdetails","multiple",item2send,[],(result)=>{

      if(onComplete)
        onComplete(result);
    });
  }


  convertToInventoryList(values):InventoriesDetails[]{
    let p:InventoriesDetails=new InventoriesDetails();
    let result:InventoriesDetails[]=[];
    let keys=Object.getOwnPropertyNames(p);
    
    for(let v of values){
      for(let k of keys){
        if(v[k]!=undefined && k!="id")
          p[k]=v[k];
      }
      result.push(p);

    }

    return result;
  }

  downloadSDI(id_invoice):Observable<any>{
    return Globals.ws.requestUrl("documents","downloadInboxDocument",[id_invoice.toString(),"",true]);

  }

  parsingXMLSdi(file,sdi_idinvoice,type=2):Observable<boolean>{
    return Globals.ws.requestUrl("documents","parsingXMLSdi",[file,sdi_idinvoice,type]);
  }

  parsingXMLSdiZip(file,sdi_idinvoice,type=2):Observable<boolean>{
    return Globals.ws.requestUrl("documents","parsingXMLSdiZip",[file,sdi_idinvoice,type]);
  }


  convertDocument(id_document,typedocument_to,referenceprefixTo,typeTo):Observable<any>{
    return Globals.ws.requestUrl("documents","convertDocument",[id_document,typedocument_to,referenceprefixTo,typeTo]);

  }

  reorderNumeration(referenceprefix,year,type=1):Observable<any>{
    return Globals.ws.requestUrl("documents","reorderNumeration",[referenceprefix,year,type]);

  }

  saveReorderNumber(items:any[],oncomplete){

    var item2send:any=items;
    if(Globals.ws.api_version=="v2")
      item2send={"values":items}

    return Globals.ws.send<any[]>("documents","saveReorderNumber",item2send,[],()=>{oncomplete();});

  }

  checkStatusSdi(id_document,id_invoice):Observable<any>{
    return Globals.ws.requestUrl("documents","checkStatusSdi",[id_document,id_invoice]);

  }

  checkStatusEInvoice():Observable<any>{
    return Globals.ws.requestUrl("documents","checkStatusEInvoice",[]);

  }

  checkDocumentSdi():Observable<any>{
    return Globals.ws.requestUrl("documents","checkDocumentSdi",[]);

  }

  downloadDocumentSdi(sdi_idinvoice):Observable<any>{
    return Globals.ws.requestUrl("documents","downloadDocumentSdi",[sdi_idinvoice]);
  } 

  downloadDocumentsSdi(sdi_idinvoices):Observable<any>{
    return Globals.ws.requestUrl("documents","downloadDocumentsSdi",[sdi_idinvoices]);
  } 
  
  resaveInBoxDownloadedFile(identificativoSdI,id_document):Observable<any>{
    return Globals.ws.requestUrl("documents","resaveInBoxDownloadedFile",[identificativoSdI,id_document]);
  }

  resaveInBoxDownloadedFileAllDocuments():Observable<any>{
    return Globals.ws.requestUrl("documents","resaveInBoxDownloadedFileAllDocuments",[]);
    
  }

  resaveOutBoxDownloadedFile(identificativoSdI,id_document):Observable<any>{
    return Globals.ws.requestUrl("documents","resaveOutBoxDownloadedFile",[identificativoSdI,id_document]);
  }

  resaveOutBoxDownloadedFileAllDocuments():Observable<any>{
    return Globals.ws.requestUrl("documents","resaveOutBoxDownloadedFileAllDocuments",[]);
    
  }


  getDocumentsSdi(date_search=""):Observable<any>{
    return Globals.ws.requestUrl("documents","getDocumentsSDI",["",date_search]);
  }


  getOverview(date_from,date_to,created_from):Observable<any>{
    return Globals.ws.requestUrl("documents","getOverview",[date_from,date_to,created_from]);
  }

  getAnalyticsByTable(date_from,date_to):Observable<any>{
    return Globals.ws.requestUrl("documents","getAnalyticsByTable",[date_from,date_to]);
  }

  getOverviewByMonths(year,created_from):Observable<any>{
    return Globals.ws.requestUrl("documents","getOverviewByMonths",[year,created_from]);
  }

  getTotals(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[],full=0):Observable<any>{
    let args=this.getSQLWhere(filters,ordering,advancedFilter);
    args.push(full.toString());

    return Globals.ws.requestUrl("documents","getTotals",args);
  }

  verifyAllInvoice(date_search=""):Observable<any>{
    console.log('⏳ Avviata verifica su fatture elettroniche...');
    return Globals.ws.requestUrl("documents","verifyAllInvoice",[date_search]);
 
  }

  createNonFiscal(item,return_id){

    var item2send:any=item;
    if(Globals.ws.api_version=="v2")
      item2send={"values":item}

    return Globals.ws.send("documents","createNonFiscal",item2send,[],return_id);
  }

  cancelDocument(id):Observable<any>{
    return Globals.ws.requestUrl("documents","cancelDocument",[id]);
  }

  checkXMLEInvoice(ids):Observable<any>{
    return Globals.ws.requestUrl("documents","checkXMLEInvoice",[ids]);
  }

  linkDocumentByReference(id_document,id_table,table):Observable<any>{
    return Globals.ws.requestUrl("documents","linkDocumentByReference",[id_document,id_table,table]);
  }

  downloadFiles(ids):Observable<any>{
    return Globals.ws.requestUrl("documents","downloadFiles",[ids]);
  }

  createXML(id_document):Observable<any>{
    return Globals.ws.requestUrl("documents","createXML",[id_document]);
  }

  createXSL(id_document,format="Foglio_di_stile_fatturaordinaria_v1.2.1.xsl.xml"):Observable<any>{
    return Globals.ws.requestUrl("documents","createXSL",[id_document,format]);
  }


  processReceipt(item:any[],oncomplete){
    var item2send:any=item;

    if(Globals.ws.api_version=="v2")
      item2send={"document":item};

    return Globals.ws.send<any[]>("documents","processReceipt",item2send,[],(response)=>{oncomplete(response);},"",null,()=>{},()=>{},true);

  }
}