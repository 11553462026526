import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Filter, FilterAdvanced, Ordering } from 'projects/core/src/include/structures';
import { Ads, AdsOffer } from '../ads';

@Injectable({
  providedIn: 'root'
})
export class AdsService extends WSService<Ads> {

  constructor() {super("ads"); }

  /** Ottiene tutti gli annunci dell'utente corrente */
  getAdsCurrentUser():Observable<Ads[]>{
    return Globals.ws.requestUrl<any>("ads","getItems",["a.id_user="+Globals.user.id.toString()+" AND a.status=1","a.id DESC"]);
  }

  /** Ottiene tutte le valutazioni fatte dell'utente corrente */
  getOfferCurrentUser():Observable<Ads[]>{
    return Globals.ws.requestUrl<any>("ads","getAdsOffer",[Globals.user.id.toString()]);
  }

  getAdsViewed(id_user:number):Observable<Ads[]>{
    return Globals.ws.requestUrl<any>("ads","getAdsViewed",[id_user.toString()]);
  }

  

  addOffer(adsoffer:AdsOffer,oncomplete){
    var item2db:any=adsoffer;
    if(Globals.ws.api_version=="v2")
      item2db={"values":item2db}


    return Globals.ws.send("ads","addOffer",item2db,[],oncomplete);
    
  }

  getItemsByLocation(LatNW,LatSE,LonNW,LonSE,filter:Filter[]=[],ordering:Ordering[]=[],filter_advanced:FilterAdvanced[]=[]):Observable<Ads[]>{
    let w=this.getSQLWhere(filter,ordering,filter_advanced);
    return Globals.ws.requestUrl<Ads[]>("ads","getItemsByLocation",[LatNW.toString(),LatSE.toString(),LonNW.toString(),LonSE.toString(),w[0]],true,0,20);
  
  }

  //prende tutte le offerte per un dato ADS
  getOffers(id_ads,id_user=0):Observable<any[]>{
    return Globals.ws.requestUrl<any[]>("ads","getOffers",[id_ads,id_user.toString()]);
  }

  //prende un'offerta specifica
  getOffer(id_ads_offer):Observable<AdsOffer>{
    return Globals.ws.requestUrl<AdsOffer>("ads","getOffer",[id_ads_offer]);
  }

  //salva il form compilato di un'offerta
  saveOfferIdForm(id_ads_offer,id_form):Observable<any>{
    return Globals.ws.requestUrl("ads","saveOfferIdForm",[id_ads_offer,id_form]);
    
  }

  saveOfferRating(id_ads_offer,rating_number,rating_comment):Observable<any>{
    return Globals.ws.requestUrl("ads","saveOfferRating",[id_ads_offer,rating_number,rating_comment]);
  }

  getAdsAwarded(id_user):Observable<Ads[]>{
    return Globals.ws.requestUrl<any[]>("ads","getAdsAwarded",[id_user]);
  }


  confirmPayment(ids_offer,payment_method_id,amount,oncomplete){

    Globals.ws.send("ads","paymentStripe",null,[ids_offer,payment_method_id,amount],oncomplete);
 }

  StripeConfirmPayment(ids_offer,payment_intent_id):Observable<any>{
    return Globals.ws.requestUrl<any[]>("ads","StripeConfirmPayment",[ids_offer,payment_intent_id]);
  }

  confirmOffer(ids,ids_offer):Observable<any>{
    return Globals.ws.requestUrl<any[]>("ads","confirmOffer",[ids,ids_offer]);
  }
  
  createDocument(ids_offer):Observable<any>{
    return Globals.ws.requestUrl<any[]>("ads","createDocument",[ids_offer]);
  }
  
  deleteOffer(id_ads_offer):Observable<any>{
    return Globals.ws.requestUrl<any[]>("ads","deleteOffer",[id_ads_offer]);
  }

  unlinkOffer(id_ads_offer):Observable<any>{
    return Globals.ws.requestUrl<any[]>("ads","unlinkOffer",[id_ads_offer]);
  }

  sendRating(id_ads,id_ads_offer):Observable<any>{
    return Globals.ws.requestUrl<any[]>("ads","sendRating",[id_ads,id_ads_offer]);
  }

  getMetaFromWebsite(url):Observable<any>{
    return Globals.ws.requestUrl<any>("ads","getMetaFromWebsite",[url]);
  }
  
  getOffersConfirmedUser(id_user):Observable<any>{
    return Globals.ws.requestUrl<any>("ads","getOffersConfirmedUser",[id_user]);
  }

}
